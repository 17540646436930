// Desc: This page is used to test components and functionalities
// Update: 20/08/2024

import React, { useState } from 'react'

const TestPage = () => {
 
    return (
        <div>
            <h1>Test Page</h1>
            <p>This page is used to test components and functionalities</p>
        </div>
    )
}

export default TestPage