// Desc: Email Form for creating new email templates and also for viewing and editing existing email templates.
// Update: 

// React Components
import React, { useState } from 'react';

// Material UI Components
import { Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem, Grid, FormControl, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import Titles from '../../components/Titles/Titles';
import { personalizedBackgrounds } from '../../utils/templates';


// render Your Component
const FormEmail = ({ variablesOfTemplate, emailTitle, setEmailTitle, message, setMessageTemporary, initialGreeting, setInitialGreeting, typeMessage, setTypeMessage, saveTemplate, setBackground, isEmailTemplatePersonalized, edit = false }) => {
    // State variables
    const [expanded, setExpanded] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    // Function to toggle the visibility of the variables
    const toggleAccordionVisibility = () => {
        setIsVisible(!isVisible);
    };

    // Function to handle the change of the accordion
    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // Function to add a variable in the temporary message
    const addVariableInTemporaryMessage = (variable) => {
        setMessageTemporary(message + ' {{' + variable + '}}');
    };

    return (
        <>
            <Box display={'flex'} flexDirection={'column'} gap='15px' marginTop={'20px'} marginBottom={'0px'}>
                <Box display={'flex'} flexDirection={'column'} gap={'5px'} minWidth={'100%'}>
                    <Titles children={'Título do Email'} color={'#52525B'} type={'P1'} />
                    <TextField
                        placeholder='Digite o título aqui :)'
                        fullWidth
                        rows={1}
                        variant='outlined'
                        value={emailTitle}
                        onChange={(e) => setEmailTitle(e.target.value)}
                        autoComplete='off'
                        style={{ background: 'white', color: 'black', borderRadius: '12px', margin: 0, border: `1px solid ${ThemeColors.gray20}`, color: ThemeColors.gray40 }}
                        sx={{ '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': { padding: '14px' }, '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': { borderRadius: '6px' } }}
                    />
                </Box>

                <Box display={'flex'} flexDirection={'column'} gap={'5px'} minWidth={'100%'}>
                    <Titles children={'Saudação inicial'} color={'#52525B'} type={'P1'} />
                    <TextField
                        placeholder='Dê olá para o seu cliente'
                        fullWidth
                        rows={1}
                        variant='outlined'
                        value={initialGreeting}
                        onChange={(e) => setInitialGreeting(e.target.value)}
                        autoComplete='off'
                        style={{ background: 'white', color: 'black', borderRadius: '12px', margin: 0, border: `1px solid ${ThemeColors.gray20}`, color: ThemeColors.gray40 }}
                        sx={{ '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': { padding: '14px' }, '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': { borderRadius: '6px' } }}
                    />
                </Box>

                <Box display={'flex'} flexDirection={'column'} gap={'5px'} minWidth={'100%'}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Titles children={'Corpo do Email'} color={'#52525B'} type={'P1'} />
                        </Grid>
                        <Grid item xs={6} style={{ position: 'relative' }}>
                            <FormControl fullWidth>
                                <Typography onClick={toggleAccordionVisibility} style={{
                                    color: ThemeColors.purple500,
                                    marginRight: '5px',
                                    marginTop: '2px',
                                    fontFamily: 'Satoshi-Medium',
                                    fontSize: '18px',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    cursor: 'pointer'
                                }}>
                                    {isVisible ? "Esconder Variáveis" : "+ Adicionar Variáveis"}
                                </Typography>
                                {isVisible && (
                                    <div style={{
                                        position: 'absolute',
                                        right: 0,
                                        top: '100%',
                                        width: 'auto',
                                        backgroundColor: ThemeColors.purple200,
                                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                                        overflow: 'hidden',
                                        zIndex: 1,
                                        borderRadius: '12px'
                                    }}>
                                        {Object.entries(variablesOfTemplate).map(([category, values]) => (
                                            <Accordion key={category} expanded={expanded === category} onChange={handleChangeAccordion(category)} style={{ background: ThemeColors.purple200, border: 'none !important' }}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Typography>{category}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ border: 'none !important', padding: 0 }}>
                                                    {values.length > 0 ? (
                                                        values.map((value) => (
                                                            <MenuItem key={value} onClick={() => addVariableInTemporaryMessage(value)} style={{ color: ThemeColors.purple950 }}>
                                                                {value}
                                                            </MenuItem>
                                                        ))
                                                    ) : (
                                                        <Typography>Adicionar variáveis</Typography>
                                                    )}
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </div>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <TextField
                        placeholder='Dê olá para o seu cliente'
                        fullWidth
                        multiline
                        minRows={9}
                        variant='outlined'
                        value={message}
                        onChange={(e) => setMessageTemporary(e.target.value)}
                        autoComplete='off'
                        style={{ background: 'white', color: 'black', borderRadius: '12px', margin: 0, border: `1px solid ${ThemeColors.gray20}`, color: ThemeColors.gray40 }}
                        sx={{ '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': { padding: '14px' }, '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': { borderRadius: '6px' } }}
                    />
                </Box>


                <Grid container spacing={"10px"}>
                    <Grid item xs={7}>
                        {
                            !isEmailTemplatePersonalized && (
                                <Box
                                    display={'flex'}
                                    gap={'7px'}
                                    alignItems={'center'}
                                    height={'48px'}
                                >
                                    {
                                        personalizedBackgrounds.slice(0).reverse().map((option, index) => (
                                            <div
                                                key={index}
                                                style={{ background: option.backgroundColor, width: '43px', height: '43px', borderRadius: '6px', cursor: 'pointer' }}
                                                onClick={() => setBackground(option.base64)}
                                            />
                                        ))
                                    }
                                </Box>
                            )
                        }
                    </Grid>
                    <Grid item xs={5}>
                        <FormControl fullWidth>
                            <Button style={{ width: '100%', padding: '13px', height: '48px' }} variant='contained'
                                onClick={saveTemplate}
                            >
                                {edit ? "Editar Modelo" : "Salvar Modelo"}
                            </Button>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box >
        </>
    );
};

export default FormEmail;