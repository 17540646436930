import React from "react";

const Emoji03 = (props) => (
    <svg
        id="Camada_2"
        data-name="Camada 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 208.9 208.9"
        width={props.width || '85'} height={props.height || '85'}
        >
        <defs>
            <style>
                {
                    "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
                }
            </style>
        </defs>
        <g id="Layer_1" data-name="Layer 1">
            <g>
                <path
                    className="cls-2"
                    d="M153.9,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.9c30.38,0,55-24.63,55-55V55c0-30.38-24.62-55-55-55ZM77.6,89.25c-4.05,4.3-9.76,6.77-15.67,6.77s-11.62-2.47-15.66-6.77c-1.82-1.93-1.72-4.97.21-6.78,1.93-1.82,4.97-1.72,6.78.21,2.27,2.42,5.35,3.75,8.67,3.75s6.4-1.33,8.67-3.75c1.82-1.93,4.85-2.03,6.78-.21,1.94,1.81,2.03,4.85.22,6.78ZM127.78,117.64c-6.11,6.51-14.4,10.09-23.33,10.09s-17.21-3.58-23.32-10.09c-1.82-1.93-1.72-4.97.21-6.78,1.93-1.82,4.97-1.72,6.78.21,4.28,4.56,10.08,7.07,16.33,7.07s12.05-2.51,16.33-7.07c1.82-1.93,4.85-2.03,6.79-.21,1.93,1.81,2.02,4.85.21,6.78ZM162.63,89.25c-4.04,4.3-9.75,6.77-15.66,6.77s-11.62-2.47-15.67-6.77c-1.81-1.93-1.72-4.97.22-6.78,1.93-1.82,4.96-1.72,6.78.21,2.27,2.42,5.35,3.75,8.67,3.75s6.4-1.33,8.67-3.75c1.81-1.93,4.85-2.03,6.78-.21,1.93,1.81,2.03,4.85.21,6.78Z"
                />
                <path
                    className="cls-2"
                    d="M77.38,82.47c1.94,1.81,2.03,4.85.22,6.78-4.05,4.3-9.76,6.77-15.67,6.77s-11.62-2.47-15.66-6.77c-1.82-1.93-1.72-4.97.21-6.78,1.93-1.82,4.97-1.72,6.78.21,2.27,2.42,5.35,3.75,8.67,3.75s6.4-1.33,8.67-3.75c1.82-1.93,4.85-2.03,6.78-.21Z"
                />
                <path
                    className="cls-2"
                    d="M127.57,110.86c1.93,1.81,2.02,4.85.21,6.78-6.11,6.51-14.4,10.09-23.33,10.09s-17.21-3.58-23.32-10.09c-1.82-1.93-1.72-4.97.21-6.78,1.93-1.82,4.97-1.72,6.78.21,4.28,4.56,10.08,7.07,16.33,7.07s12.05-2.51,16.33-7.07c1.82-1.93,4.85-2.03,6.79-.21Z"
                />
                <path
                    className="cls-2"
                    d="M162.63,89.25c-4.04,4.3-9.75,6.77-15.66,6.77s-11.62-2.47-15.67-6.77c-1.81-1.93-1.72-4.97.22-6.78,1.93-1.82,4.96-1.72,6.78.21,2.27,2.42,5.35,3.75,8.67,3.75s6.4-1.33,8.67-3.75c1.81-1.93,4.85-2.03,6.78-.21,1.93,1.81,2.03,4.85.21,6.78Z"
                />
                <g>
                    <path
                        className="cls-1"
                        d="M77.6,89.25c-4.05,4.3-9.76,6.77-15.67,6.77s-11.62-2.47-15.66-6.77c-1.82-1.93-1.72-4.97.21-6.78,1.93-1.82,4.97-1.72,6.78.21,2.27,2.42,5.35,3.75,8.67,3.75s6.4-1.33,8.67-3.75c1.82-1.93,4.85-2.03,6.78-.21,1.94,1.81,2.03,4.85.22,6.78Z"
                    />
                    <path
                        className="cls-1"
                        d="M162.63,89.25c-4.04,4.3-9.75,6.77-15.66,6.77s-11.62-2.47-15.67-6.77c-1.81-1.93-1.72-4.97.22-6.78,1.93-1.82,4.96-1.72,6.78.21,2.27,2.42,5.35,3.75,8.67,3.75s6.4-1.33,8.67-3.75c1.81-1.93,4.85-2.03,6.78-.21,1.93,1.81,2.03,4.85.21,6.78Z"
                    />
                    <path
                        className="cls-1"
                        d="M127.78,117.64c-6.11,6.51-14.4,10.09-23.33,10.09s-17.21-3.58-23.32-10.09c-1.82-1.93-1.72-4.97.21-6.78,1.93-1.82,4.97-1.72,6.78.21,4.28,4.56,10.08,7.07,16.33,7.07s12.05-2.51,16.33-7.07c1.82-1.93,4.85-2.03,6.79-.21,1.93,1.81,2.02,4.85.21,6.78Z"
                    />
                </g>
            </g>
        </g>
    </svg>

); export default Emoji03