import React from "react";

const EditIcon = (props) => (
    <svg width={props.width || '15'} height={props.height || '15'} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_78_3533" maskUnits="userSpaceOnUse" x="0" y="0" width="15" height="15">
            <rect width="15" height="15" fill={props.color || 'red'} />
        </mask>
        <g mask="url(#mask0_78_3533)">
            <path d="M1.875 13.125V10.4688L10.125 2.23437C10.25 2.11979 10.388 2.03125 10.5391 1.96875C10.6901 1.90625 10.849 1.875 11.0156 1.875C11.1823 1.875 11.3438 1.90625 11.5 1.96875C11.6563 2.03125 11.7917 2.125 11.9063 2.25L12.7656 3.125C12.8906 3.23958 12.9818 3.375 13.0391 3.53125C13.0964 3.6875 13.125 3.84375 13.125 4C13.125 4.16667 13.0964 4.32552 13.0391 4.47656C12.9818 4.6276 12.8906 4.76563 12.7656 4.89063L4.53125 13.125H1.875ZM11 4.875L11.875 4L11 3.125L10.125 4L11 4.875Z" fill={props.color || 'red'} />
        </g>
    </svg>
);

export default EditIcon;
