// Desc: This page is responsible for showing the options of channels that the user can use to send the templates
// Update: 20/08/2024

import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import CardInfo from '../../components/Cards/CardInfo';
import Titles from '../../components/Titles/Titles';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import Box from '@mui/material/Box';
import SendEmail from '../../assets/ilustrations/SendEmail.png';
import SendWhatsApp from '../../assets/ilustrations/SendWhatsApp.png';
import SendSMS from '../../assets/ilustrations/SendSMS.png';
// Colors of the theme
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import Footer from '../../components/Footer/Footer';

import Loading from '../../components/Loading/Loading';

const PageOptionsChannel = (props) => {
    const [showLoading, setShowLoading] = useState(true);

    const data = [
        {
            image: SendEmail,
            title: 'Email',
            desc: 'Aperfeiçoe suas campanhas com templates de e-mail versáteis, ideal para marketing, notificações ou correspondências corporativas.',
            href: '/modelos/canal/template'
        },
        {
            image: SendWhatsApp,
            title: 'WhatsApp',
            desc: 'Envie mensagens de texto, imagens, vídeos e documentos para seus clientes de forma rápida e segura.',
            href: '/modelos/canal/whatsapp'
        },
        {
            image: SendSMS,
            title: 'SMS',
            desc: 'Envie mensagens de texto para seus clientes de forma rápida e segura.',
            href: '/modelos/canal/sms'
        },

    ];

    useEffect(() => {
        // Simulate a API call
        setTimeout(() => setShowLoading(false), 1000);
    }, []);

    // Show the loading screen
    if (showLoading) {
        return <Loading open={true} />;
    }

    return (
        <>

            <Box style={{ position: 'relative', minHeight: '100vh' }}>
                <Grid container spacing={2}>
                    <HeaderPage title="Modelos" />
                </Grid>

                <div style={{ borderRadius: '2px', overflow: 'hidden', padding: '25px 40px 25px 40px' , margin: '20px'}}>
                    <Grid container spacing={2} justify="center" style={{ marginBottom: '40px' }}>
                        <Grid item xs={12} sm={4}>
                            <Titles type='H3' color={ThemeColors.gray40} style="medium">Qual canal você vai utilizar?</Titles>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} sm={12}>
                            <CardInfo title="Email" desc="Aperfeiçoe suas campanhas com templates de e-mail versáteis, ideal para marketing, notificações ou correspondências corporativas." style={{ maxHeight: '100px', overflow: 'auto' }} cards={data} />
                        </Grid>
                    </Grid>

                    <div style={{ padding: "40px 0 0 0" }}>
                        <Footer />
                    </div>
                </div>
            </Box>
        </>
    );
};

export default PageOptionsChannel;

