// Desc: Page to add a new WhatsApp instance
// Update: 05/08/2024

// React Components and DOM Elements
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Material UI Components
import Box from '@mui/material/Box';

// Components and functions from the project
import { isAuthenticated, encrypt } from '../../authMiddleware';
import { RequestCreate } from '../../api/backend/Request/RequestCreate';

import CardList from '../../components/CardList/CardList';
import CardModal from '../../components/CardList/CardModal';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import Loading from '../../components/Loading/Loading';
import Titles from '../../components/Titles/Titles';

// Render the page
function ChannelPageAdd() {

  // Authentication Context Functions
  const { authenticated, userType, uuidClient } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  let Navigate = useNavigate();

  // Loading Transition state
  const [showLoading, setShowLoading] = useState(true);
  const [input, setInput] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const openModal = (type) => {
    setModalType(type);
    setShowModal(true);
  }

  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
      console.log(decryptedData);
    }
  }, []);

  const addRequest = async () => {
    
    
    let info = {

      title: 'Solicitação de Adição de Canal ' + modalType,
      description: 'Solicitação de adição de canal ' + modalType + ' para envio de mensagens personalizadas para os clientes.',
      status: 'Pendente',
      creationdate: new Date().toISOString(),
      updatedate: new Date().toISOString(),
      requesterid: userInfo['EMAIL'],
      user_client: userInfo['UUID'],  
      body: {
        type: modalType,
        data: input
      },
      priority: 'A Definir'

    }

    info = JSON.stringify(info);

    const encryptedData = encrypt(info, userInfo['IV']);
    const addRequestUser = await RequestCreate({ data: encryptedData, iv: userInfo['IV'] });

    if (addRequestUser.status === 'OK') {
      Navigate('/suporte')
    } else {
      console.log('Erro ao adicionar solicitação:', addRequestUser);
    }

  }

  const HandleRedirectAdd = () => {
    Navigate('/canais/adicionar/whatsapp');
  }

  const channelData = [
    { title: "WhatsApp", text: "Adicione um número de Whatsapp para enviar mensagens personalizadas para seus clientes.", icon: "WhatsApp", corFundo: "#DDD4FF", titleColor: "#8033F4", onClick: HandleRedirectAdd, pago: false },
    { title: "WhatsApp Business", text: "Avisos de cobrança, faturas e lembretes de pagamento. personalize mensagens para manter seus clientes informados", icon: "WhatsApp Business", corFundo: "#DDD4FF", titleColor: "#8033F4", onClick: () => openModal("WhatsApp Business"), pago: true },
    { title: "Email", text: "Solicite um endereço de email personalizado para enviar mensagens de email para os seus clientes.", icon: "Email", corFundo: "#DDD4FF", titleColor: "#8033F4", onClick: () => openModal("Email"), pago: true },
    { title: "SMS", text: "Solicite um número de telefone para enviar mensagens de texto para os seus clientes.", icon: "SMS", corFundo: "#DDD4FF", titleColor: "#8033F4", onClick: () => openModal("SMS"), pago: true },
  ];

  useEffect(() => {
    // Simulate a API call
    setTimeout(() => setShowLoading(false), 1000);
  }, []);

  // Show the loading screen
  if (showLoading) {
    return <Loading open={true} />;
  }

  // Render the page
  return (
    <>
      <HeaderPage title="Add WhatsApp Instance" />
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          minHeight: 'calc(100vh - 100px)',
          bgcolor: 'background.paper',
          paddingBottom: 10,
        }}
      >
        <Box paddingLeft={5} paddingTop={5} paddingRight={5}>
          <Titles type={"H3"} children={"Qual Canal deseja adicionar?"} color={"#848484"} />
          <CardList data={channelData} />
        </Box>

        <CardModal isOpen={showModal} onClose={() => setShowModal(false)} type={modalType} input={input} setInput={setInput} onConfirm={addRequest} />
      </Box>
    </>
  );
}

export default ChannelPageAdd;