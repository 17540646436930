// Request to save campaign data to the backend
// Update in 20/04/2024 ( OK )

export async function CampaignSave(campaignData) {
    try {
      const response = await fetch('https://api.123zap.com.br/webhook/ea229f17-6f87-4ddb-8765-c507cfe3ee3a', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(campaignData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error saving campaign data:', error);
      throw new Error('Error saving campaign data');
    }
  }