import React from "react";

const Emoji15 = (props) => (

    <svg
        id="Camada_2"
        data-name="Camada 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 208.89 208.9"
        width={props.width || '85'} height={props.height || '85'}
        >
        <defs>
            <style>
                {
                    "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7428ea;\n      }\n    "
                }
            </style>
        </defs>
        <g id="Layer_1" data-name="Layer 1">
            <g>
                <path
                    className="cls-2"
                    d="M153.89,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.89c30.38,0,55-24.63,55-55V55c0-30.38-24.62-55-55-55ZM74,91.25c-1.05,6.43-6.02,11.31-12.03,11.31-6.79,0-12.27-6.21-12.27-13.85,0-1.75.29-3.43.84-4.96l-6.76-2.15c-2.53-.8-3.93-3.51-3.11-6.02.8-2.53,3.49-3.92,6.02-3.11l36.58,11.65c2.53.82,3.93,3.51,3.11,6.04-.63,2.04-2.53,3.33-4.57,3.33-.47,0-.96-.07-1.45-.22l-6.36-2.02ZM127.48,119.38c0,.15-.02.31-.04.48-1.78,11.61-12.84,16.8-22.99,16.8s-21.22-5.19-23-16.8c-.02-.17-.04-.33-.04-.48v-9.76c0-1.76,1.44-3.18,3.21-3.18h39.65c1.77,0,3.21,1.42,3.21,3.18v9.76ZM165.11,81.6l-6.76,2.15c.55,1.53.84,3.21.84,4.96,0,7.64-5.5,13.85-12.25,13.85-6.01,0-11-4.88-12.05-11.31l-6.36,2.02c-.49.15-.98.22-1.45.22-2.04,0-3.94-1.29-4.57-3.33-.82-2.53.58-5.22,3.11-6.04l36.58-11.65c2.53-.81,5.22.58,6.02,3.11.82,2.51-.58,5.22-3.11,6.02Z"
                />
                <path
                    className="cls-2"
                    d="M127.48,109.62v9.76c0,.15-.02.31-.04.48-1.78,11.61-12.84,16.8-22.99,16.8s-21.22-5.19-23-16.8c-.02-.17-.04-.33-.04-.48v-9.76c0-1.76,1.44-3.18,3.21-3.18h39.65c1.77,0,3.21,1.42,3.21,3.18Z"
                />
                <path
                    className="cls-2"
                    d="M165.11,81.6l-6.76,2.15c.55,1.53.84,3.21.84,4.96,0,7.64-5.5,13.85-12.25,13.85-6.01,0-11-4.88-12.05-11.31l-6.36,2.02c-.49.15-.98.22-1.45.22-2.04,0-3.94-1.29-4.57-3.33-.82-2.53.58-5.22,3.11-6.04l36.58-11.65c2.53-.81,5.22.58,6.02,3.11.82,2.51-.58,5.22-3.11,6.02Z"
                />
                <g>
                    <path
                        className="cls-1"
                        d="M127.48,109.62v9.76c0,.15-.02.31-.04.48-1.78,11.61-12.84,16.8-22.99,16.8s-21.22-5.19-23-16.8c-.02-.17-.04-.33-.04-.48v-9.76c0-1.76,1.44-3.18,3.21-3.18h39.65c1.77,0,3.21,1.42,3.21,3.18Z"
                    />
                    <path
                        className="cls-1"
                        d="M86.38,90.16c-.63,2.04-2.53,3.33-4.57,3.33-.47,0-.96-.07-1.45-.22l-6.36-2.02c-1.05,6.43-6.02,11.31-12.03,11.31-6.79,0-12.27-6.21-12.27-13.85,0-1.75.29-3.43.84-4.96l-6.76-2.15c-2.53-.8-3.93-3.51-3.11-6.02.8-2.53,3.49-3.92,6.02-3.11l36.58,11.65c2.53.82,3.93,3.51,3.11,6.04Z"
                    />
                    <path
                        className="cls-1"
                        d="M165.11,81.6l-6.76,2.15c.55,1.53.84,3.21.84,4.96,0,7.64-5.5,13.85-12.25,13.85-6.01,0-11-4.88-12.05-11.31l-6.36,2.02c-.49.15-.98.22-1.45.22-2.04,0-3.94-1.29-4.57-3.33-.82-2.53.58-5.22,3.11-6.04l36.58-11.65c2.53-.81,5.22.58,6.02,3.11.82,2.51-.58,5.22-3.11,6.02Z"
                    />
                </g>
            </g>
        </g>
    </svg>

); export default Emoji15