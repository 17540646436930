// Description: This component render a showcase of the colors of the site
// Update: 12/08/2024

// React Components and DOM Elements
import React from 'react';

// Material UI Components
import { Grid } from '@mui/material';

// Components and functions from the project
import ThemeColors from '../ThemeColors/ThemeColors';
import Titles from '../Titles/Titles';
import ContainerStyle from './ContainerStyle';

// Render Your Component
const Cores = () => {
    return (


        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>

            {/* type(SIZE) | color (ThemeColors) | style->satoshi (regular/medium/bold) |bold (TRUE/FALSE)  */}
            <Titles type='H4' color={'#5F1BBC'} bold={false} style={'regular'}>Escala roxo</Titles>

            < Grid container spacing={2} sx={{ marginTop: '5px', marginBottom: '10px' }}>
                <ContainerStyle color={ThemeColors.purple50} colorFont={'#7121E0'} title={'50'} subtitle={'#F5F3FF'} />
                <ContainerStyle color={ThemeColors.purple100} colorFont={'#7121E0'} title={'100'} subtitle={'#EDE8FF'} />
                <ContainerStyle color={ThemeColors.purple200} colorFont={'#7121E0'} title={'200'} subtitle={'#DDD4FF'} />
                <ContainerStyle color={ThemeColors.purple300} colorFont={'#7121E0'} title={'300'} subtitle={'#C5B2FF'} />
                <ContainerStyle color={ThemeColors.purple400} colorFont={'#FFFFFF'} title={'400'} subtitle={'#A986FF'} />
                <ContainerStyle color={ThemeColors.purple500} colorFont={'#FFFFFF'} title={'500'} subtitle={'#8142FC'} />
                <ContainerStyle color={ThemeColors.purple600} colorFont={'#FFFFFF'} title={'600'} subtitle={'#8033F4'} />
                <ContainerStyle color={ThemeColors.purple700} colorFont={'#FFFFFF'} title={'700'} subtitle={'#7121E0'} />
                <ContainerStyle color={ThemeColors.purple800} colorFont={'#FFFFFF'} title={'800'} subtitle={'#5F1BBC'} />
                <ContainerStyle color={ThemeColors.purple900} colorFont={'#FFFFFF'} title={'900'} subtitle={'#4F189A'} />
                <ContainerStyle color={ThemeColors.purple950} colorFont={'#FFFFFF'} title={'950'} subtitle={'#300D68'} />
            </Grid>

            <Titles type='H4' color={'#52525B'} bold={false} style={'regular'}>Escala cinza</Titles>

            <Grid container spacing={2} style={{ marginTop: '5px', marginBottom: '10px' }}>
                <ContainerStyle color={ThemeColors.gray10} colorFont={'#000000'} title={'10'} subtitle={'#F9F9F9'} />
                <ContainerStyle color={ThemeColors.gray20} colorFont={'#000000'} title={'20'} subtitle={'#E5E5E5'} />
                <ContainerStyle color={ThemeColors.gray30} colorFont={'#000000'} title={'30'} subtitle={'#B7B7B7'} />
                <ContainerStyle color={ThemeColors.gray40} colorFont={'#FFFFFF'} title={'40'} subtitle={'#848484'} />
                <ContainerStyle color={ThemeColors.gray50} colorFont={'#FFFFFF'} title={'50'} subtitle={'#52525B'} />
                <ContainerStyle color={ThemeColors.gray60} colorFont={'#FFFFFF'} title={'60'} subtitle={'#303035'} />
            </Grid>

            <Titles type='H4' color={'#345427'} bold={false} style={'regular'}>Escala alertas</Titles>
            <Grid container spacing={2} style={{ marginTop: '5px', marginBottom: '10px' }}>
                <ContainerStyle color={ThemeColors.yellow01} colorFont={'#8D4D1B'} title={'01'} subtitle={'#F6EDCB'} />
                <ContainerStyle color={ThemeColors.yellow02} colorFont={'#FFFFFF'} title={'02'} subtitle={'#DEA73F'} />
                <ContainerStyle color={ThemeColors.yellow03} colorFont={'#FFFFFF'} title={'03'} subtitle={'#8D4D1B'} />
                <ContainerStyle color={ThemeColors.red01} colorFont={'#4E1B1D'} title={'01'} subtitle={'#F8D6D3'} />
                <ContainerStyle color={ThemeColors.red02} colorFont={'#FFFFFF'} title={'02'} subtitle={'#CD4747'} />
                <ContainerStyle color={ThemeColors.red03} colorFont={'#FFFFFF'} title={'03'} subtitle={'#4E1B1D'} />
                <ContainerStyle color={ThemeColors.green01} colorFont={'#1B4D1B'} title={'01'} subtitle={'#D3EAC8'} />
                <ContainerStyle color={ThemeColors.green02} colorFont={'#FFFFFF'} title={'02'} subtitle={'#4C8435'} />
                <ContainerStyle color={ThemeColors.green03} colorFont={'#FFFFFF'} title={'03'} subtitle={'#345427'} />
            </Grid>

        </div>
    )
}
export default Cores;