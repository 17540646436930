// Description: This component render a change password form
// Update: 12/08/2024

// React Components and DOM Elements
import React, { useState } from 'react';

// Material UI Components
import { TextField, Button, Box } from '@mui/material';
import Titles from '../Titles/Titles';

// Components and functions from the project
import ThemeColors from '../ThemeColors/ThemeColors';

// Render Your Component
function PasswordPrompt({ onSuccess }) {
    const [password, setPassword] = useState('');
    const correctPassword = 'Equipe123zap'; // Correct password

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (password === correctPassword) {
            onSuccess(); // Call the onSuccess function passed by props
        } else {
            alert('Senha incorreta!');
            setPassword(''); // Clear the password field before a failed attempt
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 3,
            }}
        >
            < Titles type='H0' color={ThemeColors.purple700} bold={true}>Digite a senha para acessar</Titles>
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Senha"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={handlePasswordChange}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Entrar
                </Button>
            </form>
        </Box>
    );
}

export default PasswordPrompt;