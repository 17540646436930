import React from "react";

const Emoji24 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.9 208.9"
    width={props.width || '85'} height={props.height || '85'}
>
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.9,0H55C24.63,0,0,24.62,0,55v98.9c0,30.37,24.63,55,55,55h98.9c30.38,0,55-24.63,55-55V55c0-30.38-24.62-55-55-55ZM46.32,87.14c-2.64,0-4.79-2.15-4.79-4.81s2.15-4.79,4.79-4.79h39.8c2.64,0,4.79,2.15,4.79,4.79s-2.15,4.81-4.79,4.81h-39.8ZM127.39,127.7c-.74,1.36-2.62,3.66-6.79,3.66h-32.31c-4.14,0-6.01-2.26-6.76-3.61-1.62-2.96-1.11-7.22,1.35-11.1,4.69-7.45,12.41-11.73,21.19-11.78h.16c8.85,0,16.97,4.39,21.76,11.74,2.49,3.83,3.02,8.09,1.4,11.09ZM151.79,114.14c-4.97,0-8.99-4.03-8.99-9s8.99-14.25,8.99-14.25c0,0,8.99,9.28,8.99,14.25s-4.02,9-8.99,9ZM162.59,87.14h-39.8c-2.64,0-4.79-2.15-4.79-4.81s2.15-4.79,4.79-4.79h39.8c2.64,0,4.78,2.15,4.78,4.79s-2.14,4.81-4.78,4.81Z"
        />
        <path
          className="cls-2"
          d="M86.12,87.14h-39.8c-2.64,0-4.79-2.15-4.79-4.81s2.15-4.79,4.79-4.79h39.8c2.64,0,4.79,2.15,4.79,4.79s-2.15,4.81-4.79,4.81Z"
        />
        <path
          className="cls-2"
          d="M125.99,116.61c2.49,3.83,3.02,8.09,1.4,11.09-.74,1.36-2.62,3.66-6.79,3.66h-32.31c-4.14,0-6.01-2.26-6.76-3.61-1.62-2.96-1.11-7.22,1.35-11.1,4.69-7.45,12.41-11.73,21.19-11.78h.16c8.85,0,16.97,4.39,21.76,11.74Z"
        />
        <path
          className="cls-2"
          d="M151.79,90.89s8.99,9.28,8.99,14.25-4.02,9-8.99,9-8.99-4.03-8.99-9,8.99-14.25,8.99-14.25Z"
        />
        <path
          className="cls-2"
          d="M167.37,82.33c0,2.66-2.14,4.81-4.78,4.81h-39.8c-2.64,0-4.79-2.15-4.79-4.81s2.15-4.79,4.79-4.79h39.8c2.64,0,4.78,2.15,4.78,4.79Z"
        />
        <g>
          <g>
            <path
              className="cls-1"
              d="M90.91,82.33c0,2.66-2.15,4.81-4.79,4.81h-39.8c-2.64,0-4.79-2.15-4.79-4.81s2.15-4.79,4.79-4.79h39.8c2.64,0,4.79,2.15,4.79,4.79Z"
            />
            <path
              className="cls-1"
              d="M167.37,82.33c0,2.66-2.14,4.81-4.78,4.81h-39.8c-2.64,0-4.79-2.15-4.79-4.81s2.15-4.79,4.79-4.79h39.8c2.64,0,4.78,2.15,4.78,4.79Z"
            />
          </g>
          <path
            className="cls-1"
            d="M127.39,127.7c-.74,1.36-2.62,3.66-6.79,3.66h-32.31c-4.14,0-6.01-2.26-6.76-3.61-1.62-2.96-1.11-7.22,1.35-11.1,4.69-7.45,12.41-11.73,21.19-11.78h.16c8.85,0,16.97,4.39,21.76,11.74,2.49,3.83,3.02,8.09,1.4,11.09Z"
          />
          <path
            className="cls-1"
            d="M160.78,105.14c0,4.97-4.02,9-8.99,9s-8.99-4.03-8.99-9,8.99-14.25,8.99-14.25c0,0,8.99,9.28,8.99,14.25Z"
          />
        </g>
      </g>
    </g>
  </svg>

); export default Emoji24