import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import DashboardPage from './pages/Dashboard';

import ChannelPage from './pages/Channel';
import ChannelPageAdd from './pages/Channel/ChannelPageAdd';
import ChannelPageAddWhatsApp from './pages/Channel/ChannelPageAddWhatsApp';
import ChannelPageConfig from './pages/Channel/ChannelPageConfig';

import Erro from './pages/Error';

import Templates from './pages/Templates';
import TemplateAdd from './pages/Templates/TemplatePageAdd';
import TemplateView from './pages/Templates/TemplateViewPage';
import PageOptionsChannel from './pages/Templates/TemplatePageOptionsChannel';
import PageOptionsTemplate from './pages/Templates/TemplatePageOptions';
import PageTemplateServices from './pages/Templates/TemplatePageServices';
import PageTemplateFinanceiro from './pages/Templates/TemplatePageFinanceiro';

import Reports from './pages/Reports';

import Sheets from './pages/Sheets';
import PageAddSheets from './pages/Sheets/addSheets';

import CampaignPage from './pages/Campaigns';
import PageAddPersonalized from './pages/Campaigns/PageAddPersonalized';
import PageAddFast from './pages/Campaigns/PageAddFast';

import Support from './pages/Support';
import PageAddRequest from './pages/Support/AddRequest';

import Charges from './pages/Charges';
import GenerateCharge from './pages/Charges/generateCharge';  

import LoginForm from './pages/Login';
import LoginRecoveryPage from './pages/Login/LoginRecoveryPage';

import { decrypted } from './authMiddleware';

import Styles from './components/Styles/Styles';
import TestPage from './pages/Test';
import EditTemplate from './pages/Templates/EditTemplate';
import { useAuth } from './context/AuthContext';
import TemplateEmailHTML from './pages/Templates/TemplateEmailHTML';
import EditTemplateHTML from './pages/Templates/EditTemplateHTML';
import PageTemplateMarketing from './pages/Templates/TemplatePageMarketing';
import HappyBirthdayTemplate from './pages/Templates/HtmlTemplates/HappyBirthDayTemplate';
import InvoicesTemplate from './pages/Templates/HtmlTemplates/Invoices';


import Apis from './pages/Apis';
import PageAddApis from './pages/Apis/addSheets';
import PageOptionsCampaign from './pages/Campaigns/addCampaignOptions';
import PageAddSelected from './pages/Campaigns/PageAddSelected';

function App() {

  const { userAuthenticated } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const userTypeCookie = Cookies.get(process.env.REACT_APP_COOKIE_USER_TYPE);
  const decryptedUserType = decrypted(userTypeCookie);

  if (loading) {
    return null;
  }

  return (

    <Routes>
      <Route path="/" element={userAuthenticated ? <Navigate to="/painel" /> : <LoginForm />} />
      
      {!userAuthenticated && (
        <>
          <Route path='/login/recovery/:token?' element={<LoginRecoveryPage />} />
          <Route path="/styles" element={<Styles />} />
          <Route path="*" element={<Navigate to="/" />} />
        </>
      )}

      {userAuthenticated && (
        <>
          {decryptedUserType === 'Admin' && (
            <>
              <Route path="/painel" element={<DashboardPage />} />
              <Route path="/relatorios" element={<Reports />} />
              <Route path="/apis" element={<Apis />} />
              <Route path="/apis/adicionar" element={<PageAddApis />} />
              <Route path="/canais" element={<ChannelPage />} />
              <Route path="/canais/adicionar" element={<ChannelPageAdd />} />
              <Route path="/canais/adicionar/whatsapp" element={<ChannelPageAddWhatsApp />} />
              <Route path="/canais/configurações" element={<ChannelPageConfig />} />
              <Route path="/modelos" element={<Templates />} />
              <Route path="/modelos/canal" element={<PageOptionsChannel />} />
              <Route path="/modelos/canal/template" element={<PageOptionsTemplate />} />
              <Route path="/modelos/canal/template/marketing" element={<PageTemplateMarketing />} />
              <Route path="/modelos/canal/template/marketing/aniversariante" element={<HappyBirthdayTemplate />} />
              <Route path="/modelos/canal/template/serviços" element={<PageTemplateServices />} />
              <Route path="/modelos/canal/template/serviços/senha" element={<TemplateEmailHTML />} />
              <Route path="/modelos/canal/template/financeiro" element={<PageTemplateFinanceiro />} />
              <Route path="/modelos/canal/template/financeiro/lembrete" element={<InvoicesTemplate />} />
              <Route path='/modelos/canal/whatsapp' element={< TemplateAdd typeChannel='WhatsApp' />} />
              <Route path='/modelos/canal/email' element={< TemplateAdd typeChannel='E-mail' />} />
              <Route path='/modelos/canal/sms' element={< TemplateAdd typeChannel='SMS' />} />
              <Route path='/modelos/editar' element={<EditTemplate />} />
              <Route path='/modelos/serviços/editar' element={<EditTemplateHTML />} />
              <Route path="/modelos/serviços" element={<PageTemplateFinanceiro />} />
              <Route path="/planilhas" element={<Sheets />} />
              <Route path="/planilhas/adicionar" element={<PageAddSheets />} />
              <Route path="/campanhas" element={<CampaignPage />} />
              <Route path="/campanhas/adicionar" element={<PageOptionsCampaign />} />
              <Route path="/template/view" element={<TemplateView />} />
              <Route path="/suporte" element={<Support />} />
              <Route path="/suporte/adicionar" element={<PageAddRequest />} />
              <Route path="/teste" element={<TestPage />} />
              <Route path="/campanhas/adicionar/modo-personalizado" element={<PageAddPersonalized />} />
              <Route path="/campanhas/adicionar/modo-rapido" element={<PageAddFast />} />

              <Route path="/cobranças" element={<Charges />} />
              <Route path="/cobranças/gerar" element={<GenerateCharge />} />
              <Route path="/campanhas/adicionar/modo-seleção" element={<PageAddSelected />} />
              
              <Route path="*" element={<Erro />} />
            </>
          )}

          {decryptedUserType === 'Client' && (
            <>
              <Route path="/painel" element={<DashboardPage />} />
              <Route path="/relatorios" element={<Reports />} />
              <Route path="/relatorios" element={<Reports />} />
              <Route path="/canais" element={<ChannelPage />} />
              <Route path="/canais/adicionar" element={<ChannelPageAdd />} />
              <Route path="/canais/adicionar/whatsapp" element={<ChannelPageAddWhatsApp />} />
              <Route path="/canais/configurações" element={<ChannelPageConfig />} />
              <Route path="/modelos" element={<Templates />} />
              <Route path="/modelos/canal" element={<PageOptionsChannel />} />
              <Route path="/modelos/canal/template" element={<PageOptionsTemplate />} />
              <Route path="/modelos/canal/template/marketing" element={<PageTemplateMarketing />} />
              <Route path="/modelos/canal/template/marketing/aniversariante" element={<HappyBirthdayTemplate />} />
              <Route path="/modelos/canal/template/serviços" element={<PageTemplateServices />} />
              <Route path="/modelos/canal/template/serviços/senha" element={<TemplateEmailHTML />} />
              <Route path="/modelos/canal/template/financeiro" element={<PageTemplateFinanceiro />} />
              <Route path="/modelos/canal/template/financeiro/lembrete" element={<InvoicesTemplate />} />
              <Route path='/modelos/canal/whatsapp' element={< TemplateAdd typeChannel='WhatsApp' />} />
              <Route path='/modelos/canal/email' element={< TemplateAdd typeChannel='E-mail' />} />
              <Route path='/modelos/canal/sms' element={< TemplateAdd typeChannel='SMS' />} />
              <Route path='/modelos/editar' element={<EditTemplate />} />
              <Route path='/modelos/serviços/editar' element={<EditTemplateHTML />} />
              <Route path="/planilhas" element={<Sheets />} />
              <Route path="/planilhas/adicionar" element={<PageAddSheets />} />
              <Route path="/campanhas" element={<CampaignPage />} />
              <Route path="/campanhas/adicionar" element={<PageOptionsCampaign />} />
              <Route path="/campanhas/adicionar/modo-personalizado" element={<PageAddPersonalized />} />

              <Route path="/template/view" element={<TemplateView />} />
              <Route path="/suporte" element={<Support />} />
              <Route path="/suporte/adicionar" element={<PageAddRequest />} />
              <Route path="*" element={<Erro />} />
            </>
          )}

          {decryptedUserType === 'atendente' && (
            <>
              <Route path="/painel" element={<DashboardPage />} />
              <Route path="/channel" element={<ChannelPage />} />
              {/* Adicione rotas específicas para atendente aqui */}
              <Route path="*" element={<Erro />} />
            </>
          )}
        </>
      )}
    </Routes>
  );
}

export default App;
