import React from "react";

const Emoji16 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.89 208.9"
    width={props.width || '85'} height={props.height || '85'}
  >
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.89,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.89c30.38,0,55-24.63,55-55V55c0-30.38-24.62-55-55-55ZM61.92,105.92c-6.77,0-12.26-6.2-12.26-13.86s5.49-13.86,12.26-13.86,12.27,6.21,12.27,13.86-5.49,13.86-12.27,13.86ZM124.34,130.7h-39.79c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.79c2.65,0,4.8,2.15,4.8,4.8s-2.15,4.8-4.8,4.8ZM146.96,105.92c-6.77,0-12.26-6.2-12.26-13.86s5.49-13.86,12.26-13.86,12.27,6.21,12.27,13.86-5.49,13.86-12.27,13.86Z"
        />
        <path
          className="cls-2"
          d="M74.19,92.06c0,7.66-5.49,13.86-12.27,13.86s-12.26-6.2-12.26-13.86,5.49-13.86,12.26-13.86,12.27,6.21,12.27,13.86Z"
        />
        <path
          className="cls-2"
          d="M129.14,125.9c0,2.65-2.15,4.8-4.8,4.8h-39.79c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.79c2.65,0,4.8,2.15,4.8,4.8Z"
        />
        <path
          className="cls-2"
          d="M159.23,92.06c0,7.66-5.49,13.86-12.27,13.86s-12.26-6.2-12.26-13.86,5.49-13.86,12.26-13.86,12.27,6.21,12.27,13.86Z"
        />
        <g>
          <g>
            <path
              className="cls-1"
              d="M74.19,92.06c0,7.66-5.49,13.86-12.27,13.86s-12.26-6.2-12.26-13.86,5.49-13.86,12.26-13.86,12.27,6.21,12.27,13.86Z"
            />
            <path
              className="cls-1"
              d="M159.23,92.06c0,7.66-5.49,13.86-12.27,13.86s-12.26-6.2-12.26-13.86,5.49-13.86,12.26-13.86,12.27,6.21,12.27,13.86Z"
            />
          </g>
          <path
            className="cls-1"
            d="M129.14,125.9c0,2.65-2.15,4.8-4.8,4.8h-39.79c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.79c2.65,0,4.8,2.15,4.8,4.8Z"
          />
        </g>
      </g>
    </g>
  </svg>

); export default Emoji16