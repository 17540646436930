// Desc: This Component is responsible for displaying a loading animation while the page is loading
// Update: 12/08/2024

// React Components and DOM Elements
import React from 'react';
import { useEffect } from 'react';

// Function and Components from the project
import Lottie from 'react-lottie';
import loading from './Loading.json';

// Render Your Component
const Loading = ({ open }) => {
// Define the state at the top before any early return
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  // Define effect hook at the top level
  useEffect(() => {
    const mainContent = document.querySelector('.MainContent');
    console.log(mainContent, 'achei')
    if (mainContent && mainContent.classList.contains('collapsed')) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, []);

  // Early return based on 'open'
  if (!open) {
    return null;
  }

  // Default options for Lottie animation
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 0,
      left: isCollapsed ? '2%' : '5%',
      width: '100%',
      height: '100%',
      // backgroundColor: 'rgba(0,0,0,0.1)',
      zIndex: 1000,
      alignItems: 'center',
      position: 'fixed',
    }}>
      <Lottie options={defaultOptions} height={400} width={400} />
    </div>
  );
};

export default Loading;