// Description: This component render a list of channel cards 
// Update: 12/08/2024

// React Components and DOM Elements
import React from 'react'

// Material UI Components
import { Grid } from '@mui/material';

// Components and functions from the project
import CreateCardList from "./CreateCardList";

// Render Your Component
const CardList = ({ data }) => {

  return (
    <Grid container gap={"20px"} width={"100%"} style={{ marginTop: "20px" }}>
        {
            data.map((content, index) => (
                <CreateCardList key={index} title={content.title} text={content.text} icon={content.icon} titleColor={content.titleColor} handleClick={content.onClick} alreadyPaid={content.pago} iconBackground={content.iconBackground} iconColor={content.iconColor} heightIcon={content.heightIcon} />
            ))
        }
    </Grid>
  )
}

export default CardList