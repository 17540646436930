import React from "react";

const Emoji20 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.89 208.9"
    width={props.width || '85'} height={props.height || '85'}
>

    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.89,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.89c30.38,0,55-24.63,55-55V55c0-30.38-24.62-55-55-55ZM71.94,104.64l-5.1,3.3c-.53.34-1.15.51-1.75.51s-1.2-.17-1.73-.51l-5.11-3.3c-12.58-8.14-14.04-14.67-14.04-18.88,0-6.95,5.66-12.6,12.62-12.6,3.07,0,6,1.13,8.26,3.1,2.26-1.97,5.19-3.1,8.29-3.1,6.95,0,12.61,5.65,12.61,12.6,0,4.21-1.45,10.74-14.05,18.88ZM127.47,118.46c0,.16,0,.33-.04.47-1.78,11.62-12.82,16.81-22.98,16.81s-21.21-5.19-22.99-16.81c-.02-.14-.04-.31-.04-.47v-9.76c0-1.76,1.44-3.19,3.21-3.19h39.65c1.77,0,3.19,1.43,3.19,3.19v9.76ZM150.64,104.64l-5.09,3.3c-.55.34-1.15.51-1.75.51s-1.2-.17-1.73-.51l-5.12-3.3c-12.58-8.14-14.03-14.67-14.03-18.88,0-6.95,5.64-12.6,12.59-12.6,3.1,0,6.03,1.13,8.29,3.1,2.26-1.97,5.19-3.1,8.28-3.1,6.96,0,12.6,5.65,12.6,12.6,0,4.21-1.44,10.74-14.04,18.88Z"
        />
        <path
          className="cls-2"
          d="M85.99,85.76c0,4.21-1.45,10.74-14.05,18.88l-5.1,3.3c-.53.34-1.15.51-1.75.51s-1.2-.17-1.73-.51l-5.11-3.3c-12.58-8.14-14.04-14.67-14.04-18.88,0-6.95,5.66-12.6,12.62-12.6,3.07,0,6,1.13,8.26,3.1,2.26-1.97,5.19-3.1,8.29-3.1,6.95,0,12.61,5.65,12.61,12.6Z"
        />
        <path
          className="cls-2"
          d="M127.47,108.7v9.76c0,.16,0,.33-.04.47-1.78,11.62-12.82,16.81-22.98,16.81s-21.21-5.19-22.99-16.81c-.02-.14-.04-.31-.04-.47v-9.76c0-1.76,1.44-3.19,3.21-3.19h39.65c1.77,0,3.19,1.43,3.19,3.19Z"
        />
        <path
          className="cls-2"
          d="M164.68,85.76c0,4.21-1.44,10.74-14.04,18.88l-5.09,3.3c-.55.34-1.15.51-1.75.51s-1.2-.17-1.73-.51l-5.12-3.3c-12.58-8.14-14.03-14.67-14.03-18.88,0-6.95,5.64-12.6,12.59-12.6,3.1,0,6.03,1.13,8.29,3.1,2.26-1.97,5.19-3.1,8.28-3.1,6.96,0,12.6,5.65,12.6,12.6Z"
        />
        <g>
          <path
            className="cls-1"
            d="M127.47,108.7v9.76c0,.16,0,.33-.04.47-1.78,11.62-12.82,16.81-22.98,16.81s-21.21-5.19-22.99-16.81c-.02-.14-.04-.31-.04-.47v-9.76c0-1.76,1.44-3.19,3.21-3.19h39.65c1.77,0,3.19,1.43,3.19,3.19Z"
          />
          <g>
            <path
              className="cls-1"
              d="M85.99,85.76c0,4.21-1.45,10.74-14.05,18.88l-5.1,3.3c-.53.34-1.15.51-1.75.51s-1.2-.17-1.73-.51l-5.11-3.3c-12.58-8.14-14.04-14.67-14.04-18.88,0-6.95,5.66-12.6,12.62-12.6,3.07,0,6,1.13,8.26,3.1,2.26-1.97,5.19-3.1,8.29-3.1,6.95,0,12.61,5.65,12.61,12.6Z"
            />
            <path
              className="cls-1"
              d="M164.68,85.76c0,4.21-1.44,10.74-14.04,18.88l-5.09,3.3c-.55.34-1.15.51-1.75.51s-1.2-.17-1.73-.51l-5.12-3.3c-12.58-8.14-14.03-14.67-14.03-18.88,0-6.95,5.64-12.6,12.59-12.6,3.1,0,6.03,1.13,8.29,3.1,2.26-1.97,5.19-3.1,8.28-3.1,6.96,0,12.6,5.65,12.6,12.6Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>

); export default Emoji20