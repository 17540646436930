import React from "react";

const Emoji27 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.9 208.9"
    width={props.width || '85'} height={props.height || '85'}
>
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.9,0H55C24.63,0,0,24.62,0,55v98.9c0,30.38,24.63,55,55,55h98.9c30.38,0,55-24.62,55-55V55c0-30.38-24.62-55-55-55ZM46.32,85.87c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.8c2.65,0,4.79,2.15,4.79,4.8s-2.14,4.8-4.79,4.8h-39.8ZM127.47,115.35c0,.17,0,.33-.04.48-1.78,11.61-12.81,16.8-22.97,16.8s-21.21-5.19-23-16.8c-.01-.15-.03-.31-.03-.48v-9.76c0-1.76,1.44-3.18,3.2-3.18h39.66c1.76,0,3.18,1.42,3.18,3.18v9.76ZM162.58,85.87h-39.79c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.79c2.65,0,4.8,2.15,4.8,4.8s-2.15,4.8-4.8,4.8Z"
        />
        <path
          className="cls-2"
          d="M86.12,85.87h-39.8c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.8c2.65,0,4.79,2.15,4.79,4.8s-2.14,4.8-4.79,4.8Z"
        />
        <path
          className="cls-2"
          d="M127.47,105.59v9.76c0,.17,0,.33-.04.48-1.78,11.61-12.81,16.8-22.97,16.8s-21.21-5.19-23-16.8c-.01-.15-.03-.31-.03-.48v-9.76c0-1.76,1.44-3.18,3.2-3.18h39.66c1.76,0,3.18,1.42,3.18,3.18Z"
        />
        <path
          className="cls-2"
          d="M167.38,81.07c0,2.65-2.15,4.8-4.8,4.8h-39.79c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.79c2.65,0,4.8,2.15,4.8,4.8Z"
        />
        <g>
          <path
            className="cls-1"
            d="M127.47,105.59v9.76c0,.17,0,.33-.04.48-1.78,11.61-12.81,16.8-22.97,16.8s-21.21-5.19-23-16.8c-.01-.15-.03-.31-.03-.48v-9.76c0-1.76,1.44-3.18,3.2-3.18h39.66c1.76,0,3.18,1.42,3.18,3.18Z"
          />
          <g>
            <path
              className="cls-1"
              d="M90.91,81.07c0,2.65-2.14,4.8-4.79,4.8h-39.8c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.8c2.65,0,4.79,2.15,4.79,4.8Z"
            />
            <path
              className="cls-1"
              d="M167.38,81.07c0,2.65-2.15,4.8-4.8,4.8h-39.79c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.79c2.65,0,4.8,2.15,4.8,4.8Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>

); export default Emoji27