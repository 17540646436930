import React from "react";

const Emoji19 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.9 208.9"
    width={props.width || '85'} height={props.height || '85'}
>
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF\n        ;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.9,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.9c30.37,0,55-24.63,55-55V55c0-30.38-24.63-55-55-55ZM72.43,104.09c-4.97,0-8.99-4.02-8.99-8.99v-7.99h-17.14c-2.64,0-4.78-2.15-4.78-4.81s2.14-4.79,4.78-4.79h39.8c2.66,0,4.81,2.15,4.81,4.79s-2.15,4.81-4.81,4.81h-4.67v7.99c0,4.97-4.03,8.99-9,8.99ZM127.38,127.73c-.75,1.36-2.62,3.66-6.79,3.66h-32.32c-4.13,0-5.99-2.26-6.74-3.61-1.63-2.97-1.12-7.23,1.33-11.11,4.7-7.44,12.44-11.72,21.2-11.77,8.92-.02,17.11,4.33,21.92,11.74,2.49,3.82,3.02,8.08,1.4,11.09ZM162.57,87.11h-3.95v7.99c0,4.97-4.02,8.99-8.99,8.99s-9-4.02-9-8.99v-7.99h-17.86c-2.64,0-4.79-2.15-4.79-4.81s2.15-4.79,4.79-4.79h39.8c2.66,0,4.81,2.15,4.81,4.79s-2.15,4.81-4.81,4.81Z"
        />
        <path
          className="cls-2"
          d="M81.43,95.1c0,4.97-4.03,8.99-9,8.99s-8.99-4.02-8.99-8.99v-7.99h-17.14c-2.64,0-4.78-2.15-4.78-4.81s2.14-4.79,4.78-4.79h39.8c2.66,0,4.81,2.15,4.81,4.79s-2.15,4.81-4.81,4.81h-4.67v7.99Z"
        />
        <path
          className="cls-2"
          d="M125.98,116.64c2.49,3.82,3.02,8.08,1.4,11.09-.75,1.36-2.62,3.66-6.79,3.66h-32.32c-4.13,0-5.99-2.26-6.74-3.61-1.63-2.97-1.12-7.23,1.33-11.11,4.7-7.44,12.44-11.72,21.2-11.77,8.92-.02,17.11,4.33,21.92,11.74Z"
        />
        <path
          className="cls-2"
          d="M167.38,82.3c0,2.66-2.15,4.81-4.81,4.81h-3.95v7.99c0,4.97-4.02,8.99-8.99,8.99s-9-4.02-9-8.99v-7.99h-17.86c-2.64,0-4.79-2.15-4.79-4.81s2.15-4.79,4.79-4.79h39.8c2.66,0,4.81,2.15,4.81,4.79Z"
        />
        <g>
          <path
            className="cls-1"
            d="M127.38,127.73c-.75,1.36-2.62,3.66-6.79,3.66h-32.32c-4.13,0-5.99-2.26-6.74-3.61-1.63-2.97-1.12-7.23,1.33-11.11,4.7-7.44,12.44-11.72,21.2-11.77,8.92-.02,17.11,4.33,21.92,11.74,2.49,3.82,3.02,8.08,1.4,11.09Z"
          />
          <g>
            <path
              className="cls-1"
              d="M90.91,82.3c0,2.66-2.15,4.81-4.81,4.81h-4.67v7.99c0,4.97-4.03,8.99-9,8.99s-8.99-4.02-8.99-8.99v-7.99h-17.14c-2.64,0-4.78-2.15-4.78-4.81s2.14-4.79,4.78-4.79h39.8c2.66,0,4.81,2.15,4.81,4.79Z"
            />
            <path
              className="cls-1"
              d="M167.38,82.3c0,2.66-2.15,4.81-4.81,4.81h-3.95v7.99c0,4.97-4.02,8.99-8.99,8.99s-9-4.02-9-8.99v-7.99h-17.86c-2.64,0-4.79-2.15-4.79-4.81s2.15-4.79,4.79-4.79h39.8c2.66,0,4.81,2.15,4.81,4.79Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>

); export default Emoji19