import React from "react";

const Emoji04 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.9 208.9"
    width={props.width || '85'} height={props.height || '85'}
  >
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.9,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.9c30.37,0,55-24.63,55-55V55c0-30.38-24.63-55-55-55ZM63.28,118.3c-6.77,0-12.25-6.19-12.25-13.85s5.48-13.86,12.25-13.86,12.27,6.21,12.27,13.86-5.49,13.85-12.27,13.85ZM148.33,118.3c-6.78,0-12.28-6.19-12.28-13.85s5.5-13.86,12.28-13.86,12.25,6.21,12.25,13.86-5.48,13.85-12.25,13.85Z"
        />
        <path
          className="cls-2"
          d="M75.55,104.45c0,7.66-5.49,13.85-12.27,13.85s-12.25-6.19-12.25-13.85,5.48-13.86,12.25-13.86,12.27,6.21,12.27,13.86Z"
        />
        <path
          className="cls-2"
          d="M160.58,104.45c0,7.66-5.48,13.85-12.25,13.85s-12.28-6.19-12.28-13.85,5.5-13.86,12.28-13.86,12.25,6.21,12.25,13.86Z"
        />
        <g>
          <path
            className="cls-1"
            d="M75.55,104.45c0,7.66-5.49,13.85-12.27,13.85s-12.25-6.19-12.25-13.85,5.48-13.86,12.25-13.86,12.27,6.21,12.27,13.86Z"
          />
          <path
            className="cls-1"
            d="M160.58,104.45c0,7.66-5.48,13.85-12.25,13.85s-12.28-6.19-12.28-13.85,5.5-13.86,12.28-13.86,12.25,6.21,12.25,13.86Z"
          />
        </g>
      </g>
    </g>
  </svg>

); export default Emoji04