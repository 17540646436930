// Desc: Component render the type of the application
// Update: 12/08/2024

// Material-UI Components
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Avatar, IconButton, Typography } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PhoneIcon from '@mui/icons-material/Phone';

import VideocamIcon from '@mui/icons-material/Videocam';
import EmojiRounded from './../../assets/ilustrations/EmojiRounded01.png';
import ThemeColors from '../ThemeColors/ThemeColors';

// Render the header of the application
const RenderTypeAplication = (props) => {

    const { type } = props;

    switch (type) {
        case 'whatsapp':
            return (
                <div style={{
                    background: ThemeColors.purple500, padding: '10px 0', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', boxSizing: 'border-box', height: '70px' // Garante que o padding não afete a largura total
                }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton color="inherit">
                        </IconButton>
                        <Avatar
                            style={{ margin: '0 8px' }}
                            src={EmojiRounded}
                        />
                        <div style={{ color: 'white', marginLeft: '8px' }}>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton color="inherit">
                            <VideocamIcon style={{ color: 'white', fontSize: '25px' }} />
                        </IconButton>
                        <IconButton color="inherit">
                            <PhoneIcon style={{ color: 'white', fontSize: '25px' }} />
                        </IconButton>
                        <IconButton color="inherit">
                            <MoreVertIcon style={{ color: 'white', fontSize: '25px' }} />
                        </IconButton>
                    </div>
                </div>
            );

        case 'sms':
            return (
                <div style={{
                    background: ThemeColors.purple500, padding: '10px 0', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', boxSizing: 'border-box', height: '70px' // Garante que o padding não afete a largura total
                }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton color="inherit">
                        </IconButton>
                        <Avatar
                            style={{ margin: '0 8px' }}
                            src={EmojiRounded}
                        />
                        <div style={{ color: 'white', marginLeft: '8px' }}>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton color="inherit">
                        </IconButton>
                        <IconButton color="inherit">
                            <PhoneIcon style={{ color: 'white', fontSize: '25px' }} />
                        </IconButton>
                        <IconButton color="inherit">
                            <MoreVertIcon style={{ color: 'white', fontSize: '25px' }} />
                        </IconButton>
                    </div>
                </div>
            );
        default:
            return null;
    }
};

export default RenderTypeAplication;