import React from "react";

const Emoji25 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.89 208.9"
    width={props.width || '85'} height={props.height || '85'}
>
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.89,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.89c30.38,0,55-24.63,55-55V55c0-30.38-24.62-55-55-55ZM69.22,107.59c-2.92,0-5.79-.87-8.26-2.55-3.28-2.22-5.49-5.59-6.24-9.47-.49-2.6,1.21-5.12,3.82-5.62,2.6-.48,5.11,1.21,5.61,3.82.26,1.36,1.04,2.54,2.19,3.32,1.15.78,2.54,1.07,3.89.8,4.18-.8,6.93-4.84,6.13-9.02-1.13-5.85-6.83-9.73-12.68-8.59-7.98,1.54-13.23,9.27-11.7,17.25.5,2.61-1.21,5.12-3.81,5.62-2.6.48-5.12-1.21-5.61-3.81-2.53-13.17,6.14-25.95,19.31-28.48,11.05-2.13,21.78,5.15,23.91,16.2,1.79,9.38-4.37,18.46-13.73,20.26-.94.18-1.89.27-2.83.27ZM127.77,128.32c-6.11,6.51-14.4,10.09-23.32,10.09s-17.22-3.58-23.33-10.09c-1.81-1.93-1.72-4.97.21-6.78,1.93-1.82,4.97-1.73,6.79.21,4.28,4.55,10.07,7.06,16.33,7.06s12.05-2.51,16.32-7.06c1.82-1.94,4.86-2.03,6.79-.21,1.93,1.81,2.02,4.85.21,6.78ZM147.02,108.93c-1.29.24-2.58.36-3.85.36-9.59.01-18.18-6.81-20.06-16.57-1.79-9.38,4.37-18.46,13.73-20.25,3.88-.75,7.82.06,11.09,2.28,3.28,2.22,5.49,5.58,6.24,9.47.49,2.6-1.21,5.11-3.82,5.61-2.6.49-5.11-1.21-5.61-3.81-.26-1.37-1.04-2.55-2.19-3.33-1.15-.78-2.53-1.06-3.89-.8-4.18.8-6.93,4.85-6.13,9.02,1.13,5.86,6.82,9.73,12.68,8.59,7.98-1.53,13.23-9.27,11.7-17.25-.5-2.6,1.21-5.12,3.81-5.62,2.6-.48,5.12,1.21,5.61,3.81,2.53,13.18-6.14,25.96-19.31,28.49Z"
        />
        <path
          className="cls-2"
          d="M72.05,107.32c-.94.18-1.89.27-2.83.27-2.92,0-5.79-.87-8.26-2.55-3.28-2.22-5.49-5.59-6.24-9.47-.49-2.6,1.21-5.12,3.82-5.62,2.6-.48,5.11,1.21,5.61,3.82.26,1.36,1.04,2.54,2.19,3.32,1.15.78,2.54,1.07,3.89.8,4.18-.8,6.93-4.84,6.13-9.02-1.13-5.85-6.83-9.73-12.68-8.59-7.98,1.54-13.23,9.27-11.7,17.25.5,2.61-1.21,5.12-3.81,5.62-2.6.48-5.12-1.21-5.61-3.81-2.53-13.17,6.14-25.95,19.31-28.48,11.05-2.13,21.78,5.15,23.91,16.2,1.79,9.38-4.37,18.46-13.73,20.26Z"
        />
        <path
          className="cls-2"
          d="M127.56,121.54c1.93,1.81,2.02,4.85.21,6.78-6.11,6.51-14.4,10.09-23.32,10.09s-17.22-3.58-23.33-10.09c-1.81-1.93-1.72-4.97.21-6.78,1.93-1.82,4.97-1.73,6.79.21,4.28,4.55,10.07,7.06,16.33,7.06s12.05-2.51,16.32-7.06c1.82-1.94,4.86-2.03,6.79-.21Z"
        />
        <path
          className="cls-2"
          d="M147.02,108.93c-1.29.24-2.58.36-3.85.36-9.59.01-18.18-6.81-20.06-16.57-1.79-9.38,4.37-18.46,13.73-20.25,3.88-.75,7.82.06,11.09,2.28,3.28,2.22,5.49,5.58,6.24,9.47.49,2.6-1.21,5.11-3.82,5.61-2.6.49-5.11-1.21-5.61-3.81-.26-1.37-1.04-2.55-2.19-3.33-1.15-.78-2.53-1.06-3.89-.8-4.18.8-6.93,4.85-6.13,9.02,1.13,5.86,6.82,9.73,12.68,8.59,7.98-1.53,13.23-9.27,11.7-17.25-.5-2.6,1.21-5.12,3.81-5.62,2.6-.48,5.12,1.21,5.61,3.81,2.53,13.18-6.14,25.96-19.31,28.49Z"
        />
        <g>
          <path
            className="cls-1"
            d="M127.77,128.32c-6.11,6.51-14.4,10.09-23.32,10.09s-17.22-3.58-23.33-10.09c-1.81-1.93-1.72-4.97.21-6.78,1.93-1.82,4.97-1.73,6.79.21,4.28,4.55,10.07,7.06,16.33,7.06s12.05-2.51,16.32-7.06c1.82-1.94,4.86-2.03,6.79-.21,1.93,1.81,2.02,4.85.21,6.78Z"
          />
          <g>
            <path
              className="cls-1"
              d="M72.05,107.32c-.94.18-1.89.27-2.83.27-2.92,0-5.79-.87-8.26-2.55-3.28-2.22-5.49-5.59-6.24-9.47-.49-2.6,1.21-5.12,3.82-5.62,2.6-.48,5.11,1.21,5.61,3.82.26,1.36,1.04,2.54,2.19,3.32,1.15.78,2.54,1.07,3.89.8,4.18-.8,6.93-4.84,6.13-9.02-1.13-5.85-6.83-9.73-12.68-8.59-7.98,1.54-13.23,9.27-11.7,17.25.5,2.61-1.21,5.12-3.81,5.62-2.6.48-5.12-1.21-5.61-3.81-2.53-13.17,6.14-25.95,19.31-28.48,11.05-2.13,21.78,5.15,23.91,16.2,1.79,9.38-4.37,18.46-13.73,20.26Z"
            />
            <path
              className="cls-1"
              d="M147.02,108.93c-1.29.24-2.58.36-3.85.36-9.59.01-18.18-6.81-20.06-16.57-1.79-9.38,4.37-18.46,13.73-20.25,3.88-.75,7.82.06,11.09,2.28,3.28,2.22,5.49,5.58,6.24,9.47.49,2.6-1.21,5.11-3.82,5.61-2.6.49-5.11-1.21-5.61-3.81-.26-1.37-1.04-2.55-2.19-3.33-1.15-.78-2.53-1.06-3.89-.8-4.18.8-6.93,4.85-6.13,9.02,1.13,5.86,6.82,9.73,12.68,8.59,7.98-1.53,13.23-9.27,11.7-17.25-.5-2.6,1.21-5.12,3.81-5.62,2.6-.48,5.12,1.21,5.61,3.81,2.53,13.18-6.14,25.96-19.31,28.49Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>

); export default Emoji25