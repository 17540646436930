import React from "react";

const Emoji10 = (props) => (

    <svg
        id="Camada_2"
        data-name="Camada 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 208.89 208.9"
        width={props.width || '85'} height={props.height || '85'}
        >
        <defs>
            <style>
                {
                    "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
                }
            </style>
        </defs>
        <g id="Layer_1" data-name="Layer 1">
            <g>
                <path
                    className="cls-2"
                    d="M153.89,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.89c30.38,0,55-24.63,55-55V55c0-30.38-24.62-55-55-55ZM72.41,93.77c1.88,1.88,1.88,4.92,0,6.78-.95.94-2.17,1.42-3.39,1.42s-2.45-.48-3.4-1.42l-3.7-3.7-3.69,3.7c-.95.94-2.17,1.42-3.41,1.42s-2.45-.48-3.38-1.42c-1.88-1.86-1.88-4.9,0-6.78l3.71-3.69-3.71-3.72c-1.88-1.87-1.88-4.91,0-6.79,1.87-1.85,4.91-1.85,6.79,0l3.69,3.72,3.7-3.7c1.87-1.87,4.92-1.87,6.79,0s1.88,4.9,0,6.77l-3.7,3.7,3.7,3.71ZM127.38,127.04c-.75,1.38-2.62,3.68-6.79,3.68h-32.32c-4.13,0-5.99-2.28-6.74-3.62-1.63-2.97-1.12-7.21,1.33-11.11,4.7-7.43,12.44-11.73,21.2-11.78h.16c8.85,0,16.97,4.39,21.76,11.76,2.49,3.83,3.02,8.07,1.4,11.07ZM157.45,93.77c1.88,1.88,1.88,4.92,0,6.78-.94.94-2.16,1.42-3.4,1.42s-2.46-.48-3.39-1.42l-3.69-3.7-3.72,3.7c-.93.94-2.16,1.42-3.38,1.42s-2.46-.48-3.39-1.42c-1.88-1.86-1.88-4.9,0-6.78l3.71-3.69-3.71-3.72c-1.88-1.87-1.88-4.91,0-6.79,1.87-1.85,4.92-1.85,6.77,0l3.72,3.72,3.69-3.7c1.88-1.87,4.92-1.87,6.79,0s1.88,4.9,0,6.77l-3.71,3.7,3.71,3.71Z"
                />
                <path
                    className="cls-2"
                    d="M72.41,86.36l-3.7,3.7,3.7,3.71c1.88,1.88,1.88,4.92,0,6.78-.95.94-2.17,1.42-3.39,1.42s-2.45-.48-3.4-1.42l-3.7-3.7-3.69,3.7c-.95.94-2.17,1.42-3.41,1.42s-2.45-.48-3.38-1.42c-1.88-1.86-1.88-4.9,0-6.78l3.71-3.69-3.71-3.72c-1.88-1.87-1.88-4.91,0-6.79,1.87-1.85,4.91-1.85,6.79,0l3.69,3.72,3.7-3.7c1.87-1.87,4.92-1.87,6.79,0s1.88,4.9,0,6.77Z"
                />
                <path
                    className="cls-2"
                    d="M125.98,115.97c2.49,3.83,3.02,8.07,1.4,11.07-.75,1.38-2.62,3.68-6.79,3.68h-32.32c-4.13,0-5.99-2.28-6.74-3.62-1.63-2.97-1.12-7.21,1.33-11.11,4.7-7.43,12.44-11.73,21.2-11.78h.16c8.85,0,16.97,4.39,21.76,11.76Z"
                />
                <path
                    className="cls-2"
                    d="M157.45,93.77c1.88,1.88,1.88,4.92,0,6.78-.94.94-2.16,1.42-3.4,1.42s-2.46-.48-3.39-1.42l-3.69-3.7-3.72,3.7c-.93.94-2.16,1.42-3.38,1.42s-2.46-.48-3.39-1.42c-1.88-1.86-1.88-4.9,0-6.78l3.71-3.69-3.71-3.72c-1.88-1.87-1.88-4.91,0-6.79,1.87-1.85,4.92-1.85,6.77,0l3.72,3.72,3.69-3.7c1.88-1.87,4.92-1.87,6.79,0s1.88,4.9,0,6.77l-3.71,3.7,3.71,3.71Z"
                />
                <g>
                    <path
                        className="cls-1"
                        d="M127.38,127.04c-.75,1.38-2.62,3.68-6.79,3.68h-32.32c-4.13,0-5.99-2.28-6.74-3.62-1.63-2.97-1.12-7.21,1.33-11.11,4.7-7.43,12.44-11.73,21.2-11.78h.16c8.85,0,16.97,4.39,21.76,11.76,2.49,3.83,3.02,8.07,1.4,11.07Z"
                    />
                    <path
                        className="cls-1"
                        d="M72.41,93.77c1.88,1.88,1.88,4.92,0,6.78-.95.94-2.17,1.42-3.39,1.42s-2.45-.48-3.4-1.42l-3.7-3.7-3.69,3.7c-.95.94-2.17,1.42-3.41,1.42s-2.45-.48-3.38-1.42c-1.88-1.86-1.88-4.9,0-6.78l3.71-3.69-3.71-3.72c-1.88-1.87-1.88-4.91,0-6.79,1.87-1.85,4.91-1.85,6.79,0l3.69,3.72,3.7-3.7c1.87-1.87,4.92-1.87,6.79,0s1.88,4.9,0,6.77l-3.7,3.7,3.7,3.71Z"
                    />
                    <path
                        className="cls-1"
                        d="M157.45,93.77c1.88,1.88,1.88,4.92,0,6.78-.94.94-2.16,1.42-3.4,1.42s-2.46-.48-3.39-1.42l-3.69-3.7-3.72,3.7c-.93.94-2.16,1.42-3.38,1.42s-2.46-.48-3.39-1.42c-1.88-1.86-1.88-4.9,0-6.78l3.71-3.69-3.71-3.72c-1.88-1.87-1.88-4.91,0-6.79,1.87-1.85,4.92-1.85,6.77,0l3.72,3.72,3.69-3.7c1.88-1.87,4.92-1.87,6.79,0s1.88,4.9,0,6.77l-3.71,3.7,3.71,3.71Z"
                    />
                </g>
            </g>
        </g>
    </svg>

); export default Emoji10