import React from "react";

const Emoji28 = (props) => (

    <svg
        id="Camada_2"
        data-name="Camada 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 208.9 208.9"
        width={props.width || '85'} height={props.height || '85'}
        >
        <defs>
            <style>
                {
                    "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
                }
            </style>
        </defs>
        <g id="Layer_1" data-name="Layer 1">
            <g>
                <path
                    className="cls-2"
                    d="M153.9,0H55C24.62,0,0,24.62,0,55v98.9c0,30.38,24.62,55,55,55h98.9c30.38,0,55-24.62,55-55V55c0-30.38-24.62-55-55-55ZM61.93,105.12c-6.77,0-12.26-6.2-12.26-13.86s5.49-13.86,12.26-13.86,12.26,6.21,12.26,13.86-5.49,13.86-12.26,13.86ZM129.52,116.92c-3.55,1.16-5.59,3.11-6.75,5.04-.02.04-.02.05-.04.07-.11.18-.22.38-.29.57-.02.01-.04.03-.04.05,0,0-.02,0-.02.02-1.25,2.51-1.13,4.88-1.09,5.33.15,1.75-1.15,3.32-2.89,3.46-.11.02-.2.02-.3.02-1.63,0-3.02-1.22-3.18-2.88-.17-1.67,0-4.02.84-6.49-7.01-3.76-15.59-3.76-22.6,0,.82,2.47.98,4.82.82,6.49-.16,1.66-1.55,2.9-3.19,2.9-.09,0-.2-.02-.3-.02-1.75-.18-3.05-1.73-2.88-3.48.04-.45.14-2.82-1.11-5.35,0-.02-.02-.04-.04-.05-.07-.19-.18-.39-.29-.57-.02-.02-.02-.03-.04-.07-1.14-1.93-3.18-3.88-6.73-5.04-1.7-.57-2.61-2.37-2.04-4.05.54-1.67,2.37-2.58,4.04-2.03,3.91,1.29,6.7,3.35,8.63,5.66,8.96-4.81,19.9-4.81,28.84,0,1.95-2.31,4.73-4.37,8.65-5.66,1.67-.55,3.47.36,4.02,2.03.57,1.68-.34,3.48-2.02,4.05ZM146.97,105.12c-6.77,0-12.26-6.2-12.26-13.86s5.49-13.86,12.26-13.86,12.26,6.21,12.26,13.86-5.49,13.86-12.26,13.86Z"
                />
                <ellipse
                    className="cls-2"
                    cx={61.93}
                    cy={91.26}
                    rx={12.26}
                    ry={13.86}
                />
                <path
                    className="cls-2"
                    d="M131.54,112.87c.57,1.68-.34,3.48-2.02,4.05-3.55,1.16-5.59,3.11-6.75,5.04-.02.04-.02.05-.04.07-.11.18-.22.38-.29.57-.02.01-.04.03-.04.05,0,0-.02,0-.02.02-1.25,2.51-1.13,4.88-1.09,5.33.15,1.75-1.15,3.32-2.89,3.46-.11.02-.2.02-.3.02-1.63,0-3.02-1.22-3.18-2.88-.17-1.67,0-4.02.84-6.49-7.01-3.76-15.59-3.76-22.6,0,.82,2.47.98,4.82.82,6.49-.16,1.66-1.55,2.9-3.19,2.9-.09,0-.2-.02-.3-.02-1.75-.18-3.05-1.73-2.88-3.48.04-.45.14-2.82-1.11-5.35,0-.02-.02-.04-.04-.05-.07-.19-.18-.39-.29-.57-.02-.02-.02-.03-.04-.07-1.14-1.93-3.18-3.88-6.73-5.04-1.7-.57-2.61-2.37-2.04-4.05.54-1.67,2.37-2.58,4.04-2.03,3.91,1.29,6.7,3.35,8.63,5.66,8.96-4.81,19.9-4.81,28.84,0,1.95-2.31,4.73-4.37,8.65-5.66,1.67-.55,3.47.36,4.02,2.03Z"
                />
                <ellipse
                    className="cls-2"
                    cx={146.97}
                    cy={91.26}
                    rx={12.26}
                    ry={13.86}
                />
                <g>
                    <path
                        className="cls-1"
                        d="M129.52,116.92c-3.55,1.16-5.59,3.11-6.75,5.04-.02.04-.02.05-.04.07-.11.18-.22.38-.29.57-.02.01-.04.03-.04.05,0,0-.02,0-.02.02-1.25,2.51-1.13,4.88-1.09,5.33.15,1.75-1.15,3.32-2.89,3.46-.11.02-.2.02-.3.02-1.63,0-3.02-1.22-3.18-2.88-.17-1.67,0-4.02.84-6.49-7.01-3.76-15.59-3.76-22.6,0,.82,2.47.98,4.82.82,6.49-.16,1.66-1.55,2.9-3.19,2.9-.09,0-.2-.02-.3-.02-1.75-.18-3.05-1.73-2.88-3.48.04-.45.14-2.82-1.11-5.35,0-.02-.02-.04-.04-.05-.07-.19-.18-.39-.29-.57-.02-.02-.02-.03-.04-.07-1.14-1.93-3.18-3.88-6.73-5.04-1.7-.57-2.61-2.37-2.04-4.05.54-1.67,2.37-2.58,4.04-2.03,3.91,1.29,6.7,3.35,8.63,5.66,8.96-4.81,19.9-4.81,28.84,0,1.95-2.31,4.73-4.37,8.65-5.66,1.67-.55,3.47.36,4.02,2.03.57,1.68-.34,3.48-2.02,4.05Z"
                    />
                    <g>
                        <ellipse
                            className="cls-1"
                            cx={61.93}
                            cy={91.26}
                            rx={12.26}
                            ry={13.86}
                        />
                        <ellipse
                            className="cls-1"
                            cx={146.97}
                            cy={91.26}
                            rx={12.26}
                            ry={13.86}
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>

); export default Emoji28