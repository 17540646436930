// Desc: This is the Error page, it will be displayed when the user tries to access a page that does not exist.
// update: 12/08/2024

import React from 'react';
import './Erro.css';
import Button from '@mui/material/Button';

function Erro() {
    return (
        <div className="ErroContainer">
            <div className="ErroCircle">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <p style={{ fontWeight: 200, fontSize: '100px', textAlign: 'center', fontFamily: '"Open Sans", sans-serif', color: 'white', textShadow: '2px 2px 5px rgb(171, 160, 227)' }}>404</p>
                    <p style={{ fontWeight: 200, fontSize: '20px', textAlign: 'center', fontFamily: '"Open Sans", sans-serif' }}>Oops, Página não encontrada :(</p>
                    <div style={{ marginTop: '20px' }}>
                        <Button href='/dashboard' variant="contained" sx={{ borderRadius: '20px',backgroundColor:'rgb(171, 160, 227)'}}>
                            Voltar ao Dashboard
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Erro;
