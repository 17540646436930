import React from "react";

const Emoji11 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.89 208.9"
    width={props.width || '85'} height={props.height || '85'}
  >
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2, .cls-3 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #fff;\n      }\n\n      .cls-3 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-3"
          d="M153.89,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.89c30.38,0,55-24.63,55-55V55c0-30.38-24.62-55-55-55ZM149.14,118.86c-2.47,8.59-8.2,15.55-16.15,19.63-7.94,4.07-16.95,4.66-25.36,1.66-2.49-.89-3.8-3.64-2.9-6.13.89-2.5,3.63-3.8,6.13-2.91,5.88,2.1,12.19,1.69,17.75-1.16,5.57-2.85,9.58-7.73,11.31-13.74.73-2.55,3.39-4.02,5.94-3.28,2.54.73,4.01,3.39,3.28,5.93ZM177.5,78.56h-6.14v12.57h-6.12v6.07h-6.13v6.13h-36.4v-6.07h-6.01v-12.57h-6.13v-6.13h-12.25v6.12h-6.13v12.58h-6.01v6.07h-36.4v-6.13h-6.13v-6.07h-6.12v-12.57h-6.14v-11.7h60.8v5.56h24.51v-5.56h60.8v11.7Z"
        />
        <path
          className="cls-3"
          d="M149.14,118.86c-2.47,8.59-8.2,15.55-16.15,19.63-7.94,4.07-16.95,4.66-25.36,1.66-2.49-.89-3.8-3.64-2.9-6.13.89-2.5,3.63-3.8,6.13-2.91,5.88,2.1,12.19,1.69,17.75-1.16,5.57-2.85,9.58-7.73,11.31-13.74.73-2.55,3.39-4.02,5.94-3.28,2.54.73,4.01,3.39,3.28,5.93Z"
        />
        <polygon
          className="cls-3"
          points="177.5 66.86 177.5 78.56 171.36 78.56 171.36 91.13 165.24 91.13 165.24 97.2 159.11 97.2 159.11 103.33 122.71 103.33 122.71 97.26 116.7 97.26 116.7 84.69 110.57 84.69 110.57 78.56 98.32 78.56 98.32 84.68 92.19 84.68 92.19 97.26 86.18 97.26 86.18 103.33 49.78 103.33 49.78 97.2 43.65 97.2 43.65 91.13 37.53 91.13 37.53 78.56 31.39 78.56 31.39 66.86 92.19 66.86 92.19 72.42 116.7 72.42 116.7 66.86 177.5 66.86"
        />
        <g>
          <path
            className="cls-1"
            d="M149.14,118.86c-2.47,8.59-8.2,15.55-16.15,19.63-7.94,4.07-16.95,4.66-25.36,1.66-2.49-.89-3.8-3.64-2.9-6.13.89-2.5,3.63-3.8,6.13-2.91,5.88,2.1,12.19,1.69,17.75-1.16,5.57-2.85,9.58-7.73,11.31-13.74.73-2.55,3.39-4.02,5.94-3.28,2.54.73,4.01,3.39,3.28,5.93Z"
          />
          <g>
            <rect
              className="cls-1"
              x={49.52}
              y={91.06}
              width={0.27}
              height={0.07}
            />
            <rect
              className="cls-1"
              x={49.52}
              y={91.06}
              width={0.27}
              height={0.07}
            />
            <rect
              className="cls-1"
              x={61.78}
              y={91.06}
              width={0.06}
              height={0.07}
            />
            <rect
              className="cls-1"
              x={61.78}
              y={91.06}
              width={0.06}
              height={0.07}
            />
            <rect
              className="cls-1"
              x={61.78}
              y={91.06}
              width={0.06}
              height={0.07}
            />
            <rect
              className="cls-1"
              x={49.52}
              y={91.06}
              width={0.27}
              height={0.07}
            />
            <path
              className="cls-1"
              d="M92.19,72.42v-5.56H31.39v11.7h6.14v12.57h6.12v6.07h6.13v6.13h36.4v-6.07h6.01v-12.58h6.13v-6.12h6.12v-6.14h-12.25ZM74.03,97.26h-6.12v-6.13h-6.07v6.07h-6.13v-6.14h6.07v-6.06h-6.14v6.13h-6.12v-6.13h6.12v-6.12h-6.12v-6.14h6.12v6.14h6.14v6.12h6.13v6.13h6.12v6.13Z"
            />
            <g>
              <polygon
                className="cls-2"
                points="61.84 91.13 61.84 97.2 55.71 97.2 55.71 91.06 61.78 91.06 61.78 91.13 61.84 91.13"
              />
              <polygon
                className="cls-2"
                points="67.91 85 67.91 91.13 61.84 91.13 61.84 91.06 61.78 91.06 61.78 85 67.91 85"
              />
              <rect
                className="cls-2"
                x={55.64}
                y={78.88}
                width={6.13}
                height={6.13}
              />
              <rect
                className="cls-2"
                x={49.52}
                y={72.74}
                width={6.13}
                height={6.13}
              />
              <polygon
                className="cls-2"
                points="55.64 85 55.64 91.13 49.78 91.13 49.78 91.06 49.52 91.06 49.52 85 55.64 85"
              />
              <rect
                className="cls-2"
                x={67.91}
                y={91.13}
                width={6.13}
                height={6.07}
              />
            </g>
            <polygon
              className="cls-1"
              points="177.5 66.86 177.5 78.56 171.36 78.56 171.36 91.13 165.24 91.13 165.24 97.2 159.11 97.2 159.11 103.33 122.71 103.33 122.71 97.26 116.7 97.26 116.7 84.69 110.57 84.69 110.57 78.56 104.44 78.56 104.44 72.42 116.7 72.42 116.7 66.86 177.5 66.86"
            />
            <g>
              <polygon
                className="cls-2"
                points="135.21 91.13 135.21 97.2 129.08 97.2 129.08 91.06 135.15 91.06 135.15 91.13 135.21 91.13"
              />
              <polygon
                className="cls-2"
                points="141.28 85 141.28 91.13 135.21 91.13 135.21 91.06 135.15 91.06 135.15 85 141.28 85"
              />
              <rect
                className="cls-2"
                x={129.02}
                y={78.88}
                width={6.13}
                height={6.13}
              />
              <rect
                className="cls-2"
                x={122.89}
                y={72.74}
                width={6.13}
                height={6.13}
              />
              <polygon
                className="cls-2"
                points="129.02 85 129.02 91.13 123.15 91.13 123.15 91.06 122.89 91.06 122.89 85 129.02 85"
              />
              <rect
                className="cls-2"
                x={141.28}
                y={91.13}
                width={6.13}
                height={6.07}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>

); export default Emoji11