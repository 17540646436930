import axios from 'axios';

export async function RequestCreate({data, iv}) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/create-user-request`, 
            { [process.env.REACT_APP_RECEIVED_CRYPTO]: data, [process.env.REACT_APP_COOKIE_IV]: iv },  
        );
        
        const responseData = response.data;


        if (response.status === 201 || response.status === 200 && responseData.message === 'Request created successfully') {

            responseData.status = 'OK';

        } else {

            responseData.status = 'ERROR';
        
        }

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
    }
}