// Desc: Page for user login
// Update in 30/04/2024 ( OK )

// React Components and DOM Elements
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

// Material-UI Components
import { Box, Grid } from '@mui/material';

// Functions and Components
import { TryRecovery } from '../../api/backend/Auth/TryRecovery';
import { isAuthenticated, encrypt_first_login, encrypt } from '../../authMiddleware';
import Cookies from 'js-cookie';

// Archives and Styles
import FormInitAnimate from '../../assets/ilustrations/FormInitAnimate.gif';
import FormRecovery from './FormRecovery';
import FormConfirm from './FormConfirm';
import FormChangePassword from './FormChangePassword';
import { ChangePassword } from '../../api/backend/Auth/ChangePassword';
import Logo from '../../assets/ilustrations/Logo';
import './styles.css';
import { VerifyUrls } from '../../api/backend/Auth/VerifyUrls';
import { VerifyToken } from '../../api/backend/Auth/VerifyToken';
import Erro from '../Error/index'

const LoginRecoveryPage = () => {

    // Constants
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [codeConfirm, setCodeConfirm] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const Navigate = useNavigate();

    const { token } = useParams(); // Extrai o parâmetro `code` da URL
    const [tokenType, setTokenType] = useState('');

    // Function to handle the submit of the form
    const handleSubmit = async (event) => {
        event.preventDefault();


        if (!username) {
            setMessage('Por favor, preencha o campo de email.');
            return;
        }

        setLoading(true);

        const indexData = process.env.REACT_APP_RECEIVED_CRYPTO;
        const indexIV = process.env.REACT_APP_COOKIE_IV;

        let recoveryInfo = { 'email': username }

        recoveryInfo = JSON.stringify(recoveryInfo)

        let encryptLogin = encrypt_first_login(recoveryInfo);

        try {
            const response = await TryRecovery({ data: encryptLogin[indexData], iv: encryptLogin[indexIV] });

            if (response.status === 404) {
                setMessage('Esse email ou usuário é inválido.');
            } else {
                setMessage('');
                console.log('Resposta da API:', response);
                setTokenType('url1');
                Navigate('/login/recovery/' + response.url1);
            }

            setLoading(false);
        } catch (error) {
            console.error('Erro ao buscar dados da API:', error);
            throw error;
        }
    };
    const verifyLink1IsValid = async () => {

        if (token.length === 10) {

            const indexData = process.env.REACT_APP_RECEIVED_CRYPTO;
            const indexIV = process.env.REACT_APP_COOKIE_IV;

            let url1Info = { 'token': token, 'type': 'url1' }

            url1Info = JSON.stringify(url1Info)

            let encryptLogin = encrypt_first_login(url1Info);

            try {
                const response = await VerifyUrls({ data: encryptLogin[indexData], iv: encryptLogin[indexIV] });

                console.log('Resposta da API:', response);
                if (response.status === 200) {
                    console.log('entrou aqui')
                    setTokenType('url1');
                    setShowForm(true);
                } else {
                    setShowForm(false);
                }

                setLoading(false);

            } catch (error) {

                console.error('Erro ao buscar dados da API:', error);

                throw error;

            }

        } else if (token.length === 30) {
            
            const indexData = process.env.REACT_APP_RECEIVED_CRYPTO;
            const indexIV = process.env.REACT_APP_COOKIE_IV;

            let url2Info = { 'token': token, 'type': 'url2' }

            url2Info = JSON.stringify(url2Info)

            let encryptLogin = encrypt_first_login(url2Info);

            try {
                const response = await VerifyUrls({ data: encryptLogin[indexData], iv: encryptLogin[indexIV] });

                console.log('Resposta da API:', response);
                if (response.status === 200) {
                    console.log('entrou aqui')
                    setTokenType('url2');
                    setShowForm(true);
                } else {
                    setShowForm(false);
                    setTokenType('error')

                }

                setLoading(false);

            } catch (error) {

                console.error('Erro ao buscar dados da API:', error);

                throw error;

            }
            finally {  
                setLoading(false);
            }
        }

    

    };

    const handleSubmitConfirm = async (event) => {

        event.preventDefault();


        if (token && token.length === 10) {

            if (!codeConfirm) {
                setMessage('Por favor, preencha o campo de código.');
                return;
            }

            setLoading(true);

            const indexData = process.env.REACT_APP_RECEIVED_CRYPTO;
            const indexIV = process.env.REACT_APP_COOKIE_IV;

            let confirmInfo = { 'code': codeConfirm, 'url1': token }

            confirmInfo = JSON.stringify(confirmInfo)

            let encryptLogin = encrypt_first_login(confirmInfo);

            try {

                const response = await VerifyToken({ data: encryptLogin[indexData], iv: encryptLogin[indexIV] });

                console.log('Resposta da API:', response);
                if (response.status === 200) {
                    setLoading(false);
                    setMessage('');
                    setTokenType('url2');
                    Navigate('/login/recovery/' + response.url2);

                } else {
                    setMessage('Código inválido.');
                    setLoading(false);
                }

            }
            catch (error) {

                console.error('Erro ao buscar dados da API:', error);

                throw error;

            }
            finally {
                setLoading(false);
            }
        } else if (token && token.length === 30) {

            const indexData = process.env.REACT_APP_RECEIVED_CRYPTO;
            const indexIV = process.env.REACT_APP_COOKIE_IV;

            let confirmInfo = { 'code': codeConfirm, 'url2': token }

            confirmInfo = JSON.stringify(confirmInfo)

            let encryptLogin = encrypt_first_login(confirmInfo);

            try {

                const response = await VerifyToken({ data: encryptLogin[indexData], iv: encryptLogin[indexIV] });

                console.log('Resposta da API:', response);
                if (response.status === 200) {
                    setMessage('Código confirmado com sucesso.');
                    setLoading(false);
                } else {
                    setMessage('Código inválido.');
                    setLoading(false);
                }

            }
            catch (error) {

                console.error('Erro ao buscar dados da API:', error);

                throw error;

            }
            finally {
                setLoading(false);
            }
        }
    };

    const handleSubmitChangePassword = async (event) => {

        event.preventDefault();

        if (!newPassword || !confirmPassword) {
            setMessage('Por favor, preencha todos os campos.');
            return;

        }

        if (newPassword !== confirmPassword) {
            setMessage('As senhas não coincidem.');
            return;
        }

        setLoading(true);

        const indexData = process.env.REACT_APP_RECEIVED_CRYPTO;
        const indexIV = process.env.REACT_APP_COOKIE_IV;

        let changePasswordInfo = { 'password': newPassword, 'url2': token }

        changePasswordInfo = JSON.stringify(changePasswordInfo)

        let encryptLogin = encrypt_first_login(changePasswordInfo);

        try {

            const response = await ChangePassword({ data: encryptLogin[indexData], iv: encryptLogin[indexIV] });

            console.log('Resposta da API:', response);

            if (response.status === 200) {

                // Navigate to the login page whith param to show a message

                Navigate('/', { state: { message: 'Senha alterada com sucesso.' } });



            } else {

                setMessage('Ocorreu um erro ao alterar a senha.');

            }

        } catch (error) {

            console.error('Erro ao buscar dados da API:', error);

            throw error;

        }
        finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        
        setLoading(true);

        try {


            setPassword('')
            setUsername('')
            setNewPassword('')
            setConfirmPassword('')
            setCodeConfirm('')
            

            if (token) {

                verifyLink1IsValid()

                console.log('Token:', token);
                if (token.length === 10 && showForm) {
                    setTokenType('url1')
                } else if (token.length === 30 && showForm) {
                    setTokenType('url2')
                } else {
                    setTokenType('error01')
                }


            } else {
                setTokenType('initial')
            }

        } catch (error) {
                
                console.error('Erro ao buscar dados da API:', error);
    
                throw error;
    
            }
            finally {
                setLoading(false);
                if (tokenType === 'error01') {
                    setTokenType('error')
                }
            }

    }, [token]);




    return (

        <>

            {tokenType === 'url1' ? (
                <>
                    <div style={{
                        position: 'absolute',
                        top: '20px',
                        left: '20px',
                        padding: '10px',
                    }}>
                        <Logo />
                    </div>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#F9F9F9' }}>
                        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center', maxWidth: { xl: 1200, lg: 1100 }, height: { xl: 460.200, lg: 422.0034 }, width: '100%', margin: 'auto', padding: '0px' }}>
                            <Grid item xs={12} md={4} sx={{ display: 'flex', maxHeight: '100%' }}>
                                <Box sx={{ width: '100%', backgroundColor: 'transparent', boxShadow: 'none' }}>
                                    <img src={FormInitAnimate} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4.5} sx={{ display: 'flex', justifyContent: 'left', alignItems: { xl: 'left', lg: 'end' }, maxHeight: '100%' }}>
                                <FormConfirm loading={loading} setLoading={setLoading} message={message} setMessage={setMessage} codeConfirm={codeConfirm} setCodeConfirm={setCodeConfirm} handleSubmitConfirm={handleSubmitConfirm} />
                            </Grid>
                        </Grid>
                    </Box>
                </>
            ) : tokenType === 'initial' ? (
                <>
                    <div style={{
                        position: 'absolute',
                        top: '20px',
                        left: '20px',
                        padding: '10px',
                    }}>
                        <Logo />
                    </div>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#F9F9F9' }}>
                        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center', maxWidth: { xl: 1200, lg: 1100 }, height: { xl: 460.200, lg: 422.0034 }, width: '100%', margin: 'auto', padding: '0px' }}>
                            <Grid item xs={12} md={4} sx={{ display: 'flex', maxHeight: '100%' }}>
                                <Box sx={{ width: '100%', backgroundColor: 'transparent', boxShadow: 'none' }}>
                                    <img src={FormInitAnimate} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4.5} sx={{ display: 'flex', justifyContent: 'left', alignItems: { xl: 'left', lg: 'end' }, maxHeight: '100%' }}>
                                <FormRecovery username={username} setUsername={setUsername} password={password} setPassword={setPassword} loading={loading} setLoading={setLoading} message={message} setMessage={setMessage} codeConfirm={codeConfirm} setCodeConfirm={setCodeConfirm} handleSubmit={handleSubmit} />
                            </Grid>
                        </Grid>
                    </Box>
                </>
            ) : tokenType === 'url2' ? (
                <>
                    <div style={{
                        position: 'absolute',
                        top: '20px',
                        left: '20px',
                        padding: '10px',
                    }}>
                        <Logo />
                    </div>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#F9F9F9' }}>
                        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center', maxWidth: { xl: 1200, lg: 1100 }, height: { xl: 460.200, lg: 422.0034 }, width: '100%', margin: 'auto', padding: '0px' }}>
                            <Grid item xs={12} md={4} sx={{ display: 'flex', maxHeight: '100%' }}>
                                <Box sx={{ width: '100%', backgroundColor: 'transparent', boxShadow: 'none' }}>
                                    <img src={FormInitAnimate} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4.5} sx={{ display: 'flex', justifyContent: 'left', alignItems: { xl: 'left', lg: 'end' }, maxHeight: '100%' }}>
                                <FormChangePassword loading={loading} setLoading={setLoading} message={message} setMessage={setMessage} codeConfirm={codeConfirm} setCodeConfirm={setCodeConfirm} handleSubmitChangePassword={handleSubmitChangePassword} newPassword={newPassword} setNewPassword={setNewPassword} confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword} />
                            </Grid>
                        </Grid>
                    </Box>

                </>
            ) : tokenType === 'error' ? (

                <Erro />

            ) : null}
        </>


    )

};

export default LoginRecoveryPage;
