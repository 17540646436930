// React Components and DOM Elements
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material UI Components
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  useMediaQuery,
} from "@mui/material";

// Components and functions from the project
import { GetVariables } from "../../api/backend/Templates/GetVariables";
import ViewWhatsAppScreen from "../../components/WhatsAppScreen/ViewWhatsAppScreen";

import { isAuthenticated, encrypt, decrypted } from "../../authMiddleware";

import FormWhatsApp from "./TemplateFormWhatsApp";
import FormSMS from "./TemplateFormSMS";
import FormEmail from "./TemplateFormEmail";
import HeaderPage from "../../components/HeaderPage/HeaderPage";
import ThemeColors from "../../components/ThemeColors/ThemeColors";
import Footer from "../../components/Footer/Footer";
import EmailFormShowCase from "../../components/EmailFormShowCase/EmailFormShowCase";
import { personalizedBackgrounds } from "../../utils/templates";
import Loading from "../../components/Loading/Loading";
import { TemplateUpdate } from "../../api/backend/Templates/UpdateTemplate";
import { GetAllTemplates } from "../../api/backend/Templates/GetAllTemplates";

function EditTemplate() {
  // Authentication Context Functions
  const { authenticated, userType, uuidClient } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  const Navigate = useNavigate();

  // Template Id State
  const [templateId, setTemplateId] = useState(
    JSON.parse(localStorage.getItem("templateId")) || null
  );

  // Form States default
  const [title, setTitle] = useState("");
  const [typeMessage, setTypeMessage] = useState("Atrasos");
  const [selected, setSelected] = useState("Atrasos");
  const buttons = [
    "Atrasos",
    "Avisos",
    "Faturas",
    "Inadimplências",
    "Marketing",
    "Outros",
  ];
  const isSmallScreen = useMediaQuery('(max-width:1200px)');
  const [showPreview, setShowPreview] = useState(false);

  // Email States
  const [emailTitle, setEmailTitle] = useState("");
  const [initialGreeting, setInitialGreeting] = useState("");
  const [messageTemporaryEmail, setMessageTemporaryEmail] = useState("");
  const [referenceDesign, setReferenceDesign] = useState("");
  const [background, setBackground] = useState();
  const [isEmailTemplatePersonalized, setIsEmailTemplatePersonalized] =
    useState("");
  const [templateType, setTemplateType] = useState("");
  const [templateStatus, setTemplateStatus] = useState("");

  // WhatsApp States
  const [messageTemporaryWhatsApp, setMessageTemporaryWhatsApp] = useState("");
  const [integration_system, setIntegrationSystem] = useState("codeChat");

  // Template States
  const [variablesOfTemplate, setVariablesOfTemplate] = useState({});
  const [palletOfColors, setPalletOfColors] = useState("N/A");

  // Message States
  const [messageTemporary, setMessageTemporary] = useState("");
  const [typeChannel, setTypeChannel] = useState();

  // Loading Transition state
  const [showLoading, setShowLoading] = useState(true);

  // Component Props State
  const [viewWhatsAppScreenProps, setViewWhatsAppScreenProps] = useState({
    messages: {
      messages: [],
    },
  });

  // Effect to Check Authentication and Fetch User Info
  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, []);

  // Effect to Fetch Data from API
  useEffect(() => {
    if (userInfo["UUID"]) {
      fetchData();

      if (templateId === null) {
        Navigate("/modelos");
        return;
      }

      fetchTemplatesData();
    }
  }, [userInfo]);

  // Pega as variáveis do template
  const fetchData = async () => {
    try {
      const translations = {
        Personal_information: "Informações Pessoais",
        Contact: "Contato",
        Address: "Endereço",
        Financial_References: "Referências Financeiras",
      };

      let info = {
        uuid_client: userInfo["UUID"],
        id: userInfo["EMAIL"],
        token: userInfo["TOKEN"],
      };
      info = JSON.stringify(info);

      const cookieIv = process.env.REACT_APP_COOKIE_IV;
      const encryptedData = encrypt(info, userInfo["IV"]);
      const getVariablesOfTemplate = await GetVariables({
        data: encryptedData,
        iv: userInfo["IV"],
      });
      const decryptedData = decrypted(
        getVariablesOfTemplate[process.env.REACT_APP_RECEIVED_CRYPTO]
      );
      const resultJson = JSON.parse(decryptedData);
      const result = resultJson.reduce((acc, item) => {
        const category = translations[item[1]] || item[1]; // Traduz a categoria, ou usa o nome original caso não haja tradução
        if (!acc[category]) {
          acc[category] = [];
        }

        // limita a 10 itens

         acc[category].push(item[0])

        return acc;
      }, {});

      //console.log(result, "Template Variables");

      setVariablesOfTemplate(result);
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
  };

  // Function to get the template data
  const fetchTemplatesData = async () => {
    try {
      let info = { 'uuid_client': userInfo['UUID'], 'id': userInfo['EMAIL'], 'token': userInfo['TOKEN'] };
      info = JSON.stringify(info);

      // PEGA O IV ARMAZENADO EM COOKIE NA HORA DO LOGIN
      const cookieIv = process.env.REACT_APP_COOKIE_IV;

      // CRIPTOGRAFA OS DADOS PARA O ENVIO AO BACKEND UTILIZANDO O IV ARMAZENADO EM COOKIE
      const encryptedData = encrypt(info, userInfo['IV']);

      const templatesData = await GetAllTemplates({ data: encryptedData, iv: userInfo['IV'] });

      const decryptedData = decrypted(templatesData[process.env.REACT_APP_RECEIVED_CRYPTO]);

      const resultJson = JSON.parse(decryptedData);

      const items = resultJson.map(template => {
          return {
            id: template[0],
            title: template[1],
            uuidAccount: template[2],
            integration_icons: template[3],
            status: template[4],
            msg_type: template[5],
            channel_type: template[6],
            type: template[7] || "N/A",
            background: template[8] || "N/A",
            messageJson: template[9],
          };
      }).filter(item => item !== null);

      const findTemplate = items.find((item) => item.id === templateId);
      console.log(findTemplate, "findTemplate");

      setTypeChannel(findTemplate.channel_type);
      setSelected(findTemplate.msg_type);
      setTemplateStatus(findTemplate.status);
      setTemplateType(findTemplate.type);

      if (findTemplate.channel_type === "WhatsApp") {
        setTitle(findTemplate.title);

        setViewWhatsAppScreenProps((prevProps) => ({
          ...prevProps,
          messages: {
            messages: findTemplate.messageJson.messages,
          },
        }));
      } else if (findTemplate.channel_type === "SMS") {
        setTitle(findTemplate.title);

        const findMessage = findTemplate.messageJson.messages.find(
          (message) => message.type === "sms"
        );

        setMessageTemporary(findMessage.message);

        setViewWhatsAppScreenProps((prevProps) => ({
          ...prevProps,
          messages: {
            messages: findTemplate.messageJson.messages,
          },
        }));
      } else if (findTemplate.channel_type === "E-mail") {
        setTitle(findTemplate.title);

        const findMessage = findTemplate.messageJson.messages.find(
          (message) => message.type === "email"
        );

        setEmailTitle(findMessage.titleEmail);
        setInitialGreeting(findMessage.initialGreeting);

        setMessageTemporary(findMessage.message);

        setIsEmailTemplatePersonalized(
          findTemplate.type === "Custom" ? true : false
        );

        setBackground(findTemplate.background);
        setViewWhatsAppScreenProps((prevProps) => ({
          ...prevProps,
          messages: {
            messages: findTemplate.messageJson.messages,
          },
        }));
      }
    } catch (error) {
      console.error("Erro ao obter modelos:", error);
    } finally {
      setShowLoading(false);
    }
  };

  // Function to edit the template
  const updateTemplate = async () => {
    if (
      typeChannel === "E-mail" &&
      (emailTitle === "" ||
        initialGreeting === "" ||
        viewWhatsAppScreenProps.messages.messages.length === 0)
    ) {
      console.log(
        "typeChannel",
        typeChannel,
        "emailTitle",
        emailTitle,
        "initialGreeting",
        initialGreeting,
        "mensagens",
        viewWhatsAppScreenProps.messages.messages.length
      );

      return;
    }

    let info = {};

    if (typeChannel === "E-mail") {
      info = {
        uuid_client: userInfo["UUID"],
        id: userInfo["EMAIL"],
        token: userInfo["TOKEN"],
        title: title,
        message_json: viewWhatsAppScreenProps.messages,
        integration_system: "aws",
        status: templateStatus,
        msg_type: typeMessage,
        channel_type: typeChannel,
        background: background,
        idTemplate: templateId,
        status: templateStatus,
        type: templateType,
      };
    } else if (typeChannel === "WhatsApp") {
      info = {
        uuid_client: userInfo["UUID"],
        id: userInfo["EMAIL"],
        token: userInfo["TOKEN"],
        title: title,
        message_json: viewWhatsAppScreenProps.messages,
        integration_system: integration_system,
        status: templateStatus,
        msg_type: typeMessage,
        channel_type: typeChannel,
        idTemplate: templateId,
        type: templateType,
      };
    } else if (typeChannel === "SMS") {
      info = {
        uuid_client: userInfo["UUID"],
        id: userInfo["EMAIL"],
        token: userInfo["TOKEN"],
        title: title,
        message_json: viewWhatsAppScreenProps.messages,
        integration_system: "SMS",
        status: templateStatus,
        msg_type: typeMessage,
        channel_type: typeChannel,
        idTemplate: templateId,
        type: templateType,
      };
    }

    info = JSON.stringify(info);

    const encryptedData = encrypt(info, userInfo["IV"]);

    const updateTemplate = await TemplateUpdate({
      data: encryptedData,
      iv: userInfo["IV"],
    });
    const decryptedData = decrypted(
      updateTemplate[process.env.REACT_APP_RECEIVED_CRYPTO]
    );
    const resultJson = JSON.parse(decryptedData);
    if (resultJson["status"] === "success") {
      Navigate("/modelos");
    }
  };

  const handleClick = (value) => {
    setSelected(value);
    setTypeMessage(value);
  };

  // Show the loading screen
  if (showLoading) {
    return <Loading open={true} />;
  }

  return (
    <>
      <HeaderPage />
      <Box style={{ position: "relative", minHeight: "90vh" }}>
        <Grid container spacing={2} style={{ paddingTop: "20px" }}>
          <Grid
            item
            xs={12}
            lg={6}
            xl={6}
            sx={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "center",
                backgroundColor: "#F9F9F9",
                width: { xs: "561.200px", lg: "90%" },
                margin: { xs: "0 auto 20px", lg: "0 auto" },
                padding: { xs: 0, lg: "20px 0px 20px 30px", xl: "20px" },
              }}
            >
              <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  label="Nome do seu Modelo"
                  variant="standard"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  InputLabelProps={{
                    style: { fontSize: "20px" }, // Ajuste o tamanho da fonte conforme necessário
                  }}
                />
              </FormControl>
              <Box
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                {buttons.map((button, index) => (
                  <Button
                    key={button}
                    onClick={() => handleClick(button)}
                    sx={{
                      flexGrow: 1,
                      mx: index === 0 || index === buttons.length - 1 ? 0 : 0.5,
                      bgcolor:
                        selected === button ? "#8142FC" : ThemeColors.gray30,
                      color: "#fff",
                      borderRadius: "27px",
                      "&:hover": {
                        bgcolor: selected === button ? "#8142FC" : "gray",
                      },
                      fontSize: "12px",
                      padding: "4px 16px",
                    }}
                  >
                    {button}
                  </Button>
                ))}
              </Box>

              <Box
                position={"relative"}
                marginBottom={isSmallScreen && showPreview ? "20px" : "none"}
              >
                <Box
                  sx={{
                    color: "rgb(129, 66, 252)",
                    fontSize: "18px",
                    position: "absolute",
                    top:
                      typeChannel === "WhatsApp" || typeChannel === "SMS"
                        ? "16px"
                        : typeChannel === "E-mail" && showPreview
                          ? "16px"
                          : "0px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    display: isSmallScreen ? "block" : "none",
                    fontFamily: "Satoshi",
                    cursor: "pointer",
                    zIndex: "10",
                  }}
                  onClick={() => setShowPreview((prev) => !prev)}
                >
                  {!showPreview ? "Exibir Prévia" : "Esconder Prévia"}
                </Box>
                <Box
                  sx={{
                    display: isSmallScreen && showPreview ? "none" : "block",
                  }}
                >
                  {typeChannel === "E-mail" ? (
                    <FormEmail
                      isEmailTemplatePersonalized={isEmailTemplatePersonalized}
                      emailTitle={emailTitle}
                      setEmailTitle={setEmailTitle}
                      initialGreeting={initialGreeting}
                      setInitialGreeting={setInitialGreeting}
                      message={messageTemporary}
                      setMessageTemporary={setMessageTemporary}
                      variablesOfTemplate={variablesOfTemplate}
                      typeMessage={typeMessage}
                      setTypeMessage={setTypeMessage}
                      saveTemplate={updateTemplate}
                      setBackground={setBackground}
                      edit
                    />
                  ) : typeChannel === "WhatsApp" ? (
                    <FormWhatsApp
                      messageTemporaryWhatsApp={messageTemporaryWhatsApp}
                      setMessageTemporary={setMessageTemporaryWhatsApp}
                      palletOfColors={palletOfColors}
                      setPalletOfColors={setPalletOfColors}
                      variablesOfTemplate={variablesOfTemplate}
                      typeMessage={typeMessage}
                      setTypeMessage={setTypeMessage}
                      setViewWhatsAppScreenProps={setViewWhatsAppScreenProps}
                      viewWhatsAppScreenProps={viewWhatsAppScreenProps}
                      integration_system={integration_system}
                      setIntegrationSystem={setIntegrationSystem}
                      saveTemplate={updateTemplate}
                      edit
                    />
                  ) : typeChannel === "SMS" ? (
                    <FormSMS
                      messageTemporary={messageTemporary}
                      setMessageTemporary={setMessageTemporary}
                      palletOfColors={palletOfColors}
                      setPalletOfColors={setPalletOfColors}
                      variablesOfTemplate={variablesOfTemplate}
                      typeMessage={typeMessage}
                      setTypeMessage={setTypeMessage}
                      setViewWhatsAppScreenProps={setViewWhatsAppScreenProps}
                      viewWhatsAppScreenProps={viewWhatsAppScreenProps}
                      saveTemplate={updateTemplate}
                      edit
                    />
                  ) : null}
                </Box>
              </Box>

              <Box
                sx={{
                  display: { xs: "none", lg: "flex" },
                  justifyContent: "start",
                  alignItems: "end",
                  minHeight: "80px",
                }}
              >
                <Footer />
              </Box>
            </Box>
          </Grid>
          {isSmallScreen && showPreview === true ? (
            <Grid
              item
              xs={12}
              lg={6}
              xl={6}
              padding={{ xs: "0 0 20px 0", lg: "0", xl: "0" }}
            >
              {typeChannel === "WhatsApp" || typeChannel === "SMS" ? (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#FFFF",
                    padding: "20px",
                    borderRadius: "15px",
                    minHeight: { xs: "696px", lg: "100%", xl: "100%" },
                    width: { xs: "561.200px", lg: "100%", xl: "100%" },
                    margin: { xs: "0 auto", lg: "0", xl: "0" },
                  }}
                >
                  <ViewWhatsAppScreen
                    {...viewWhatsAppScreenProps}
                    emailTitle={emailTitle}
                    setEmailTitle={setEmailTitle}
                    initialGreeting={initialGreeting}
                    setInitialGreeting={setInitialGreeting}
                    message={messageTemporaryEmail}
                    setMessageTemporary={setMessageTemporaryEmail}
                    open={true}
                    palletOfColors={palletOfColors}
                    setPalletOfColors={setPalletOfColors}
                    setViewWhatsAppScreenProps={setViewWhatsAppScreenProps}
                    viewWhatsAppScreenProps={viewWhatsAppScreenProps}
                    type={typeChannel === "WhatsApp" ? "whatsapp" : "sms"}
                  />
                </Box>
              ) : (
                <EmailFormShowCase
                  isEmailTemplatePersonalized={isEmailTemplatePersonalized}
                  greeting={initialGreeting}
                  titleModel={title}
                  title={emailTitle}
                  message={messageTemporary}
                  background={background}
                  setBackground={setBackground}
                />
              )}
            </Grid>
          ) : !isSmallScreen ? (
            <Grid
              item
              xs={12}
              lg={6}
              xl={6}
              padding={{ xs: "0 0 20px 0", lg: "0", xl: "0" }}
            >
              {typeChannel === "WhatsApp" || typeChannel === "SMS" ? (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#FFFF",
                    padding: "20px",
                    borderRadius: "15px",
                    minHeight: { xs: "696px", lg: "100%", xl: "100%" },
                    width: { xs: "561.200px", lg: "100%", xl: "100%" },
                    margin: { xs: "0 auto", lg: "0", xl: "0" },
                  }}
                >
                  <ViewWhatsAppScreen
                    {...viewWhatsAppScreenProps}
                    emailTitle={emailTitle}
                    setEmailTitle={setEmailTitle}
                    initialGreeting={initialGreeting}
                    setInitialGreeting={setInitialGreeting}
                    message={messageTemporaryEmail}
                    setMessageTemporary={setMessageTemporaryEmail}
                    open={true}
                    palletOfColors={palletOfColors}
                    setPalletOfColors={setPalletOfColors}
                    setViewWhatsAppScreenProps={setViewWhatsAppScreenProps}
                    viewWhatsAppScreenProps={viewWhatsAppScreenProps}
                    type={typeChannel === "WhatsApp" ? "whatsapp" : "sms"}
                  />
                </Box>
              ) : (
                <EmailFormShowCase
                  isEmailTemplatePersonalized={isEmailTemplatePersonalized}
                  greeting={initialGreeting}
                  titleModel={title}
                  title={emailTitle}
                  message={messageTemporary}
                  background={background}
                  setBackground={setBackground}
                />
              )}
            </Grid>
          ) : null}
          <Box
            sx={{
              padding: "20px 0 0 0",
              display: { xs: "block", lg: "none" },
              width: { xs: "561.200px", lg: "100%", xl: "100%" },
              margin: { xs: "0 auto", lg: "0", xl: "0" },
            }}
          >
            <Footer />
          </Box>
        </Grid>
      </Box>
    </>
  );
}

export default EditTemplate;
