// Desc: Page to display the list of charges

// React imports
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Material-UI imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";

// Functions and Components to project
import DynamicTable from "../../components/Tables/DynamicTable";
import DeleteIcon from '../../assets/Icons/components/Delete';
import EmptyTemplate from "../../assets/ilustrations/EmptyTemplates.png"
import HeaderPage from '../../components/HeaderPage/HeaderPage'
import PlusIcon from '../../assets/Icons/components/Plus';
import StatusLabel from '../../components/StatusLabel/StatusLabel';
import StatusIcon from '../../components/StatusLabel/StatusIcon';
import ThemeColors from "../../components/ThemeColors/ThemeColors";
import Titles from "../../components/Titles/Titles";
import ViewStreamIcon from '@mui/icons-material/ViewStream';

import { isAuthenticated, encrypt, decrypted } from '../../authMiddleware';
import Loading from "../../components/Loading/Loading";
import { truncateText } from "../../utils/utils";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import axios from "axios";
import { PaidOutlined } from "@mui/icons-material";

function Charges() {

  // States to control authentication and user info
  const { authenticated, userType, uuidClient } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  let navigate = useNavigate();

  // States to page control
  const [loading, setLoading] = useState(true);
  const [videoUrl, setVideoUrl] = useState("./charges.mp4");
  const [openModalHelp, setOpenModalHelp] = useState(false);
  const [charges, setCharges] = useState([]);

  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, []);

  useEffect(() => {
    if (userInfo['ID']) {
      fetchData();
    }
  }, [userInfo]);

  const fetchData = async () => {

    try {

      // https://api.123zap.com.br/webhook/charges

      const response = await axios.get(`https://api.123zap.com.br/webhook/charges?uuid=${userInfo['UUID']}&id=${userInfo['ID']}&token=${userInfo['TOKEN']}`);


      const resultJson = response.data;


      const charges = resultJson.list.map((item) => {

        const date = new Date(item.creation);
        let creation;

        // expiration = 3600
        let expiration = new Date(date.getTime() + 3600 * 1000);


        if (!isNaN(date.getTime())) {
          creation = date.toLocaleString('pt-BR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          });
        } else {
          creation = 'Data Inválida';
        }

        if (!isNaN(expiration.getTime())) {
          expiration = expiration.toLocaleString('pt-BR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          });
        }

        return {
          id: item.id,
          Nome: item.data.Name ? item.data.Name : item.data.FullName ? item.data.FullName : 'Sem Nome',
          'Data de Vencimento': item.data.DueDate,
          status: item.status_process === 'Finish'
            ? 'Processando'
            : item.status === 'ATIVA'
              ? 'Aguardando pagamento'
              : item.status === 'CONCLUIDA'
                ? 'Pagamento recebido'
                : item.status_process === 'WAIT' ? 'Processando' 
                : 'Desconhecido',
          'desc': item.desc

        };
      });

      console.log(charges, 'charges');


      setCharges(charges);
      setLoading(false);


    } catch (error) {
      console.error("Erro ao obter dados da API:", error);
      setLoading(false);
    }
  }

  const handleDelete = async (item) => {
    try {

      let info = { 'uuid': userInfo['UUID'], 'id': userInfo['ID'], 'token': userInfo['TOKEN'], 'charge_id': item.id };


    } catch (error) {
      console.error("Erro ao obter dados da API:", error);
    }
  }

  const handlePage = () => {
    navigate("/cobranças/gerar");
  };

  if (loading) {
    return <Loading open={true} />
  }

  return (
    <Fragment>
      <Fade in={!loading} timeout={1000}>
        <Box width={"100%"} height={"100vh"}>
          <HeaderPage title="Charges" />
          {
            charges && charges.length > 0 && (
              <DynamicTable
                title="Charges"
                data={charges}
                columns={[
                  { header: '', field: 'icon', render: (icon) => <PaidOutlined /> },
                  { header: 'Nome', field: 'Nome' },
                  { header: 'Descrição', field: 'desc' },
                  { header: 'Data de Vencimento', field: 'Data de Vencimento' },
                  { header: 'Status', field: 'status', render: (status) => <StatusLabel status={status} usingOnTable /> },
                ]}
                actions={[
                  { icon: <PlayCircleOutlineIcon />, tooltip: 'Ativar', action: (item) => console.log(item), isVisible: (item) => true },


                ]}
                button={{ icon: <PlusIcon />, tooltip: 'Gerar Cobrança ', action: h => handlePage() }}
                activeSearch={true}
                titleSearch={'Pesquisar Charge'}
                titleLabel={'Charges por página'}
              />
            )
            || (
              <Box display={"flex"} sx={{ justifyContent: "center", alignItems: "center", width: "100%", height: "90%" }}>
                <Box>
                  <img src={EmptyTemplate} alt="Você não tem modelos" width={"494px"} height={"330px"} />
                  <Box display={"flex"} sx={{ flexDirection: "column", gap: "10px", justifyContent: "center", textAlign: "center" }}>
                    <Titles bold type={"H3"} children={"Parece que você não tem nenhuma cobrança:("} color={"#8033F4"} />
                    <Titles type={"P1"} children={"Gera um agora mesmo, é começe a receber!"} />

                    <Button
                      variant='contained'
                      style={{ color: '#fff', width: '100%', padding: '10px', marginTop: "10px" }}
                      onClick={() => handlePage()}
                    >
                      Gerar cobrança
                    </Button>
                  </Box>
                </Box>
              </Box>
            )
          }
        </Box>
      </Fade>
  
    </Fragment>
  );
}

export default Charges;
