// Desc: Page to list and manage channels
// Update in 05/08/2024

// React Components and DOM Elements
import React, { useEffect, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

// Material-UI Components
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

// Custom Components and Functions
import { ChannelStatus } from '../../api/backend/Channels/ChannelStatus';
import { ChannelDisconnect } from '../../api/backend/Channels/ChannelDisconnect';
import { ChannelConnect } from '../../api/backend/Channels/ChannelConnect';
import { isAuthenticated, encrypt, decrypted } from '../../authMiddleware';
import { ChannelRemoved } from '../../api/backend/Channels/ChannelRemoved';

import DynamicTable from '../../components/Tables/DynamicTable';
import Loading from '../../components/Loading/Loading';
import StatusLabel from '../../components/StatusLabel/StatusLabel';

import PlusIcon from '../../assets/Icons/components/Plus';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import StatusIcon from '../../components/StatusLabel/StatusIcon';
import ModalR from '../../components/Modals/ModalR';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import EmptyTemplate from "../../assets/ilustrations/EmptyTemplates.png"
import Titles from '../../components/Titles/Titles';
import Button from '@mui/material/Button';

// Render the Channel page
function ChannelPage() {

    // Authentication Context Functions
    const { authenticated } = isAuthenticated();
    const [userInfo, setUserInfo] = useState({});
    let Navigate = useNavigate();

    const [openModalR, setOpenModalR] = useState(false);
    const [optionSelected, setOptionSelected] = useState('');

    const [loading, setLoading] = useState(true);

    const [selectedConexao, setSelectedConexao] = useState(null);
    const [showLoading, setShowLoading] = useState(true);
    const [itemsToShow, setItemsToShow] = useState(null);

    const [dataCards, setDataCards] = useState([
        { title: 'WhatsApps Conectados', dataKey: 'conectados', qtd: 0, icon: null },
        { title: 'WhatsApps Desconectados', dataKey: 'desconectados', qtd: 0, icon: null }
    ]);

    // Get the user information
    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, []);

    // Fetch the data from the API
    useEffect(() => {
        if (userInfo['UUID']) {
            fetchData();
        }
    }, [userInfo])

    // Set a timer to hide the loading screen
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowLoading(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    // Open the modal to view the selected channel
    const handleOpenModalView = (item) => {
        setSelectedConexao(item);
        Navigate('/canais/configurações', { state: { selectedItem: item } });
    };

    // Format the phone number
    const formatPhoneNumber = (phoneNumber) => {

        const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
        if (numericPhoneNumber.length < 11) {
            return phoneNumber;
        }

        const countryCode = numericPhoneNumber.substring(0, 2);
        const areaCode = numericPhoneNumber.substring(2, 4);
        const firstPart = numericPhoneNumber.substring(4, 8);
        const secondPart = numericPhoneNumber.substring(8);
        return `+${countryCode} (${areaCode}) 9 ${firstPart}-${secondPart}`;
    };

    // Format a fixed phone number
    const formatFixedPhoneNumber = (phoneNumber) => {

        const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
        if (numericPhoneNumber.length < 10) {
            return phoneNumber;
        }

        const countryCode = numericPhoneNumber.substring(0, 2);
        const areaCode = numericPhoneNumber.substring(2, 4);
        const firstPart = numericPhoneNumber.substring(4, 8);
        const secondPart = numericPhoneNumber.substring(8);
        return `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;

    };

    // Verify the phone number and format it
    const verifyPhoneNumber = (phoneNumber) => {

        const phoneNumberArray = phoneNumber.split('');
        if (phoneNumberArray[4] === '3') {
            return formatFixedPhoneNumber(phoneNumber);
        } else {
            return formatPhoneNumber(phoneNumber);
        }

    }

    // Fetch the data from the API
    const fetchData = async () => {

        try {

            setLoading(true);

            let info = { 'uuid_client': userInfo['UUID'], 'id': userInfo['EMAIL'], 'token': userInfo['TOKEN'] };
            info = JSON.stringify(info);

            const encryptedData = encrypt(info, userInfo['IV']);
            const instanceStatusData = await ChannelStatus({ data: encryptedData, iv: userInfo['IV'] });
            const decryptedData = decrypted(instanceStatusData[process.env.REACT_APP_RECEIVED_CRYPTO]);
            const resultJson = JSON.parse(decryptedData);
            const conectados = resultJson.status.filter((item) => item.last_status === 'open');
            const desconectados = resultJson.status.filter((item) => item.last_status !== 'open');
            const sortedInstances = [...conectados, ...desconectados];
            const newDataCards = [...dataCards];

            newDataCards[0].qtd = conectados.length;
            newDataCards[1].qtd = desconectados.length;

            setDataCards(newDataCards);

            const instancesToShow = sortedInstances.map((instance) => ({
                id: instance.id,
                instance: `WhatsApp ${instance.id}`,
                instance_name: instance.instance,
                number: verifyPhoneNumber(instance.owner),
                name: (instance.instance.split('@')[1] || instance.profileName), 
                status: instance.status === 'open' ? 'Conectado' : instance.status === 'disconnected' ? 'Desconectado' : 'Indisponível',
                type_msg: instance.type,
                integration_icons: instance.integration_system,
                request_process: instance.request_process,
                profileName: instance.profileName,
            }));

            console.log(instancesToShow, 'instancesToShow');
            setItemsToShow(instancesToShow);


        } catch (error) {
           
            console.error('Erro ao carregar dados:', error);
          
        } finally {
            setLoading(false);
        }
    };

    const handleConnect = async () => {

        try {

            let number = unformatPhoneNumber(selectedConexao.number);

            let info = { 'uuid_account': userInfo['UUID'], 'userId': userInfo['EMAIL'], 'owner': number, 'tokenUser': userInfo['TOKEN'], 'instance_name': selectedConexao.instance_name, profileName: selectedConexao.profileName };
            info = JSON.stringify(info);

            const encryptedData = encrypt(info, userInfo['IV']);
            const instanceStatusData = await ChannelConnect({ data: encryptedData, iv: userInfo['IV'] });
            const decryptedData = decrypted(instanceStatusData[process.env.REACT_APP_RECEIVED_CRYPTO]);
            const resultJson = JSON.parse(decryptedData);

            if (resultJson.status === 'success') {
                setTimeout(() => {
                    fetchData();
                }, 1000);
            }

        } catch (error) {

            console.error('Erro ao conectar instância:', error);

        } finally {
            setOpenModalR(false);
        }

    };


    const handleDelete = async () => {

        try {

            let number = unformatPhoneNumber(selectedConexao.number);

            let info = { 'uuid_account': userInfo['UUID'], 'userId': userInfo['EMAIL'], 'tokenUser': userInfo['TOKEN'], 'instance_name': selectedConexao.instance_name, 'owner': number , profileName: selectedConexao.profileName};

            info = JSON.stringify(info);

            const encryptedData = encrypt(info, userInfo['IV']);
            const instanceStatusData = await ChannelRemoved({ data: encryptedData, iv: userInfo['IV'] });
            const decryptedData = decrypted(instanceStatusData[process.env.REACT_APP_RECEIVED_CRYPTO]);
            const resultJson = JSON.parse(decryptedData);

            if (resultJson.status === 'success') {

                setTimeout(() => {
                    fetchData();
                }, 1000);

            }

        } catch (error) {

            console.error('Erro ao excluir instância:', error);

        } finally {

            setOpenModalR(false);

        }

    };

    // Open the modal to add a new channel
    const handleOpenModalAdd = () => {
        Navigate('/canais/adicionar');
    };

    // Open the modal to reconnect the channel
    const handleOpenReconnect = () => {
        Navigate('/canais/adicionar/whatsapp');
    };

    // Show the loading screen
    if (showLoading) {
        return <Loading open={true} />;
    }

    const unformatPhoneNumber = (formattedPhoneNumber) => {
        // Remove all non-numeric characters and leading zeros
        const numericPhoneNumber = formattedPhoneNumber.replace(/^0+|\D/g, '');

        // Check if the phone number was formatted using the formatPhoneNumber function
        if (numericPhoneNumber.startsWith('55') && numericPhoneNumber.length === 13) {
            // Remove the '9' at the 8th position from the end
            return numericPhoneNumber.slice(0, -9) + numericPhoneNumber.slice(-8);
        }

        // Check if the phone number was formatted using the formatFixedPhoneNumber function
        if (numericPhoneNumber.startsWith('31') && numericPhoneNumber.length === 11) {
            // Remove the '9' at the 8th position from the end
            return numericPhoneNumber.slice(0, -9) + numericPhoneNumber.slice(-8);
        }

        // The format does not match either function, so return the original formatted number
        return formattedPhoneNumber;
    };

    const handleDisconnect = async () => {

        try {

            let number = unformatPhoneNumber(selectedConexao.number);

            let info = { 'uuid_account': userInfo['UUID'], 'userId': userInfo['EMAIL'], 'tokenUser': userInfo['TOKEN'], 'instance_name': selectedConexao.instance_name, 'owner': number , profileName: selectedConexao.profileName};
            info = JSON.stringify(info);

            const encryptedData = encrypt(info, userInfo['IV']);
            const instanceStatusData = await ChannelDisconnect({ data: encryptedData, iv: userInfo['IV'] });
            const decryptedData = decrypted(instanceStatusData[process.env.REACT_APP_RECEIVED_CRYPTO]);
            const resultJson = JSON.parse(decryptedData);

            if (resultJson.status === 'success') {

                setTimeout(() => {
                    fetchData();
                }, 1000);

            }

        } catch (error) {

            console.error('Erro ao desconectar instância:', error);

        } finally {
            setOpenModalR(false);
        }

    };

    const handleCloseModalConfirm = () => {
        setOpenModalR(false);
    };

    const OpenModalConfirm = (option) => {
        setOptionSelected(option);
        setOpenModalR(true);
    };

    // Render the Channel page
    return (

        <Fragment>
            <Fade in={!loading} timeout={1000}>
                <Box width={"100%"} height={"100vh"}>

                    <HeaderPage title="Canais" />
                    {
                        itemsToShow && itemsToShow.length > 0 && (
                            <DynamicTable
                                title="Listagem de Templates"
                                data={itemsToShow}
                                columns={[
                                    { header: '', field: 'integration_icons', render: (integration) => <StatusIcon status={integration} /> },
                                    { header: 'Nome', field: 'name' },
                                    { header: 'Saida', field: 'number' },
                                    { header: 'Tipo de Mensagem', field: 'type_msg', render: (type_msg) => <StatusLabel status={type_msg} usingOnTable />, filter: true},
                                    { header: 'Status', field: 'status', render: (status) => <StatusLabel status={status} usingOnTable />, filter: true },

                                ]}
                                
                                actions={[
                                    {
                                        icon: <StatusIcon status={'Edit'} color={ThemeColors.gray40} width={20} height={20} />,
                                        tooltip: 'Editar',
                                        action: (item) => handleOpenModalView(item),
                                        isVisible: (item) => item && item.integration_icons === 'whatsAppOfficial' || item && item.integration_icons === 'codeChat'
                                    },
                                    {
                                        icon: <StatusIcon status={'Power'} color={ThemeColors.green02} width={20} height={20} />,
                                        tooltip: 'Conectar',
                                        action: (item) => {
                                            OpenModalConfirm('connect');
                                            setSelectedConexao(item);
                                        },
                                        isVisible: (item) => item && item.status === 'Desconectado' && item && item.integration_icons === 'whatsAppOfficial' || item && item.integration_icons === 'codeChat' && item.status !== 'Conectado' && item.status !== 'Indisponível',
                                    },
                                    {
                                        icon: <StatusIcon status={'PowerOff'} color={ThemeColors.red02} width={20} height={20} />,
                                        tooltip: 'Desconectar',
                                        action: (item) => {
                                            OpenModalConfirm('disconnect');
                                            setSelectedConexao(item);
                                        },
                                        isVisible: (item) => item && item.status === 'Conectado' && item && item.integration_icons === 'whatsAppOfficial' || item && item.integration_icons === 'codeChat' && item.status !== 'Desconectado' && item.status !== 'Indisponível',
                                    },
                                    {
                                        icon: <StatusIcon status={'Electrical'} color={ThemeColors.green02} width={20} height={20} />,
                                        tooltip: 'Reconectar',
                                        action: (item) => {
                                            OpenModalConfirm('reconnect');
                                            setSelectedConexao(item);
                                        },
                                        isVisible: (item) => item && item.status === 'Indisponível' && (item && item.integration_icons === 'whatsAppOfficial' || item && item.integration_icons === 'codeChat')
                                    },
                                    {
                                        icon: <StatusIcon status={'Delete'} color={ThemeColors.red02} width={20} height={20} />,
                                        tooltip: 'Excluir',
                                        action: (item) => { 
                                            OpenModalConfirm('delete')
                                            setSelectedConexao(item)
                                        },
                                        isVisible: (item) => item && item.status === 'Desconectado' && item && item.integration_icons === 'whatsAppOfficial' || item && item.integration_icons === 'codeChat' && item.status !== 'Conectado'
                                    }
                                ]}


                                button={{ icon: <PlusIcon />, tooltip: 'Adicionar Canal ', action: handleOpenModalAdd }
                                }

                                activeSearch={true}
                                titleSearch={'Pesquisar Canal'}
                                titleLabel={'Canais por página'}
                            />

                        ) || (

                            <Box display={"flex"} sx={{ justifyContent: "center", alignItems: "center", width: "100%", height: "90%" }}>
                                <Box>
                                    <img src={EmptyTemplate} alt="Você não tem modelos" width={"494px"} height={"330px"} />
                                    <Box display={"flex"} sx={{ flexDirection: "column", gap: "10px", justifyContent: "center", textAlign: "center" }}>
                                        <Titles bold type={"H3"} children={"Parece que você não tem canais :("} color={"#8033F4"} />
                                        <Titles type={"P1"} children={"Adicione um agora mesmo, é fácil e rápido"} />

                                        <Button
                                            variant='contained'
                                            style={{ color: '#fff', width: '100%', padding: '10px', marginTop: "10px" }}
                                            onClick={handleOpenModalAdd}
                                        >
                                            Adicionar Canal
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    }

                </Box>
            </Fade>

            <ModalR
                cancelText={"Mudei de ideia"}
                color={ThemeColors.purple500}
                confirmText={optionSelected === 'reconnect' ? "Sim, desejo reconectar" : optionSelected === 'connect' ? "Sim, desejo conectar" : optionSelected === 'disconnect' ? "Sim, desejo desconectar" : "Sim, desejo excluir"}
                subTitle={optionSelected === 'reconnect' ? "Você será redirecionado para a página de reconexão" : optionSelected === 'connect' ? "Seu canal voltará a ficar disponível para uso." : optionSelected === 'disconnect' ? "Seu canal será desconectado e não estará disponível para uso." : "Seu canal será excluído e não estará disponível para uso. Lembre-se de remover a conexão também no seu celular"}
                onClose={handleCloseModalConfirm}
                isOpen={openModalR}
                onConfirm={optionSelected === 'reconnect' ? handleOpenReconnect : optionSelected === 'connect' ? handleConnect : optionSelected === 'disconnect' ? handleDisconnect : optionSelected === 'delete' ? handleDelete : console.log('')}
                title={optionSelected === 'reconnect' ? "Deseja realmente reconectar?" : optionSelected === 'connect' ? "Deseja realmente conectar?" : optionSelected === 'disconnect' ? "Deseja realmente desconectar?" : optionSelected === 'delete' ? "Deseja realmente excluir?" : "Deseja realmente excluir?"}
                emoji={optionSelected === 'disconnect' ? 'emoji10' : optionSelected === 'connect' ? 'emoji02' : optionSelected === 'reconnect' ? 'emoji02' : 'emoji10'}
                iconColor={ThemeColors.purple400}
            />

        </Fragment>

    );
}

export default ChannelPage;