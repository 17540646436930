import React from "react";

const Emoji23 = (props) => (
    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.9 208.9"
    width={props.width || '85'} height={props.height || '85'}
>
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7428ea;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.9,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.9c30.38,0,55-24.63,55-55V55c0-30.38-24.62-55-55-55ZM46.15,90.2c0-6.83,3.79-13.24,9.9-16.72,1.63-.94,3.67-.83,5.2.28l22.53,16.4c1.63,1.18,2.34,3.26,1.79,5.19-2.38,8.41-10.34,14.28-19.34,14.28-11.07,0-20.08-8.72-20.08-19.43ZM129.95,123.7c-1.96,6.75-7.18,11.43-13.62,12.24-.65.08-1.3.12-1.94.12-3.62,0-7.11-1.25-9.94-3.48-3.34,2.63-7.59,3.88-11.88,3.36-6.44-.81-11.66-5.5-13.62-12.24-.73-2.54.73-5.2,3.28-5.94,2.54-.75,5.2.72,5.94,3.27.9,3.09,2.93,5.05,5.59,5.38,2.04.26,4.84-.51,6.55-3.43.86-1.47,2.44-2.38,4.14-2.38.85,0,1.67.23,2.39.64.71.41,1.32,1.01,1.75,1.74,1.71,2.92,4.51,3.67,6.55,3.43,2.66-.33,4.69-2.29,5.59-5.38.74-2.55,3.4-4.01,5.94-3.27,2.55.74,4.01,3.4,3.28,5.94ZM142.67,109.63c-9.01,0-16.96-5.87-19.34-14.28-.55-1.94.17-4.01,1.79-5.19l22.53-16.4c1.53-1.11,3.57-1.22,5.21-.28,6.1,3.48,9.89,9.89,9.89,16.72,0,10.71-9.01,19.43-20.08,19.43Z"
        />
        <path
          className="cls-2"
          d="M66.23,109.63c-11.07,0-20.08-8.72-20.08-19.43,0-6.83,3.79-13.24,9.9-16.72,1.63-.94,3.67-.83,5.2.28l22.53,16.4c1.63,1.18,2.34,3.26,1.79,5.19-2.38,8.41-10.34,14.28-19.34,14.28Z"
        />
        <path
          className="cls-2"
          d="M129.95,123.7c-1.96,6.75-7.18,11.43-13.62,12.24-.65.08-1.3.12-1.94.12-3.62,0-7.11-1.25-9.94-3.48-3.34,2.63-7.59,3.88-11.88,3.36-6.44-.81-11.66-5.5-13.62-12.24-.73-2.54.73-5.2,3.28-5.94,2.54-.75,5.2.72,5.94,3.27.9,3.09,2.93,5.05,5.59,5.38,2.04.26,4.84-.51,6.55-3.43.86-1.47,2.44-2.38,4.14-2.38.85,0,1.67.23,2.39.64.71.41,1.32,1.01,1.75,1.74,1.71,2.92,4.51,3.67,6.55,3.43,2.66-.33,4.69-2.29,5.59-5.38.74-2.55,3.4-4.01,5.94-3.27,2.55.74,4.01,3.4,3.28,5.94Z"
        />
        <path
          className="cls-2"
          d="M162.75,90.2c0,10.71-9.01,19.43-20.08,19.43-9.01,0-16.96-5.87-19.34-14.28-.55-1.94.17-4.01,1.79-5.19l22.53-16.4c1.53-1.11,3.57-1.22,5.21-.28,6.1,3.48,9.89,9.89,9.89,16.72Z"
        />
        <g>
          <path
            className="cls-1"
            d="M129.95,123.7c-1.96,6.75-7.18,11.43-13.62,12.24-.65.08-1.3.12-1.94.12-3.62,0-7.11-1.25-9.94-3.48-3.34,2.63-7.59,3.88-11.88,3.36-6.44-.81-11.66-5.5-13.62-12.24-.73-2.54.73-5.2,3.28-5.94,2.54-.75,5.2.72,5.94,3.27.9,3.09,2.93,5.05,5.59,5.38,2.04.26,4.84-.51,6.55-3.43.86-1.47,2.44-2.38,4.14-2.38.85,0,1.67.23,2.39.64.71.41,1.32,1.01,1.75,1.74,1.71,2.92,4.51,3.67,6.55,3.43,2.66-.33,4.69-2.29,5.59-5.38.74-2.55,3.4-4.01,5.94-3.27,2.55.74,4.01,3.4,3.28,5.94Z"
          />
          <g>
            <path
              className="cls-1"
              d="M83.78,90.16l-22.53-16.4c-1.53-1.11-3.57-1.22-5.2-.28-6.11,3.48-9.9,9.89-9.9,16.72,0,10.71,9.01,19.43,20.08,19.43,9,0,16.96-5.87,19.34-14.28.55-1.93-.16-4.01-1.79-5.19ZM66.23,100.03c-5.78,0-10.48-4.41-10.48-9.83,0-2.48.98-4.81,2.72-6.59l16.49,12c-1.89,2.7-5.15,4.42-8.73,4.42Z"
            />
            <path
              className="cls-1"
              d="M152.86,73.48c-1.64-.94-3.68-.83-5.21.28l-22.53,16.4c-1.62,1.18-2.34,3.25-1.79,5.19,2.38,8.41,10.33,14.28,19.34,14.28,11.07,0,20.08-8.72,20.08-19.43,0-6.83-3.79-13.24-9.89-16.72ZM142.67,100.03c-3.58,0-6.84-1.72-8.73-4.42l16.49-12c1.74,1.78,2.72,4.1,2.72,6.59,0,5.42-4.7,9.83-10.48,9.83Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
   

); export default Emoji23