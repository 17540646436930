// Desc: This file contains the color palette for the theme.
// Update in 12/08/2024

const themeColors = {

    // Gray Colors
    gray10: "#F9F9F9",
    gray20: "#E5E5E5",
    gray30: "#B7B7B7",
    gray40: "#848484",
    gray50: "#52525B",
    gray60: "#303035",
  
    // Purple Colors
    purple50: "#F5F3FF",
    purple100: "#EDE8FF",
    purple200: "#DDD4FF",
    purple300: "#C5B2FF",
    purple400: "#A986FF",
    purple500: "#8142FC",
    purple600: "#8033F4",
    purple700: "#7121E0",
    purple800: "#5F1BBC",
    purple900: "#4F189A",
    purple950: "#300D68",

    // Alert Colors
    green01: "#D3EAC8",
    green02: "#4C8435",
    green03: "#345427",
    red01: "#F8D6D3",
    red02: "#CD4747",
    red03: "#4E1B1D",
    yellow01: "#F6EDCB",
    yellow02: "#DEA73F",
    yellow03: "#8D4D1B",

    black: "#000000",
    white: "#FFFFFF",

  };

  
  
export default themeColors;
  