// Description: This component render a showcase of the site's illustrations
// Update: 12/08/2024

// React Components and DOM Elements
import React from "react";

// Material UI Components
import { Grid } from '@mui/material';

// Components and functions from the project
import FormInitAnimate from '../../assets/ilustrations/FormInitAnimate.gif'
import EmptyTemplate from '../../assets/ilustrations/EmptyTemplates.png'
import SendEmail from '../../assets/ilustrations/SendEmail.png'
import SendWhatsApp from '../../assets/ilustrations/SendWhatsApp.png'
import SendSMS from '../../assets/ilustrations/SendSMS.png'

// Render Your Component
const Ilustrations = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", padding: "30px" }}>
            <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                <Grid item xs={12} sm={6} md={3} sx={{ '& > *': { marginBottom: '10px' } }}>
                    <img src={FormInitAnimate} alt="FormInitAnimate" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} sx={{ '& > *': { marginBottom: '10px' } }}>
                    <img src={EmptyTemplate} alt="EmptyTemplate" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} sx={{ '& > *': { marginBottom: '10px' } }}>
                    <img src={SendEmail} alt="SendEmail" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} sx={{ '& > *': { marginBottom: '10px' } }}>
                    <img src={SendWhatsApp} alt="SendWhatsApp" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                </Grid>
                <Grid item xs={12} sm={6} md={3} sx={{ '& > *': { marginBottom: '10px' } }}>
                    <img src={SendSMS} alt="SendSMS" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                </Grid>
            </Grid>
        </div>
    )
}

export default Ilustrations;