// Description: Modal that's activated when the user click one of the channel cards
// Update: 12/08/2024

// React Components and DOM Elements
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../../authMiddleware';
import themeColors from '../ThemeColors/ThemeColors';

// Material UI Components
import { Box, InputLabel, MenuItem, Select, styled, Button, Dialog, FormControl, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Components and functions from the project
import Titles from '../Titles/Titles';
import StatusIcon from '../StatusLabel/StatusIcon';
import { set } from 'date-fns';

// Customizating the Dialog Component
const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .css-1hju3x6-MuiPaper-root-MuiDialog-paper': {
        borderRadius: '12px',
        width: '461px',
        height: '427px',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    '& .css-hz1bth-MuiDialog-container': {
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: '0',
        left: '0',
        display: 'block',
    }
}))

// Customizating the Select Component
const CustomSelect = styled(Select)(({ theme }) => ({
    '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
        padding: '12px 10px'
    },
    '.css-1ng7iqm-MuiFormLabel-root-MuiInputLabel-root': {
        top: '-10px'
    }
}))

// Render Your Component
const CardModal = ({ type, onClose, isOpen, input, setInput, onConfirm }) => {

    // Authentication Context Functions
    const { authenticated } = isAuthenticated();
    const navigate = useNavigate();

    // State variables
    const [userInfo, setUserInfo] = useState({});
    const [error, setError] = useState(false);

    // Function to get the title based on the type that comes as props
    const getTitle = () => {
        if (type === 'WhatsApp') {
            return 'Solicite o Whatsapp'
        } else if (type === 'WhatsApp Business') {
            return 'Solicite o Whatsapp Business'
        } else if (type === 'Email') {
            return 'Solicite seu email personalizado'
        } else if (type === 'SMS') {
            return 'Solicite o envio de SMS.'
        }
    }

    // Function to get the text based on the type that comes as props
    const getText = () => {
        if (type === 'WhatsApp') {
            return 'Solicite o Whatsapp'
        } else if (type === 'WhatsApp Business') {
            return 'Qual Whatsapp você deseja transformar?'
        } else if (type === 'Email') {
            return 'Crie seu email'
        } else if (type === 'SMS') {
            return "Digite 'solicitar' para confirmar"
        }
    }

    // Function to get the action based on the type that comes as props
    const handleAction = () => {
        if (!input) {
            console.log('Error: Input is empty', input);   
            setError(true);
        } else {
            onConfirm();
        }
    }

    // Effect to Check Authentication and Fetch User Info
    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        } else {
            navigate('/login');
        }
    }, []);

    // Function to handle the input change
    const handleInputChange = (e) => {
        setInput(e.target.value)
    }

    // Return Your Component JSX
    return (
        <CustomDialog
            open={isOpen}
            onClose={() => {
                setError(false);
                onClose();
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            maxWidth='sx'
            onBackdropClick={() => {
                setError(false);
                onClose();
            }}
        >
            <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} padding={'50px'} sx={{ textAlign: 'center', backgroundColor: '#fff', height: '100%' }}>

                <Box display={'flex'} sx={{ flexDirection: 'column', gap: '10px' }}>
                    <Titles font={'medium'} children={getTitle()} color={'#8033F4'} type={'H3'} />
                    <div
                        style={{
                            fontFamily: 'Satoshi',
                            fontSize: '16px',
                        }}
                    >
                        Esse é um <strong style={{ fontWeight: 'bold' }}>serviço pago</strong>! Os valores podem ser confirmados na nossa <b style={{ color: '#8033F4' }}>documentação</b>.
                    </div>
                </Box>


                <Box textAlign={'start'} display='flex' flexDirection='column'>
                    <Titles type={'P2'} children={getText()} color={'#52525B'} />
                    <Box marginTop={'10px'}>
                        {
                            type === 'SMS' && (
                                <TextField
                                    id='outlined-multiline-static'
                                    placeholder='Digite solicitar para confirmar'
                                    variant='outlined'
                                    value={input}
                                    onChange={e => setInput(e.target.value)}
                                    fullWidth
                                    sx={{
                                        background: error ? themeColors.red01 : '#f6f6f6',
                                        color: '#848484',
                                        borderRadius: '6px',
                                        border: 'none',
                                        outline: 'none',
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            error ?
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <mask id="mask0_34_1183" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                                        <rect width="20" height="20" fill="#D9D9D9" />
                                                    </mask>
                                                    <g mask="url(#mask0_34_1183)">
                                                        <path d="M8 10.9743C8.11622 10.9743 8.21367 10.935 8.29233 10.8563C8.371 10.7778 8.41033 10.6804 8.41033 10.5642C8.41033 10.4478 8.371 10.3504 8.29233 10.2718C8.21367 10.1932 8.11622 10.1538 8 10.1538C7.88378 10.1538 7.78633 10.1932 7.70767 10.2718C7.629 10.3504 7.58967 10.4478 7.58967 10.5642C7.58967 10.6804 7.629 10.7778 7.70767 10.8563C7.78633 10.935 7.88378 10.9743 8 10.9743ZM7.66667 8.76917H8.33333V4.76917H7.66667V8.76917ZM8.00217 14C7.1725 14 6.39244 13.8426 5.662 13.5277C4.93167 13.2128 4.29633 12.7854 3.756 12.2457C3.21567 11.7059 2.78794 11.0711 2.47283 10.3413C2.15761 9.61167 2 8.83194 2 8.00217C2 7.1725 2.15744 6.39244 2.47233 5.662C2.78722 4.93167 3.21456 4.29633 3.75433 3.756C4.29411 3.21567 4.92889 2.78794 5.65867 2.47283C6.38833 2.15761 7.16806 2 7.99783 2C8.8275 2 9.60756 2.15744 10.338 2.47233C11.0683 2.78722 11.7037 3.21456 12.244 3.75433C12.7843 4.29411 13.2121 4.92889 13.5272 5.65867C13.8424 6.38833 14 7.16806 14 7.99783C14 8.8275 13.8426 9.60756 13.5277 10.338C13.2128 11.0683 12.7854 11.7037 12.2457 12.244C11.7059 12.7843 11.0711 13.2121 10.3413 13.5272C9.61167 13.8424 8.83194 14 8.00217 14Z" fill="#CD4747" />
                                                    </g>
                                                </svg>
                                                : null
                                        )
                                    }}
                                />
                            ) || type === 'WhatsApp' && (
                                <FormControl fullWidth>
                                    <InputLabel id='demo-simple-select-filled-label'>Selecione o Whatsapp</InputLabel>
                                    <CustomSelect
                                        sx={{
                                            background: error ? themeColors.red01 : '#f6f6f6',
                                            color: '#848484',
                                            border: 'none',
                                            minWidth: '100%',
                                            borderRadius: '6px',
                                            padding: '0',
                                            outline: 'none',
                                        }}
                                        IconComponent={KeyboardArrowDownIcon}
                                    >
                                        <MenuItem value='Atendimento'>Whatsapp 1</MenuItem>
                                        <MenuItem value='Financeiro'>Whatsapp 2</MenuItem>
                                        <MenuItem value='Técnico'>Whatsapp 3</MenuItem>
                                    </CustomSelect>
                                </FormControl>
                            ) || type === 'Email' && (
                                <Box display={'flex'} gap='10px'>
                                    <TextField
                                        placeholder='Novo Email'
                                        style={{
                                            background: error ? themeColors.red01 : '#f6f6f6',
                                            color: '#848484',
                                            outline: 'none',
                                            width: '100%',
                                            borderRadius: '6px',
                                            border: 'none'
                                        }}
                                        onChange={e => setInput(e.target.value)}
                                        value={input}
                                        InputProps={{
                                            endAdornment: (
                                                error ?
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <mask id="mask0_34_1183" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                                            <rect width="20" height="20" fill="#D9D9D9" />
                                                        </mask>
                                                        <g mask="url(#mask0_34_1183)">
                                                            <path d="M8 10.9743C8.11622 10.9743 8.21367 10.935 8.29233 10.8563C8.371 10.7778 8.41033 10.6804 8.41033 10.5642C8.41033 10.4478 8.371 10.3504 8.29233 10.2718C8.21367 10.1932 8.11622 10.1538 8 10.1538C7.88378 10.1538 7.78633 10.1932 7.70767 10.2718C7.629 10.3504 7.58967 10.4478 7.58967 10.5642C7.58967 10.6804 7.629 10.7778 7.70767 10.8563C7.78633 10.935 7.88378 10.9743 8 10.9743ZM7.66667 8.76917H8.33333V4.76917H7.66667V8.76917ZM8.00217 14C7.1725 14 6.39244 13.8426 5.662 13.5277C4.93167 13.2128 4.29633 12.7854 3.756 12.2457C3.21567 11.7059 2.78794 11.0711 2.47283 10.3413C2.15761 9.61167 2 8.83194 2 8.00217C2 7.1725 2.15744 6.39244 2.47233 5.662C2.78722 4.93167 3.21456 4.29633 3.75433 3.756C4.29411 3.21567 4.92889 2.78794 5.65867 2.47283C6.38833 2.15761 7.16806 2 7.99783 2C8.8275 2 9.60756 2.15744 10.338 2.47233C11.0683 2.78722 11.7037 3.21456 12.244 3.75433C12.7843 4.29411 13.2121 4.92889 13.5272 5.65867C13.8424 6.38833 14 7.16806 14 7.99783C14 8.8275 13.8426 9.60756 13.5277 10.338C13.2128 11.0683 12.7854 11.7037 12.2457 12.244C11.7059 12.7843 11.0711 13.2121 10.3413 13.5272C9.61167 13.8424 8.83194 14 8.00217 14Z" fill="#CD4747" />
                                                        </g>
                                                    </svg>
                                                    : null
                                            )
                                        }}
                                    />
                                    <FormControl fullWidth>
                                        <Select
                                            sx={{
                                                background: error ? themeColors.red01 : '#f6f6f6',
                                                color: '#848484',
                                                border: 'none',
                                                minWidth: '100%',
                                                borderRadius: '6px',
                                                padding: '0',
                                                outline: 'none',
                                            }}
                                            IconComponent={KeyboardArrowDownIcon}
                                            value={'@123zap.com.br'}
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem value='@123zap.com.br'>@123zap.com.br</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            ) || type === 'WhatsApp Business' && (
                                <TextField
                                    id='outlined-multiline-static'
                                    placeholder='Digite o número do Whatsapp'
                                    variant='outlined'
                                    value={input}
                                    onChange={e => setInput(e.target.value)}
                                    fullWidth
                                    sx={{
                                        background: error ? themeColors.red01 : '#f6f6f6',
                                        color: '#848484',
                                        borderRadius: '6px',
                                        border: 'none',
                                        outline: 'none',
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            error ?
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <mask id="mask0_34_1183" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
                                                        <rect width="20" height="20" fill="#D9D9D9" />
                                                    </mask>
                                                    <g mask="url(#mask0_34_1183)">
                                                        <path d="M8 10.9743C8.11622 10.9743 8.21367 10.935 8.29233 10.8563C8.371 10.7778 8.41033 10.6804 8.41033 10.5642C8.41033 10.4478 8.371 10.3504 8.29233 10.2718C8.21367 10.1932 8.11622 10.1538 8 10.1538C7.88378 10.1538 7.78633 10.1932 7.70767 10.2718C7.629 10.3504 7.58967 10.4478 7.58967 10.5642C7.58967 10.6804 7.629 10.7778 7.70767 10.8563C7.78633 10.935 7.88378 10.9743 8 10.9743ZM7.66667 8.76917H8.33333V4.76917H7.66667V8.76917ZM8.00217 14C7.1725 14 6.39244 13.8426 5.662 13.5277C4.93167 13.2128 4.29633 12.7854 3.756 12.2457C3.21567 11.7059 2.78794 11.0711 2.47283 10.3413C2.15761 9.61167 2 8.83194 2 8.00217C2 7.1725 2.15744 6.39244 2.47233 5.662C2.78722 4.93167 3.21456 4.29633 3.75433 3.756C4.29411 3.21567 4.92889 2.78794 5.65867 2.47283C6.38833 2.15761 7.16806 2 7.99783 2C8.8275 2 9.60756 2.15744 10.338 2.47233C11.0683 2.78722 11.7037 3.21456 12.244 3.75433C12.7843 4.29411 13.2121 4.92889 13.5272 5.65867C13.8424 6.38833 14 7.16806 14 7.99783C14 8.8275 13.8426 9.60756 13.5277 10.338C13.2128 11.0683 12.7854 11.7037 12.2457 12.244C11.7059 12.7843 11.0711 13.2121 10.3413 13.5272C9.61167 13.8424 8.83194 14 8.00217 14Z" fill="#CD4747" />
                                                    </g>
                                                </svg>
                                                : null
                                        )
                                    }}
                                />
                            )
                        }
                    </Box>
                </Box>

                <Box display={'flex'} sx={{ flexDirection: 'column', gap: '7px' }}>
                    <Button variant='outlined' style={{ padding: '10px' }}
                    >
                        Ler Documentação
                    </Button>
                    <Button variant='contained' onClick={handleAction} style={{ padding: '10px' }}
                    >
                        Confirmar Solicitação
                    </Button>
                </Box>
            </Box>

            <div
                style={{ cursor: 'pointer', position: 'absolute', top: '25px', right: '25px' }}
                onClick={() => {
                    setError(false);
                    onClose();
                }}
            >
                <StatusIcon color={'#B7B7B7'} status={'Close'} width={'20px'} height={'20px'} />
            </div>
        </CustomDialog>
    )
}

export default CardModal