// Description: This component render a text customized based on the props data
// Update: 12/08/2024

// React Components and DOM Elements
import React from 'react';

// Material UI Components
import Typography from '@mui/material/Typography';

const Titles = ({ type, color, children, font, bold, styles = {} }) => {
  // Maps custom types to Material-UI Typography variants
  const typeToVariantMap = {
    H0: '38px',
    H1: '34px',
    H2: '30px',
    H3: '24px',
    H4: '20px',
    P1: '16px',
    P2: '13px'
  };

  const variant = typeToVariantMap[type] || '12px';
  const fontSizeAlreadyDefined = 'fontSize' in styles;

  return (
    <Typography style={{ fontSize: fontSizeAlreadyDefined ? 'none' : variant, color: color, fontFamily: font === 'regular' ? 'Satoshi' : 
    font === 'medium' ? 'Satoshi-Medium' : font === 'bold' ? 'Satoshi-Bold' : font === 'black' ? 'Satoshi-Black' : 'Satoshi',
    fontWeight: bold ? 'bold' : 'normal' }}
    sx={styles} 
    >
      {children}
    </Typography>
  );
};

export default Titles;
