import React from 'react';

const DuplicateIcon = (props) => (

    <svg  width={props.width ? props.width : '15'} height={props.height ? props.height : '15'}  viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_78_3539" maskUnits="userSpaceOnUse" x="0" y="0" width="15" height="15">
            <rect width="15" height="15"  fill={props.color ? props.color : '#848484'} />
        </mask>
        <g mask="url(#mask0_78_3539)">
            <path d="M5.625 11.25C5.28125 11.25 4.98698 11.1276 4.74219 10.8828C4.4974 10.638 4.375 10.3438 4.375 10V2.5C4.375 2.15625 4.4974 1.86198 4.74219 1.61719C4.98698 1.3724 5.28125 1.25 5.625 1.25H11.25C11.5938 1.25 11.888 1.3724 12.1328 1.61719C12.3776 1.86198 12.5 2.15625 12.5 2.5V10C12.5 10.3438 12.3776 10.638 12.1328 10.8828C11.888 11.1276 11.5938 11.25 11.25 11.25H5.625ZM3.125 13.75C2.78125 13.75 2.48698 13.6276 2.24219 13.3828C1.9974 13.138 1.875 12.8438 1.875 12.5V3.75H3.125V12.5H10V13.75H3.125Z" fill="#848484" />
        </g>
    </svg>

); export default DuplicateIcon;
