// Description: This component render a showcase of the buttons of the site
// Update: 12/08/2024

// React Components and DOM Elements
import React from 'react';

// Material UI Components
import { Button, Grid } from '@mui/material';

// Components and functions from the project
import ThemeColors from '../ThemeColors/ThemeColors';

// Render Your Component
const Buttons = () => {

    return (

        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
            <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, padding: '22px'}}
                >
                    Default Button
                </Button>

                <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, background: ThemeColors.purple600,padding: '22px' }}
                >
                    Hover Button
                </Button>

                <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1,  background: ThemeColors.purple700, color: ThemeColors.purple50,padding: '22px' }}
                >Active Button
                </Button>
                <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1,  background: ThemeColors.purple700, color: ThemeColors.purple50,padding: '22px' }}
                    disabled
                >Disabled Button
                </Button>

                <Button
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 1, padding: '22px'}}
                >
                    Default Button
                </Button>
                <Button
                    fullWidth
                    variant="outlined"
                    sx={{ mt:1 , background: 'transparent !important', color: ThemeColors.purple500,padding: '22px' ,border: `1px solid ${ThemeColors.purple500}` }}
                >
                    Hover Button
                </Button>
                <Button 
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 1,  background: 'transparent !important', color: ThemeColors.purple700,padding: '22px', border: `1px solid ${ThemeColors.purple700}` }}
                >
                    Active Button
                </Button>

                <Button
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 1,padding: '22px' }}
                    disabled
                >
                    Desactive Button
                </Button>

            </Grid>
        </div>
    )
}
export default Buttons;