import React from "react";

const Emoji01 = (props) => (
   
  <svg
  id="Camada_2"
  data-name="Camada 2"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 208.89 208.9"
  {...props}
>
  <defs>
    <style>
      {
        "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
      }
    </style>
  </defs>
  <g id="Layer_1" data-name="Layer 1">
    <g>
      <path
        className="cls-2"
        d="M153.89,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.89c30.38,0,55-24.63,55-55V55c0-30.38-24.62-55-55-55ZM61.92,100.28c-6.77,0-12.25-6.19-12.25-13.86s5.48-13.85,12.25-13.85,12.27,6.21,12.27,13.85-5.49,13.86-12.27,13.86ZM127.77,126.24c-6.11,6.51-14.4,10.09-23.33,10.09s-17.21-3.58-23.32-10.09c-1.81-1.93-1.72-4.97.21-6.79,1.93-1.81,4.97-1.72,6.79.22,4.27,4.55,10.07,7.06,16.32,7.06s12.06-2.51,16.33-7.06c1.82-1.94,4.85-2.03,6.79-.22,1.93,1.82,2.02,4.86.21,6.79ZM146.97,100.28c-6.78,0-12.27-6.19-12.27-13.86s5.49-13.85,12.27-13.85,12.25,6.21,12.25,13.85-5.48,13.86-12.25,13.86Z"
      />
      <path
        className="cls-2"
        d="M74.19,86.42c0,7.67-5.49,13.86-12.27,13.86s-12.25-6.19-12.25-13.86,5.48-13.85,12.25-13.85,12.27,6.21,12.27,13.85Z"
      />
      <path
        className="cls-2"
        d="M127.56,119.45c1.93,1.82,2.02,4.86.21,6.79-6.11,6.51-14.4,10.09-23.33,10.09s-17.21-3.58-23.32-10.09c-1.81-1.93-1.72-4.97.21-6.79,1.93-1.81,4.97-1.72,6.79.22,4.27,4.55,10.07,7.06,16.32,7.06s12.06-2.51,16.33-7.06c1.82-1.94,4.85-2.03,6.79-.22Z"
      />
      <path
        className="cls-2"
        d="M159.22,86.42c0,7.67-5.48,13.86-12.25,13.86s-12.27-6.19-12.27-13.86,5.49-13.85,12.27-13.85,12.25,6.21,12.25,13.85Z"
      />
      <g>
        <g>
          <path
            className="cls-1"
            d="M74.19,86.42c0,7.67-5.49,13.86-12.27,13.86s-12.25-6.19-12.25-13.86,5.48-13.85,12.25-13.85,12.27,6.21,12.27,13.85Z"
          />
          <path
            className="cls-1"
            d="M159.22,86.42c0,7.67-5.48,13.86-12.25,13.86s-12.27-6.19-12.27-13.86,5.49-13.85,12.27-13.85,12.25,6.21,12.25,13.85Z"
          />
        </g>
        <path
          className="cls-1"
          d="M127.77,126.24c-6.11,6.51-14.4,10.09-23.33,10.09s-17.21-3.58-23.32-10.09c-1.81-1.93-1.72-4.97.21-6.79,1.93-1.81,4.97-1.72,6.79.22,4.27,4.55,10.07,7.06,16.32,7.06s12.06-2.51,16.33-7.06c1.82-1.94,4.85-2.03,6.79-.22,1.93,1.82,2.02,4.86.21,6.79Z"
        />
      </g>
    </g>
  </g>
</svg>

); export default Emoji01;