// Update status notification
// Update in 19/08/2024 ( OK )

import axios from 'axios';

export async function ReadN({data, iv}) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/read-notification`, 
            { [process.env.REACT_APP_RECEIVED_CRYPTO]: data, [process.env.REACT_APP_COOKIE_IV]: iv },  
        );
        
        const responseData = response.data;

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        throw new Error('Erro ao buscar dados da API');
    }
}