import React, { Fragment, useEffect, useState } from 'react';

// Material-UI Components
import AlternativeAvatar from '../../assets/images/avatar-padrao.png';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Box from '@mui/material/Box';
import Badge from '../../assets/images/robo.png';
import { Button } from '@mui/material';
import ConfigIcon from '../../assets/images/config.png';
import { DashboardData2 } from '../../api/backend/Dashboard/DashboardData2';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Fade from '@mui/material/Fade';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import Loading from '../../components/Loading/Loading';
import { isAuthenticated, encrypt, decrypted } from '../../authMiddleware';
import PerformanceIndicator from '../../components/Performance/PerformanceIndicator';
import StatusIcon from '../../components/StatusLabel/StatusIcon';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import Titles from '../../components/Titles/Titles';
import { ReactComponent as TagIcon } from '../../assets/Icons/svg/TagIcon.svg';
import { ReactComponent as CampaignIcon } from '../../assets/Icons/svg/CampaignIcon.svg';
import Notify from '../../assets/ilustrations/Notify.gif'
import { ReadN } from '../../api/backend/Dashboard/ReadN';
import Pagination from '@mui/material/Pagination';
import SheetsIcon from '../../assets/Icons/svg/SheetsIcon.svg';
import Typography from '@mui/material/Typography';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import DynamicTable from '../../components/Tables/DynamicTable';
import StatusLabel from '../../components/StatusLabel/StatusLabel';
import { set } from 'date-fns';

import { Analytics } from '../../api/backend/Dashboard/Analytics';

// Componente render Dashboard Page
function DashboardPage() {

    // Authentication Context Functions
    const { authenticated } = isAuthenticated();
    const [userInfo, setUserInfo] = useState({});

    // State Variables
    const [dataFromAPI, setDataFromAPI] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userAvatar, setUserAvatar] = useState('');

    const colorMappingSentiments = {

        'Positive': {
            iconColor: '#4C8435',
            backgroundColor: '#D3EAC8',
            textColor: '#345427',
        },
        'Negative': {
            iconColor: '#CD4747',
            backgroundColor: ThemeColors.red01,
            textColor: '#4E1B1D',
        },
        'Neutral': {
            iconColor: '#8142FC',
            backgroundColor: '#DDD4FF',
            textColor: '#4F189A',
        },
        'Mixed': {
            iconColor: '#848484',
            backgroundColor: '#E5E5E5',
            textColor: '#303035',
        },
    };

    const [AllNotifications, setAllNotifications] = useState([]);
    const [newNotification, setNewNotification] = useState([]);
    const [showAllNotifications, setShowAllNotifications] = useState(false);
    const [notification, setNotification] = useState([{ notification_id: 1, text: 'teste', backgroundColor: '#DDD4FF', textColor: '#4F189A', date: '2021-10-10' }]);
    const [currentPage, setCurrentPage] = useState(1);
    const notificationsPerPage = 4;

    useEffect(() => {
        if (showAllNotifications) {
            setNewNotification(AllNotifications);
        }
    }, [showAllNotifications]);



    // UseEffect to get the user information
    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, []);

    useEffect(() => {
        if (userInfo['UUID']) {
            const userHash = userInfo['UUID'] + '.png';
            fetchUserAvatar(userHash);
        }
    }, [userInfo]);

    // Mockup Data for Performance Indicator
    const [mockup, setMockupData] = useState({
        'labels': ['Enviadas', 'Visualizadas', 'Recebidas', 'Geral', 'Erro'],
        'datasets': {
            'Enviadas': {
                'quantidade': 0,
                'icone': SendOutlinedIcon
            },
            'Visualizadas': {
                'quantidade': 0,
                'icone': TaskAltOutlinedIcon
            },

            'Recebidas': {
                'quantidade': 0,
                'icone': AccessTimeOutlinedIcon
            },
            'Erro': {
                'quantidade': 0,
                'icone': ErrorOutlineOutlinedIcon
            },
            'Geral': {
                'quantidade': 0,
                'icone': FactCheckOutlinedIcon
            },
        }
    });

    // UseEffect to get the data from the API
    useEffect(() => {
        async function fetchDataWrapper() {
            if (userInfo && userInfo['UUID'] && userInfo['IV']) {
                try {
                    let info = { 'uuid_client': userInfo['UUID'], 'id': userInfo['EMAIL'], 'token': userInfo['TOKEN'] };
                    info = JSON.stringify(info);

                    const encryptedData = encrypt(info, userInfo['IV']);
                    const jsonData = await DashboardData2({ data: encryptedData, iv: userInfo['IV'] });
                    const decryptedData = decrypted(jsonData[process.env.REACT_APP_RECEIVED_CRYPTO]);
                    const resultJson = JSON.parse(decryptedData);

                    setDataFromAPI(resultJson);
                    setMockupData(mockup);
                    setDataSimulation(resultJson['analytics']);


                    console.log('resultJson:', resultJson);

                    const entidades = resultJson['analytics']['Entidades'];
                    const frasesChave = resultJson['analytics']['Frases-Chave'];

                    const entidadesFiltradas = entidades.flatMap((entidadeArray) =>
                        entidadeArray
                            .filter((item) => item.Text && item.Type && item.Score) // Filtrar apenas itens com todos os campos
                            .map((item) => ({
                                'icon': <StatusIcon status={'Alert'} />,
                                'Text': item.Text,
                                'Type': item.Type,
                                'Score': item.Score
                            }))
                    );

                    const frasesChaveFiltradas = frasesChave.flatMap((fraseArray) =>
                        fraseArray
                            .filter((item) => item.Text && item.Score) // Filtrar apenas itens com todos os campos
                            .map((item) => ({
                                'Text': item.Text,
                                'Score': item.Score
                            }))
                    );

                    setEntidades(entidadesFiltradas);
                    setFrasesChave(frasesChaveFiltradas);




                } catch (error) {

                }
            }
        }
        fetchDataWrapper();
    }, [userInfo]);


    const downloadReport = async () => {
        if (userInfo && userInfo['UUID'] && userInfo['IV']) {
            try {
                const info = { 'uuid_client': userInfo['UUID'], 'id': userInfo['EMAIL'], 'token': userInfo['TOKEN'] };
                const encryptedData = encrypt(JSON.stringify(info), userInfo['IV']);

                const blob = await Analytics({
                    data: encryptedData,
                    iv: userInfo['IV'],
                    onProgress: (percentCompleted) => {
                        console.log(`Progresso do download: ${percentCompleted}%`);
                    },
                });

                // Inicia o download do arquivo
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'analytics_report.xlsx';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Erro ao fazer o download do relatório:', error);
            }
        }
    };

    // UseEffect to update the mockup data
    useEffect(() => {
        if (dataFromAPI) {

            try {


                const { datasets = [] } = dataFromAPI;

                const monthlyData = datasets.reduce((acc, curr) => {
                    const monthYear = curr['month-year'];
                    if (!acc[monthYear]) {
                        acc[monthYear] = { send: 0, read: 0, error: 0, total: 0, received: 0 };
                    }
                    acc[monthYear].send += curr.send;
                    acc[monthYear].read += curr.read;
                    acc[monthYear].error += curr.error;
                    acc[monthYear].total += curr.total;
                    acc[monthYear].received += curr.received;
                    return acc;
                }, {});

                const labels = Object.keys(monthlyData);
                const readTotals = labels.map(monthYear => monthlyData[monthYear].read);
                const errorTotals = labels.map(monthYear => monthlyData[monthYear].error);
                const totalTotals = labels.map(monthYear => monthlyData[monthYear].total);
                const sentAndReadTotals = labels.map(monthYear => monthlyData[monthYear].send + monthlyData[monthYear].read);
                const receivedTotals = labels.map(monthYear => monthlyData[monthYear].received);

                setMockupData(prevMockup => ({
                    ...prevMockup,
                    labels: labels,
                    datasets: {
                        ...prevMockup.datasets,
                        Enviadas: {
                            ...prevMockup.datasets['Enviadas'],
                            quantidade: sentAndReadTotals
                        },
                        Visualizadas: {
                            ...prevMockup.datasets['Visualizadas'],
                            quantidade: readTotals
                        },
                        Erro: {
                            ...prevMockup.datasets['Erro'],
                            quantidade: errorTotals
                        },
                        Geral: {
                            ...prevMockup.datasets['Geral'],
                            quantidade: totalTotals
                        },
                        Recebidas: {
                            ...prevMockup.datasets['Recebidas'],
                            quantidade: receivedTotals
                        }
                    }
                }));




            }
            catch (error) {
                console.error('Erro ao atualizar o mockup:', error);
            } finally {
                setLoading(false);

            }
        }
    }, [dataFromAPI]);


    const [dataSimulation, setDataSimulation] = useState();

    const fetchUserAvatar = async (userHash) => {
        try {
            const imageUrl = 'https://files123zap.s3.amazonaws.com/avatar_users/' + userHash;
            const img = new Image();
            img.onload = () => {
                setUserAvatar(imageUrl);
            };
            img.onerror = () => {
                console.error('Erro ao carregar a imagem:', imageUrl);
                setUserAvatar(AlternativeAvatar);
            };
            img.src = imageUrl;
        } catch (error) {
            console.error('Erro ao buscar o avatar do usuário:', error);
            setUserAvatar(AlternativeAvatar);
        }
    };

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };

    // Calculate the current notifications to display
    const indexOfLastNotification = currentPage * notificationsPerPage;
    const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
    const currentNotifications = !showAllNotifications
        ? newNotification.slice(indexOfFirstNotification, indexOfLastNotification)
        : AllNotifications.slice(indexOfFirstNotification, indexOfLastNotification);


    const [entidades, setEntidades] = useState([]);
    const [frasesChave, setFrasesChave] = useState([]);

    const CalculateRelevance = (score) => {
        if (score >= 90) {
            return 'Alta';
        } else if (score >= 60) {
            return 'Média';
        } else {
            return 'Baixa';
        }
    };

    useEffect(() => {


    }, [dataFromAPI]);

    if (loading) {
        <Loading open={loading} />
    }


    // Return the Dashboard Page
    return (
        <Fragment>
            {!loading && (
                <Fade in={!loading}>
                    <Box display={'flex'} flexDirection={'column'} minHeight={'100vh'} width={"100%"}>
                        <Box display={'flex'} gap={3} padding={3} paddingLeft={'20px'} paddingRight={'20px'} paddingTop={'20px'} sx={{ flexDirection: { xl: 'row', lg: 'row', sm: 'column' } }}>
                            <Box flex={1} display={'flex'} flexDirection={'column'} borderRadius={'12px'} overflow={'hidden'} sx={{ minHeight: { xl: '312px', lg: '312px', sm: '290px' } }}>
                                <Box
                                    sx={{
                                        background: '#8142FC',
                                        flex: 1,
                                        display: 'flex',
                                        padding: '30px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Box display={'flex'} justifyContent={'space-between'} minWidth={'100%'}>
                                        <Box display={'flex'} gap={'15px'}>
                                            <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} maxHeight={'71px'}>
                                                <Titles children={'Pronto para os Insights?'} type={'H2'} color={'#F5F3FF'} />
                                            </Box>
                                        </Box>
                                        <Box
                                            component={'img'}
                                            sx={{
                                                cursor: 'pointer',
                                                transition: '.3s',
                                                width: '19.17px',
                                                height: '18.84px',
                                                '&:hover': {
                                                    scale: '1.1'
                                                }
                                            }}
                                            src={ConfigIcon}
                                            alt='Configuration'
                                        />
                                    </Box>
                                    <Box display={'flex'} justifyContent={'space-between'} gap={'15px'}>
                                        <Box
                                            sx={{
                                                background: '#8033F4',
                                                color: '#EDE8FF',
                                                borderRadius: '6px',
                                                height: '33px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '7px',
                                                flex: '1',
                                                padding: '0 0 0 10px',
                                            }}
                                        >
                                            <TagIcon width={'18px'} height={'18px'} fill={'#EDE8FF'} />
                                            <Titles color={'#EDE8FF'} children={`Canais abertos`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px' } }} />
                                            <Titles color={'#EDE8FF'} children={`${dataFromAPI && dataFromAPI['total_channels'] ? dataFromAPI['total_channels'].toString().padStart(2, '0') : '0'}`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px' } }} />
                                        </Box>
                                        <Box
                                            sx={{
                                                background: '#8033F4',
                                                color: '#EDE8FF',
                                                borderRadius: '6px',
                                                height: '33px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '7px',
                                                flex: '1',
                                                padding: '0 0 0 10px'
                                            }}
                                        >
                                            <CampaignIcon width={'18px'} height={'18px'} fill={'#EDE8FF'} />
                                            <Titles color={'#EDE8FF'} children={`Campanhas ativas`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px' } }} />
                                            <Titles color={'#EDE8FF'} children={`${dataFromAPI && dataFromAPI['total_campaigns'] ? dataFromAPI['total_campaigns'].toString().padStart(2, '0') : '0'}`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px' } }} />
                                        </Box>

                                    </Box>
                                    <Box display={'flex'} justifyContent={'space-between'} gap={'15px'}>
                                        <Box
                                            sx={{
                                                background: '#8033F4',
                                                color: '#EDE8FF',
                                                borderRadius: '6px',
                                                height: '33px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '7px',
                                                flex: '1',
                                                padding: '0 0 0 10px',
                                            }}
                                        >
                                            <BorderAllIcon width={'18px'} height={'18px'} style={{ fill: '#EDE8FF' }} />
                                            <Titles color={'#EDE8FF'} children={`Planilhas armazenadas`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px' } }} />
                                            <Titles color={'#EDE8FF'} children={`${dataFromAPI && dataFromAPI['total_spreadsheets'] ? dataFromAPI['total_spreadsheets'].toString().padStart(2, '0') : '0'}`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px' } }} />
                                        </Box>
                                        <Box
                                            sx={{
                                                background: '#8033F4',
                                                color: '#EDE8FF',
                                                borderRadius: '6px',
                                                height: '33px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '7px',
                                                flex: '1',
                                                padding: '0 0 0 10px'
                                            }}
                                        >
                                            <AlternateEmailIcon width={'18px'} height={'18px'} style={{ fill: '#EDE8FF' }} />
                                            <Titles color={'#EDE8FF'} children={`Modelos criados`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px' } }} />
                                            <Titles color={'#EDE8FF'} children={`${dataFromAPI && dataFromAPI['total_templates'] ? dataFromAPI['total_templates'].toString().padStart(2, '0') : '0'}`} styles={{ fontSize: { xl: '16px', lg: '12px', md: '16px', sm: '16px' } }} />
                                        </Box>

                                    </Box>
                                </Box>

                                <Box
                                    sx={{
                                        background: '#8033F4',
                                        height: '100px',
                                        padding: '0 30px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Box display={'flex'} gap={'10px'}>
                                        <Box
                                            component={'img'}
                                            sx={{
                                                width: '100px',
                                                height: '100px',
                                            }}
                                            alt={'Badge'}
                                            src={Badge}
                                        />
                                        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} >
                                            <Titles color={'#F5F3FF'} children={'Inteligência Artificial'}
                                                type={'P2'} />
                                            <Titles
                                                color={'#F5F3FF'}
                                                children={
                                                    'Zapi'
                                                }
                                                styles={{ fontSize: { xl: '24px', lg: '16px', sm: '20px' } }}
                                            />
                                        </Box>
                                    </Box>
                                    <Button
                                        sx={{
                                            background: '#5F1BBC',
                                            borderRadius: '6px',
                                            padding: '12px, 24px, 12px, 24px',
                                            color: '#DDD4FF',
                                            fontSize: '16px',
                                            transition: '.3s',
                                            '&:hover': {
                                                scale: '1.05',
                                                background: '#5F1BBC',
                                            }
                                        }}

                                        onClick={() => downloadReport()}
                                    >
                                        Baixar relatório
                                    </Button>
                                </Box>
                            </Box>
                            <Box
                                flex={1}
                                borderRadius={'12px'}
                                border={'1px solid #D9D9DC'}
                                padding={'30px'}
                                sx={{
                                    background: '#fff',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    gap: '25px',
                                    minHeight: { xl: '312px', lg: '312px', sm: '290px' }
                                }}
                            >
                                <Typography style={{ color: '#8033F4', fontWeight: 'bold', fontSize: '24px' }}>
                                    Análise Geral de Sentimentos <Typography component="span" style={{ fontWeight: 'normal', fontSize: '20px' }}> ( Clientes )</Typography>
                                </Typography>
                                <Box display="flex" flexDirection="column" width="100%" height="90%" alignItems="lefts">

                                    {dataSimulation && Object.keys(dataSimulation['Sentimentos Gerais']).map((key, index) => (
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            padding="0 10px"
                                            key={index}
                                            sx={{
                                                background: colorMappingSentiments[key].backgroundColor,
                                                height: '32px',
                                                borderRadius: '6px',
                                                width: `100%`,
                                                marginBottom: '10px',
                                                minWidth: '100%',
                                            }}
                                        >

                                            <Box display="flex" gap="7px" alignItems="center" flex="1">
                                                <StatusIcon status="Alert" color={colorMappingSentiments[key].iconColor} width="12px" height="12px" />
                                                <Titles children={

                                                    key === 'Mixed' ? 'Sentimentos mistos' : key === 'Neutral' ? 'Sentimentos neutros' : key === 'Positive' ? 'Sentimentos positivos' : 'Sentimentos negativos'

                                                } color={colorMappingSentiments[key].textColor} type="P2" />
                                                <Titles children={`: ${dataSimulation['Sentimentos Gerais'][key].toFixed(2)}%`} color={colorMappingSentiments[key].textColor} type="P2" />
                                            </Box>
                                        </Box>
                                    ))}
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        padding="0 10px"
                                        key='total'
                                        sx={{
                                            background: ThemeColors.yellow01,
                                            height: '32px',
                                            borderRadius: '6px',
                                            width: '100%',
                                            marginBottom: '10px',
                                            minWidth: '100%',
                                        }}
                                    >

                                        <Box display="flex" gap="7px" alignItems="center" flex="1">
                                            <StatusIcon status="Alert" color='#DEA73F' width="12px" height="12px" />
                                            <Titles children='Setenças analisadas' color='#8D4D1B' type="P2" />
                                            <Titles children={dataFromAPI && dataFromAPI['analytics'] && dataFromAPI['analytics']['Total de Mensagens'] ? dataFromAPI['analytics']['Total de Mensagens'] : '0'}
                                                color='#8D4D1B' type="P2" />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <PerformanceIndicator mockup={mockup} dataFromAPI={dataFromAPI} />

                        <Box display={'flex'} gap={3} padding={3} paddingLeft={'20px'} paddingRight={'20px'} paddingTop={'10px'} sx={{ flexDirection: { xl: 'row', lg: 'row', sm: 'column' } }}>
                            <Box flex={1} display={'flex'} flexDirection={'column'} borderRadius={'12px'} overflow={'hidden'} sx={{ minHeight: { xl: '312px', lg: '312px', sm: '290px' } }}>
                                <Box
                                    flex={1}
                                    borderRadius={'12px'}
                                    border={'1px solid #D9D9DC'}
                                    padding={'30px'}
                                    sx={{
                                        background: '#fff',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        minHeight: { xl: '312px', lg: '312px', sm: '290px' }
                                    }}
                                >
                                    <Typography style={{ color: '#8033F4', fontWeight: 'bold', fontSize: '24px', marginBottom: '10px' }}>
                                        Análise de Entidades
                                        <Typography component="span" style={{ fontWeight: 'normal', fontSize: '20px' }}> ( Mensagens recebidas )</Typography>
                                    </Typography>
                                    <DynamicTable
                                        title="Entidades"
                                        data={entidades}
                                        columns={[
                                            { header: '', field: 'icon', render: (icon) => null },
                                            {
                                                header: 'Texto',
                                                field: 'Text',
                                                render: (Text) => (
                                                    <span>
                                                        {Text.length > 20 ? `${Text.substring(0, 20)}...` : Text}
                                                    </span>
                                                )
                                            },
                                            { header: 'Tipo', field: 'Type', render: (Type) => <StatusLabel status={Type} usingOnTable /> },
                                            {
                                                header: 'Taxa de confiança',
                                                field: 'Score', render: (score) => <Titles children={score.toFixed(2)} type="P2" />
                                            }
                                        ]}
                                        actions={[]}
                                        activeSearch={false}
                                        titleSearch={'Pesquisar solicitação'}
                                        titleLabel={'Solicitações por página'}
                                        itensPerPage={4}
                                    />
                                </Box>

                            </Box>
                            <Box
                                flex={1}
                                borderRadius={'12px'}
                                border={'1px solid #D9D9DC'}
                                padding={'30px'}
                                sx={{
                                    background: '#fff',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    minHeight: { xl: '312px', lg: '312px', sm: '290px' }
                                }}
                            >

                                <Typography style={{ color: '#8033F4', fontWeight: 'bold', fontSize: '24px' }}>
                                    Análise de Sentenças
                                    <Typography component="span" style={{ fontWeight: 'normal', fontSize: '20px' }}> ( Recebidas )</Typography>
                                </Typography>
                                <DynamicTable
                                    title="Frases-Chave"
                                    data={frasesChave}
                                    columns={[
                                        { header: '', field: 'icon', render: (icon) => null },
                                        {
                                            header: 'Frases principais', field: 'Text', render: (Text) => (
                                                <span>
                                                    {Text.length > 20 ? `${Text.substring(0, 20)}...` : Text}
                                                </span>
                                            )
                                        },
                                        { header: 'Relevância', field: 'Score', render: (score) => <StatusLabel status={CalculateRelevance(score)} usingOnTable /> },
                                        {
                                            header: 'Taxa de confiança',
                                            field: 'Score', render: (score) => <Titles children={score.toFixed(2)} type="P2" />
                                        }
                                    ]}
                                    actions={[
                                    ]}
                                    activeSearch={false}
                                    titleSearch={'Pesquisar por frases'}
                                    titleLabel={'Frases por página'}
                                    itensPerPage={4}

                                />
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            )}
            <Loading open={loading} />
        </Fragment>
    );
}

export default DashboardPage;