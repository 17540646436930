import React from "react";

const Logo = () => (
    <svg width="118" height="37" viewBox="0 0 118 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_34_335)">
            <path d="M39.1513 0V30.8242H11.6581C8.78254 30.8242 6.41891 28.5375 6.41891 25.6669V7.484H6.26105L0 0H39.1513Z" fill="#D4BDFF" />
            <path d="M44.5484 0V30.8242H13.4309V27.645C13.4309 22.5276 16.4238 20.7427 22.4502 16.1691C25.6415 13.8047 27.0196 12.4082 27.0196 10.3146C27.0196 8.56964 25.7994 7.3685 23.7899 7.3685C21.663 7.3685 19.8903 9.15131 19.0242 11.2071L12.3685 8.26095C13.5503 4.57565 17.4093 0.155392 24.0266 0H44.5484Z" fill="#A789EF" />
            <path d="M52.8189 7.3286V23.9723C52.8189 27.7269 49.7172 30.7738 45.903 30.7906C43.7698 30.799 42.4322 30.8158 41.5938 30.7864C41.3187 30.8242 41.0435 30.8242 40.7662 30.8242C34.5819 30.8242 30.3666 27.0234 28.9502 23.3801L35.3307 20.0077C36.3546 21.9858 37.9311 23.5355 40.5294 23.5355C42.4983 23.5355 43.7207 22.4499 43.7207 20.9758C43.7207 19.2707 42.4215 18.1451 40.412 18.1451H36.0005V12.4061H40.6488C42.1848 12.4061 43.5244 11.205 43.5244 9.80857C43.5244 8.02577 42.0674 7.17111 40.5699 7.17111C38.3257 7.17111 36.6703 8.52764 35.5291 10.6212L29.4643 7.59739C31.1559 3.14143 35.608 0 40.9645 0C41.2397 0 41.5171 0 41.7517 0.037798V0H45.3761C49.4698 0 52.8211 3.29682 52.8211 7.3286H52.8189Z" fill="#7428EA" />
            <path d="M58.423 26.3032L70.0768 14.4325H60.7482L61.305 11.2512H74.9598L74.403 14.3758L62.7684 26.2465H72.8842L72.3082 29.4278H57.864L58.4208 26.3032H58.423Z" fill="#7428EA" />
            <path d="M75.1411 20.245C76.1416 14.6593 80.8347 10.8732 86.0846 10.8732C89.2205 10.8732 91.0849 12.0092 92.143 13.7312L92.5846 11.2512H96.3924L93.1414 29.4257H89.3336L89.7751 26.9457C88.1027 28.6677 85.8137 29.8037 82.6779 29.8037C77.428 29.8037 74.1385 25.8286 75.139 20.2429L75.1411 20.245ZM83.7573 26.4355C87.3155 26.4355 90.3532 23.5964 90.9697 20.245C91.5649 16.8936 89.4701 14.2435 85.931 14.2435C82.392 14.2435 79.5463 16.8936 78.949 20.245C78.3346 23.5964 80.199 26.4355 83.7573 26.4355Z" fill="#7428EA" />
            <path d="M95.185 37L99.8013 11.2533H103.609L103.168 13.7333C104.84 12.0114 107.129 10.8753 110.265 10.8753C115.515 10.8753 118.823 14.8504 117.823 20.4361C116.822 26.0218 112.11 29.8079 106.86 29.8079C103.724 29.8079 101.86 28.6719 100.802 26.9499L98.995 37.0021H95.1872L95.185 37ZM107.014 26.4355C110.572 26.4355 113.418 23.7854 114.015 20.434C114.612 17.0826 112.746 14.2435 109.188 14.2435C105.629 14.2435 102.592 17.0826 101.994 20.434C101.399 23.7854 103.475 26.4355 107.014 26.4355Z" fill="#7428EA" />
        </g>
        <defs>
            <clipPath id="clip0_34_335">
                <rect width="118" height="37" fill="white" />
            </clipPath>
        </defs>
    </svg>
);  

export default Logo;