import React from "react";

const Emoji29 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.9 208.9"
    width={props.width || '85'} height={props.height || '85'}
>
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.9,0H55C24.62,0,0,24.62,0,55v98.9c0,30.38,24.62,55,55,55h98.9c30.37,0,55-24.62,55-55V55c0-30.38-24.63-55-55-55ZM61.93,100.11c-6.78,0-12.26-6.19-12.26-13.86s5.48-13.85,12.26-13.85,12.27,6.21,12.27,13.85-5.5,13.86-12.27,13.86ZM131.44,118.22v6.9c0,6.28-5.1,11.38-11.38,11.38s-11.4-5.1-11.4-11.38v-6.9h-31.2c-2.66,0-4.81-2.15-4.81-4.8s2.15-4.79,4.81-4.79h53.98c2.66,0,4.81,2.15,4.81,4.79s-2.15,4.8-4.81,4.8ZM146.97,100.11c-6.77,0-12.27-6.19-12.27-13.86s5.5-13.85,12.27-13.85,12.25,6.21,12.25,13.85-5.48,13.86-12.25,13.86Z"
        />
        <path
          className="cls-2"
          d="M74.2,86.25c0,7.67-5.5,13.86-12.27,13.86s-12.26-6.19-12.26-13.86,5.48-13.85,12.26-13.85,12.27,6.21,12.27,13.85Z"
        />
        <path
          className="cls-2"
          d="M136.25,113.42c0,2.65-2.15,4.8-4.81,4.8v6.9c0,6.28-5.1,11.38-11.38,11.38s-11.4-5.1-11.4-11.38v-6.9h-31.2c-2.66,0-4.81-2.15-4.81-4.8s2.15-4.79,4.81-4.79h53.98c2.66,0,4.81,2.15,4.81,4.79Z"
        />
        <path
          className="cls-2"
          d="M159.22,86.25c0,7.67-5.48,13.86-12.25,13.86s-12.27-6.19-12.27-13.86,5.5-13.85,12.27-13.85,12.25,6.21,12.25,13.85Z"
        />
        <g>
          <path
            className="cls-1"
            d="M136.25,113.42c0,2.65-2.15,4.8-4.81,4.8v6.9c0,6.28-5.1,11.38-11.38,11.38s-11.4-5.1-11.4-11.38v-6.9h-31.2c-2.66,0-4.81-2.15-4.81-4.8s2.15-4.79,4.81-4.79h53.98c2.66,0,4.81,2.15,4.81,4.79Z"
          />
          <g>
            <path
              className="cls-1"
              d="M74.2,86.25c0,7.67-5.5,13.86-12.27,13.86s-12.26-6.19-12.26-13.86,5.48-13.85,12.26-13.85,12.27,6.21,12.27,13.85Z"
            />
            <path
              className="cls-1"
              d="M159.22,86.25c0,7.67-5.48,13.86-12.25,13.86s-12.27-6.19-12.27-13.86,5.5-13.85,12.27-13.85,12.25,6.21,12.25,13.85Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>

); export default Emoji29;