import React, { Fragment, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import AddCommentIcon from '@mui/icons-material/AddComment'; import CustomCard from '../../components/Cards/CustomCard';
import DynamicTable from '../../components/Tables/DynamicTable';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated, encrypt, decrypted } from '../../authMiddleware'; // Corrigido aqui
import Typography from '@mui/material/Typography';
import StatusLabel from '../../components/StatusLabel/StatusLabel';
import StatusIcon from '../../components/StatusLabel/StatusIcon';
import EditIcon from '../../assets/Icons/components/Edit';
import PlusIcon from '../../assets/Icons/components/Plus';
import HeaderPage from '../../components/HeaderPage/HeaderPage';

import { RequestByID } from '../../api/backend/Request/MyRequests';
import Loading from '../../components/Loading/Loading';
import EmptyTemplate from "../../assets/ilustrations/EmptyTemplates.png"
import Titles from "../../components/Titles/Titles";
import Button from "@mui/material/Button";
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
 
function Support() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [colorAlert, setColorAlert] = useState('');
  const [dataFromAPI, setDataFromAPI] = useState(null);
  const [openModalHelp, setOpenModalHelp] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedMessages, setSelectedTemplate] = useState(null); // Novo estado para armazenar o template selecionado
  const [videoUrl, setVideoUrl] = useState('./modelos.mp4');
  const [showLoading, setShowLoading] = useState(false);

  const itensPrimary = getComputedStyle(document.documentElement).getPropertyValue('--itensPrimary').trim();
  const itensSecondary = getComputedStyle(document.documentElement).getPropertyValue('--itensSecondary').trim();
  const fontPrimary = getComputedStyle(document.documentElement).getPropertyValue('--fontPrimary').trim();
  const fontSecondary = getComputedStyle(document.documentElement).getPropertyValue('--fontSecondary').trim();
  let Navigate = useNavigate();

  const { authenticated, userType, uuidClient } = isAuthenticated();
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  const [userInfo, setUserInfo] = useState({});


  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, []);


  useEffect(() => {
    console.log('informações do usuario', userInfo)
    if (userInfo['UUID']) {
      console.log('to aquiiiiiiii', userInfo)
      fetchData();
    }
  }, [userInfo])


  const handleCloseModalHelp = () => {
    setOpenModalHelp(false);
  };


  const [dataCards, setDataCards] = useState([
    { title: 'Minhas Solicitações', dataKey: 'my_request', icon: null, qtd: 0, titleColor: fontSecondary, valueColor: fontPrimary },
    { title: 'Solicitações em aberto', dataKey: 'open_request', icon: null, qtd: 0, titleColor: fontSecondary, valueColor: fontPrimary }
  ]);

  const [itemsToShow, setItemsToShow] = useState([]);

  const formataData = (data) => {
    const dataSplit = data.split(' ');
    const dataFormatada = dataSplit[0].split('-').reverse().join('/');
    return dataFormatada;

  }


  const statusSymbols = {
    Finalizada: (
      <Typography style={{
        backgroundColor: '#fff',
        color: '#333',
        padding: '15px',
        border: '1px solid #919191',
        borderRadius: '4px',
        textTransform: 'uppercase',
        fontFamily: 'math',
        minWidth: '200px',
        fontSize: '10px',
        letterSpacing: '2px',
        display: 'inline-block', // Permitir definir largura
        textAlign: 'center', // Centralizar texto
      }} title="Finalizada">
        <span style={{
          // Borda somente baixo
          paddingTop: '5px',
          borderBottom: '2px solid #4CAF',
        }}>
          Finalizada
        </span>
      </Typography>
    ),
    Pendente: (
      <Typography style={{
        backgroundColor: '#fff',
        color: '#333',
        padding: '15px',
        border: '1px solid #919191',
        borderRadius: '4px',
        textTransform: 'uppercase',
        fontFamily: 'math',
        minWidth: '200px',
        fontSize: '10px',
        letterSpacing: '2px',
        display: 'inline-block', // Permitir definir largura
        textAlign: 'center', // Centralizar texto
      }} title="Pendente">
        <span style={{
          // Borda somente baixo
          paddingTop: '5px',
          borderBottom: '2px solid #FFC107',
        }}>
          Pendente
        </span>
      </Typography>
    )
  }




  const fetchData = async () => {
    try {
      //RequestByID

      setLoading(true);

      let info = { 'ID': userInfo['UUID'] }
      info = JSON.stringify(info);

      console.log('info', userInfo['IV']);


      // PEGA O IV ARMAZENADO EM COOKIE NA HORA DO LOGIN
      const cookieIv = process.env.REACT_APP_COOKIE_IV;

      // CRIPTOGRAFA OS DADOS PARA O ENVIO AO BACKEND UTILIZANDO O IV ARMAZENADO EM COOKIE
      const encryptedData = encrypt(info, userInfo['IV']);

      console.log('encryptedData', encryptedData);

      const response = await RequestByID({ data: encryptedData, iv: userInfo['IV'] });

      const decryptedData = decrypted(response[process.env.REACT_APP_RECEIVED_CRYPTO]);

      const resultJson = JSON.parse(decryptedData);

      let itemsToshow = [];

      console.log('Data from API:', resultJson)


      itemsToshow = resultJson.map(item => {
        return {

          id: item.id,
          title: item.title,
          description: item.description,
          status: item.status,
          icon: 'Suporte',
          requesterid: item.requesterid,
          body: item.body,
          priority: item.priority,
          sectorStatus: item.sector,
          // Datas formatadas

          creationdate: formataData(item.creationdate),
          updatedate: formataData(item.updatedate),


        };
      });

      setItemsToShow(itemsToshow);

      // Inicialza o atributo qtd de cada card com a quantidade de templates

      const dataCardsCopy = [...dataCards];
      dataCardsCopy[0].qtd = resultJson.length;
      dataCardsCopy[1].qtd = resultJson.filter(item => item.status === 'Pendente').length;

      setDataCards(dataCardsCopy);

      console.log('Data from API:', resultJson);

    } catch (error) {
      console.error('Erro ao obter modelos:', error);
      setMessage('Algo deu errado. Entre em contato com o suporte.');
      setColorAlert('rgb(255, 0, 59)');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
      setShowLoading(true);
    }
  };




  useEffect(() => {
  }, [itemsToShow]);

  const handleCloseModal = () => {
    setOpenModalAdd(false);
  };

  const handleOpenModalHelp = () => {
    console.log('entrou')
    setOpenModalHelp(true);
  };

  const handleOpenModalAdd = () => {
    Navigate('/suporte/adicionar');
  };

  const formatNumber = (number) => {
    if (typeof number === 'number') {
      number = number.toString();
    }

    if (!number || number == 0) {
      return '0';
    }

    const parts = number.split('.');
    const formattedParts = parts.map(part => part.padStart(2, '0'));
    return formattedParts.join('.');
  };

  const [openModalView, setOpenModalView] = useState(false);
  const handleOpenModalView = (template) => {
    setSelectedTemplate(template); // Atualiza o estado com os dados do template selecionado
    setOpenModalView(true);
  };


  if (loading) {
    return < Loading open={true} />;
  }


  return (
    <Fragment>

      <Fade in={!loading} timeout={1000}>
        <Box width={"100%"} height={"100vh"}>

          < HeaderPage title="Solicitações" icon={<AddCommentIcon />} />


          {

            itemsToShow && itemsToShow.length > 0 && (
              <DynamicTable
                title="Campanhas"
                data={itemsToShow}
                columns={[
                  { header: '', field: 'icon', render: (icon) => <StatusIcon status={icon} /> },
                  { header: 'Titulo', field: 'title' },
                  { header: 'Abertura', field: 'creationdate' },
                  { header: 'Categoria', field: 'sectorStatus', render: (status) => <StatusLabel status={status} usingOnTable /> },
                  { header: 'Status', field: 'status', render: (status) => <StatusLabel status={status} usingOnTable />, filter: true },

                ]}
                actions={[

                  { icon: <EditIcon />, tooltip: 'Visualizar Solicitação', action: handleOpenModalView, isVisible: (item) => true },

                ]}

                button={{ icon: <PlusIcon />, tooltip: 'Adicionar Solicitação ', action: handleOpenModalAdd }
                }

                activeSearch={true}
                titleSearch={'Pesquisar solicitação'}
                titleLabel={'Solicitações por página'}
              />

            ) || (

              <Box display={"flex"} sx={{ justifyContent: "center", alignItems: "center", width: "100%", height: "90%" }}>
                <Box>
                  <img src={EmptyTemplate} alt="Você não tem modelos" width={"494px"} height={"330px"} />
                  <Box display={"flex"} sx={{ flexDirection: "column", gap: "10px", justifyContent: "center", textAlign: "center" }}>
                    <Titles bold type={"H3"} children={"Parece que você não tem solicitações :("} color={"#8033F4"} />
                    <Titles type={"P1"} children={"Abra uma agora mesmo, é fácil e rápido"} />
                    <Button
                      variant='contained'
                      style={{ color: '#fff', width: '100%', padding: '10px', marginTop: "10px" }}
                      onClick={() => Navigate("/suporte/adicionar")}
                    >
                      Abrir Solicitação
                    </Button>
                  </Box>
                </Box>
              </Box>
            )
          }
        </Box>
      </Fade>
      <Dialog
        open={openModalView}
        onClose={() => setOpenModalView(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent style={{ borderRadius: '12px' }}>

          <Grid container spacing={1}>

            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '20px'
              }}
            >
              <Typography variant="h5">
                Detalhes da Solicitação
              </Typography>
              <div style={{ marginLeft: 'auto' }}> {/* Move o StatusLabel para a direita */}
                <StatusLabel status={selectedMessages?.body?.sector} />
              </div>
            </Grid>

            <Grid item xs={12} style={{ display: 'flex' }}>
              <Typography variant="body1" style={{ fontWeight: 'bold', marginRight: '4px' }}>Titulo:</Typography>
              <Typography variant="body1">{selectedMessages?.title}</Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <Typography variant="body1" style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
                <span style={{ fontWeight: 'bold', marginRight: '4px' }}>Descrição:</span>
                {selectedMessages?.description}</Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                minHeight={'48px'}
                sx={{
                  background: '#F5F5F5',
                  borderRadius: '4px',
                  padding: '0 10px'
                }}
              >
                {selectedMessages?.body?.files?.map((file, index) => (
                  <>
                    <Box
                      key={index}
                      display={'flex'}
                      gap={'10px'}
                      alignItems={'center'} 
                      sx={{
                        maxWidth: '100%',
                        wordBreak: 'break-word',
                      }}
                    >
                      <img src={file.base64String} alt="file Image" width={'24px'} height={'24px'} style={{ objectFit: 'cover' }} />
                      <Typography variant="body2" style={{ fontSize: '13px', color: '#303035', maxWidth: '200px' }}>
                        {file.name.length > 25 ? `${file.name.substring(0, 25)}...` : file.name}
                      </Typography>
                    </Box>

                  </>
                ))}
              </Box>
            </Grid>

            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                minHeight={'48px'}
                marginTop={'20px'}
                sx={{
                  background: '#E9F5E3',
                  borderRadius: '4px',
                  padding: '0 10px'
                }}
              >
                <Typography variant="body1" >Estamos analisando sua solicitação, entraremos em contato em breve.</Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalView(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default Support;
