// Desc: This component is responsible for rendering the content of a message. 
// Update: 12/08/2024

// React and Material-UI components
import React, { useState } from 'react';
import { Grid, Card, CardContent } from '@mui/material';

// Components and styles used in this component
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';

const RenderMessageContent = (message) => {


    function formatWhatsAppMessage(message) {


        let formatted = message.replace(/\\n/g, '\n');      
        formatted = formatted.replace(/(?<!\*)\*(?!\*)/g, '**');
      

        return formatted;
      }
      

    if (!message) return null;

    switch (message.message.type) {
        case 'whatsapp':

            return (
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Card sx={{
                            background: message.message.fromMe ? '#E2FFC7' : '#fefefe',
                            padding: '0px',
                            borderRadius: '4px',
                            fontSize: '12px',
                            width: '100%',
                            maxWidth: '100%',
                            border: 'none',
                            color: '#000',
                            whiteSpace: 'pre-wrap'  // Garante que quebras de linha e espaços sejam preservados

                        }}>
                            <CardContent>
                                <div style={{ lineHeight: '1.6', overflowWrap: 'break-word' }}>
                                    <ReactMarkdown
                                        remarkPlugins={[breaks]} // Assegure-se de que o plugin está sendo passado corretamente
                                    >
                                        {formatWhatsAppMessage(message.message.message)}
                                    </ReactMarkdown>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            );

        case 'img':
            return (
                <div style={{
                    background: '#E2FFC7', padding: '10px', borderRadius: '4px', maxWidth: '90%',
                }}>
                    <img src={message.message.message} alt="Image" style={{ maxWidth: '100%', maxHeight: '300px' }} />
                </div>
            );

        case 'sms':

            if (!message.message.message) return null;
            return (
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Card sx={{
                            background: '#cce6f3',
                            padding: '0px',
                            borderRadius: '4px',
                            fontSize: '12px',
                            minWidth: '250px',
                            maxWidth: '0px',
                            border: 'none',
                            color: '#000',
                        }}>
                            <CardContent>
                                <div style={{ lineHeight: '1.6' }}>
                                    <ReactMarkdown remarkPlugins={[breaks]}>
                                        {message.message.message}
                                    </ReactMarkdown>
                                </div>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            );

        default:
            return null;
    }
};

export default RenderMessageContent;
