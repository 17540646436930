// Desc: This page is responsible for displaying the selected template and its details.
// Date: 20/08/2024

import React, { useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import ViewWhatsAppScreen from '../../components/WhatsAppScreen/ViewWhatsAppScreen';
import Dropzone from 'react-dropzone';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated, encrypt, decrypted } from '../../authMiddleware';

import { useLocation } from 'react-router-dom';
import {TemplateUpdate} from '../../api/backend/Templates/UpdateTemplate';
import { set } from 'date-fns';
import { id } from 'date-fns/locale';

function TemplateView() {

    // Cores Temas da Aplicação
    const itensPrimary = getComputedStyle(document.documentElement).getPropertyValue('--itensPrimary').trim();
    const itensSecondary = getComputedStyle(document.documentElement).getPropertyValue('--itensSecondary').trim();
    const fontPrimary = getComputedStyle(document.documentElement).getPropertyValue('--fontPrimary').trim();
    const fontSecondary = getComputedStyle(document.documentElement).getPropertyValue('--fontSecondary').trim();

    const Navigate = useNavigate();
    const location = useLocation();
    const selectedItem = location.state ? location.state.selectedItem : null;

    const [userInfo, setUserInfo] = useState({});
    const { authenticated, userType, uuidClient } = isAuthenticated();

    const [title, setTitle] = useState(''); // Estado para armazenar o título do modelo
    const [typeMessage, setTypeMessage] = useState('');
    const [seloQualidade, setSeloQualidade] = useState('');
    const [messageTemporary, setMessageTemporary] = useState('');
    const [typeChannel, setTypeChannel] = useState('WhatsApp');
    const [messages, setMessages] = useState([]);
    const [disabledelectedChannel, setDisabledelectedChannel] = useState(false);
    const [buttonAddMessage, setButtonAddMessage] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [isVisible, setIsVisible] = useState(false);  // Estado para controlar a visibilidade dos Accordions

    const [selectedFile, setSelectedFile] = useState(null);
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);
    const [variablesOfTemplate, setVariablesOfTemplate] = useState([]);

    const [viewWhatsAppScreenProps, setViewWhatsAppScreenProps] = useState(
        {
            open: true, // Assume it's always open for demonstration
            isPerfil: true,
            handleClose: () => console.log('Handle close'),
            type: null,
            data: {
                number: '',
                status: '',
                name: '',
                update_date: new Date().toLocaleString()
            },
            messages: {
                messages: [
                    //{ id: 2, type: 'text', message: '[Mensagem automatica]', fromMe: true }
                ]

            }
        }
    );


    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, []);

    // Atualiza o tipo de canal no componente de visualização do WhatsApp
    useEffect(() => {

        if (typeChannel === 'WhatsApp') {
            setViewWhatsAppScreenProps((prevProps) => ({
                ...prevProps,
                type: 'whatsapp',
            }));
        } else if (typeChannel === 'SMS') {
            setViewWhatsAppScreenProps((prevProps) => ({
                ...prevProps,
                type: 'sms',
            }));
        } else if (typeChannel === 'E-mail') {
            setViewWhatsAppScreenProps((prevProps) => ({
                ...prevProps,
                type: 'email',
            }));
        }

        setViewWhatsAppScreenProps((prevProps) => ({

            // Limpando as mensagens ao mudar o tipo de canal   

            ...prevProps,
            messages: {
                messages: []
            },
        }));
        setMessageTemporary('');

    }, [typeChannel]);

    useEffect(() => {

        if (messageTemporary !== '') {
            if (typeChannel === 'SMS') {
                setViewWhatsAppScreenProps((prevProps) => ({
                    ...prevProps,
                    messages: {
                        messages: [{ id: prevProps.messages.messages.length + 1, type: 'sms', message: messageTemporary, fromMe: true }],
                    },
                }));
            } else if (typeChannel === 'E-mail') {
                setViewWhatsAppScreenProps((prevProps) => ({
                    ...prevProps,
                    messages: {
                        messages: [{ id: prevProps.messages.messages.length + 1, type: 'email', message: messageTemporary, fromMe: true },
                        { id: prevProps.messages.messages.length + 2, type: 'document', message: 'Clique aqui para visualizar o documento', fromMe: true }
                        ],
                    },
                }));
            }
        }
    }, [messageTemporary]);

    //Inicializa o componente com o modelo selecionado
    useEffect(() => {
        console.log(selectedItem, 'selectedItem')

        setTitle(selectedItem['title']);
        setTypeChannel(selectedItem['channel_type']);
        setSeloQualidade(selectedItem['integration_icons']);
        setTypeMessage(selectedItem['msg_type']);

        setViewWhatsAppScreenProps((prevProps) => ({
            ...prevProps,
            messages: {
                messages: selectedItem['messageJson']['messages'],
            },
            
        }));
        
    }, [selectedItem]);


    useEffect(() => {
        // Este código será executado quando `buttonAddMessage` ou `selectedFile` mudar
        if (buttonAddMessage && messageTemporary !== '') {
            const newId = viewWhatsAppScreenProps.messages.messages.length + 1;
            const newMessage = { id: newId, type: 'text', message: messageTemporary, fromMe: true };

            setViewWhatsAppScreenProps((prevProps) => ({
                ...prevProps,
                messages: {
                    ...prevProps.messages,
                    messages: [...prevProps.messages.messages, newMessage],
                },
            }));

            setMessageTemporary('');

        } else if (selectedFile) {
            const newId = viewWhatsAppScreenProps.messages.messages.length + 1;
            const newMessage = { id: newId, type: 'img', message: selectedFile.base64String, fromMe: true };

            setViewWhatsAppScreenProps((prevProps) => ({
                ...prevProps,
                messages: {
                    ...prevProps.messages,
                    messages: [...prevProps.messages.messages, newMessage],
                },
            }));

            setSelectedFile(null);
        }

        // Reset o estado do botão para prevenir múltiplas adições
        setButtonAddMessage(false);
        setIsPreviewVisible(false); // Esconde a pré-visualização após adicionar a mensagem

    }, [buttonAddMessage, selectedFile, messageTemporary, viewWhatsAppScreenProps.messages.messages]);

    const handleFileChange = (acceptedFiles) => {
        const file = acceptedFiles[0];

        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();

            reader.onload = () => {
                const base64String = reader.result;
                setSelectedFile({ file, base64String });
                setIsPreviewVisible(true);
            };

            reader.readAsDataURL(file);
        } else {
            console.error('Por favor, selecione apenas imagens.');
            setSelectedFile(null);
            setIsPreviewVisible(false);
        }
    };

    const addVariableInTemporayMessage = (variable) => {
        setMessageTemporary((prevMessage) => prevMessage + `{{${variable}}}`);
    }

    const toggleAccordionVisibility = () => {
        setIsVisible(!isVisible);  // Alterna a visibilidade dos Accordions
    };

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getIntegrationSystem = () => {

        if (typeChannel === 'WhatsApp') {

            if (seloQualidade === 'codeChat') {
                return 'codeChat';
            }
            else if (seloQualidade === 'whatsAppOfficial') {
                return 'whatsAppOfficial';
            }

            else if (typeChannel === 'SMS') {
                return 'sms';
            } else if (typeChannel === 'E-mail') {
                return 'email';
            }

        }

    }

    const validateFieldsForSave = () => {

        if (typeChannel === 'WhatsApp') {

            if (title === '') {
                return false;
            }
            if (seloQualidade === '') {
                return false;
            }
            if (typeMessage === '') {
                return false;
            }
            if (typeMessage === '') {
                return false;
            }
            if (viewWhatsAppScreenProps.messages.messages.length === 0) {
                return false;
            }
        }

        return true;

    }


    const saveTemplate = async () => {

        if (!validateFieldsForSave()) {
            console.log('Campos obrigatórios não preenchidos');
            return;
        }

        let info = {

            'uuid_client': userInfo['UUID'],
            id: selectedItem['id'],
            'title': title,
            'message_json': viewWhatsAppScreenProps.messages,
            'integration_system': getIntegrationSystem(),
            'status': 'Pendente',
            'msg_type': typeMessage,
            'channel_type': typeChannel
        }

        info = JSON.stringify(info);

        // PEGA O IV ARMAZENADO EM COOKIE NA HORA DO LOGIN

        const cookieIv = process.env.REACT_APP_COOKIE_IV;

        // CRIPTOGRAFA OS DADOS PARA O ENVIO AO BACKEND UTILIZANDO O IV ARMAZENADO EM COOKIE

        const encryptedData = encrypt(info, userInfo['IV']);

        // ENVIA OS DADOS CRIPTOGRAFADOS PARA O BACKEND

        const result = await TemplateUpdate({ data: encryptedData, iv: userInfo['IV'] });

        // DECRYPTA OS DADOS RECEBIDOS DO BACKEND

        const decryptedData = decrypted(result[process.env.REACT_APP_RECEIVED_CRYPTO]);

        // CONVERTE OS DADOS DE JSON PARA OBJETO

        const resultJson = JSON.parse(decryptedData);
        
        console.log(resultJson, 'resultJson')
        

        if (resultJson['status'] === 'success') {
            Navigate('/template');
        } else {
        }

        console.log(resultJson, 'resultJson')

    }

    const handleRemoveMessage = (id) => {

        const newMessages = viewWhatsAppScreenProps.messages.messages.filter((message) => message.id !== id);

        setViewWhatsAppScreenProps((prevProps) => ({
            ...prevProps,
            messages: {
                messages: newMessages,
            },
        }));
    }
        
    return (

        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                bgcolor: 'background.paper',
                overflow: 'auto',
                padding: 5
            }}
        >
            <Grid container spacing={1}>

                <Grid container spacing={2} sx={{ marginTop: '20px', }}>


                    <Grid item xs={12} md={6}>

                        <Grid container spacing={2} sx={{ marginBottom: '15px' }}> {/* Assegura que os itens filhos dividam o espaço */}
                            <Grid item xs={12}>
                                <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', marginBottom: '15px' }}>

                                    <div className="spaceicon"></div>
                                    <Typography
                                        sx={{ marginBottom: '2px', color: itensSecondary, fontWeight: 'bold', borderBottom: '1px solid #e0e0e0', paddingBottom: '10px', marginLeft: '10px' }}
                                        id="modal-title"
                                        variant="h6"
                                        component="h2"
                                    >
                                        Visualizar Modelo
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ marginBottom: '15px' }}> {/* Assegura que os itens filhos dividam o espaço */}


                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        id="outlined-basic"
                                        label="Nome do modelo"
                                        variant="outlined"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}


                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        labelId="tipo_canal"
                                        id="tipo_canal"
                                        label="Tipo de Canal"
                                        disabled={true}
                                        defaultValue={typeChannel}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>


                        <Grid container spacing={2} sx={{ marginBottom: '15px' }}> {/* Assegura que os itens filhos dividam o espaço */}


                            {
                                typeChannel === 'WhatsApp' && (
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="Tipo de selo">Selo Qualidade</InputLabel>
                                            <Select
                                                labelId="tipo_selo"
                                                id="tipo_selo"
                                                label="Tipo de selo"
                                                value={seloQualidade}
                                                onChange={(e) => setSeloQualidade(e.target.value)}
                                            >
                                                <MenuItem value="codeChat">Normal</MenuItem>
                                                <MenuItem value="whatsAppOfficial">Bussiness</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>


                                )}

                            {typeChannel === 'WhatsApp' && (



                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="Tipo de selo">Tipo de envio</InputLabel>
                                        <Select
                                            labelId="tipe_message"
                                            id="tipe_message"
                                            label="Tipo de selo"
                                            value={typeMessage}
                                            onChange={(e) => setTypeMessage(e.target.value)}

                                        >
                                            <MenuItem value="avisos">Avisos</MenuItem>
                                            <MenuItem value="inadimplencia">Inadimplência</MenuItem>
                                            <MenuItem value="atraso">Atrasos</MenuItem>
                                            <MenuItem value="marketing">Marketing</MenuItem>
                                            <MenuItem value="fatura">Faturas</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                            ) || typeChannel === 'E-mail' && (

                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="Anexar Documento">Anexar Documento</InputLabel>
                                        <Select
                                            labelId="anexar_documento"
                                            id="anexar_documento"
                                            label="Anexar Documento"
                                        >
                                            <MenuItem value="SIM">Sim</MenuItem>
                                            <MenuItem value="NAO">Não</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                            )



                            }



                            {typeChannel === 'E-mail' && (

                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="Estilo Doc">Estilo Doc</InputLabel>
                                        <Select
                                            labelId="estilo_doc"
                                            id="estilo_doc"
                                            label="Estilo Doc"
                                        >
                                            <MenuItem value="1">Estilo 1</MenuItem>
                                            <MenuItem value="2">Estilo 2</MenuItem>
                                            <MenuItem value="3">Estilo 3</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                            )}


                        </Grid>





                        <Grid container spacing={2} sx={{ marginBottom: '15px' }}> {/* Assegura que os itens filhos dividam o espaço */}


                            <Grid item xs={typeChannel == 'WhatsApp' ? 6 : 12}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Mensagens"
                                        fullWidth
                                        multiline
                                        rows={typeChannel === 'WhatsApp' ? 8 : 10}
                                        variant="outlined"
                                        value={messageTemporary}
                                        onChange={(e) => setMessageTemporary(e.target.value)}
                                    />
                                </FormControl>
                            </Grid>

                            {
                                typeChannel === 'WhatsApp' && (

                                    <Grid item xs={6}>
                                        <Dropzone
                                            onDrop={handleFileChange}
                                            style={{
                                                border: '2px solid #cccccc',
                                                padding: '20px',
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                                marginTop: '10px',
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div
                                                    {...getRootProps()}
                                                    style={{
                                                        border: '2px solid rgb(230 230 230)',
                                                        padding: '20px',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                        height: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <input {...getInputProps()} />
                                                    {selectedFile && isPreviewVisible ? (
                                                        <div>
                                                            {selectedFile.file.type.startsWith('image/') && (
                                                                <div>
                                                                    <img
                                                                        style={{ width: '70px', height: '70px' }}
                                                                        src={selectedFile.base64String}
                                                                        alt="Preview"
                                                                    />
                                                                    <p>{selectedFile.file.name.length > 15 ? `${selectedFile.file.name.substring(0, 15)}...` : selectedFile.file.name}</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <CloudUploadIcon style={{ fontSize: '64px', color: itensSecondary }} />

                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </Dropzone>
                                    </Grid>


                                )}
                        </Grid>



                        <Grid container spacing={1} sx={{ marginBottom: '15px' }}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Button onClick={toggleAccordionVisibility} style={{ border: `1px solid ${itensSecondary}`, color: itensSecondary, width: '100%', padding: '10px' }}>
                                        {isVisible ? "Esconder Variáveis do Modelo" : "Adicionar Variáveis ao Modelo"}

                                    </Button>
                                    {isVisible && Object.entries(variablesOfTemplate).map(([category, values]) => (
                                        <Accordion key={category} expanded={expanded === category} onChange={handleChangeAccordion(category)}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography>{category}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {values.length > 0 ? (
                                                    values.map((value) => (
                                                        <MenuItem key={value} onClick={() => addVariableInTemporayMessage(value)} style={{ color: fontSecondary }}>
                                                            {value}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <Typography>Não foi possível encontrar variáveis.</Typography>
                                                )}
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </FormControl>
                            </Grid>
                        </Grid>

                        {
                            typeChannel === 'WhatsApp' && (

                                <Grid container spacing={1} sx={{ marginBottom: '15px' }}> {/* Assegura que os itens filhos dividam o espaço */}
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: '#fff', border: `1px solid ${itensSecondary}`, color: itensSecondary, width: '100%', padding: '10px' }}
                                            onClick={() => setButtonAddMessage(true)}
                                            //OCULTA O BOTÃO

                                            disabled={typeChannel === 'E-mail' || typeChannel === 'SMS'}
                                        >
                                            Adicionar Mensagem
                                        </Button>
                                    </Grid>
                                </Grid>

                            )

                        }


                        <Grid container spacing={1} sx={{ marginBottom: '15px' }}> {/* Assegura que os itens filhos dividam o espaço */}
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    style={{ border: `1px solid ${itensSecondary}`, background: itensSecondary, color: '#fff', width: '100%', padding: '10px' }}
                                    onClick={() => saveTemplate()}
                                >
                                    Salvar Modelo
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} md={6}>

                        <Grid container spacing={1} sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}> {/* Vertically and horizontally centers the content */}
                            <Grid item xs={12} md={6} sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> {/* Ensures full height and centers content */}
                                {/* Component that needs to be centered */}
                                <div style={{ width: '100%', height: '100%' }}> {/* Optional wrapper to help with styling */}
                                    <ViewWhatsAppScreen {...viewWhatsAppScreenProps} handleRemoveMessage={handleRemoveMessage} style={{ height: '100%', width: '100%' }} />
                                </div>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>
        </Box >
    );
}

export default TemplateView;