// Desc: This file contains the configuration page for the WhatsApp channel
// Update: 05/08/2024

// React Components and DOM Elements
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Material UI Components 
import Box from '@mui/material/Box';

// Components and functions from the project
import { ChannelConfig } from '../../api/backend/Channels/ChannelConfig';
import { GetInfoRedis } from '../../api/backend/Redis/GetInfoRedis';
import { isAuthenticated, encrypt, decrypted } from '../../authMiddleware'; 

import RenderWhatsAppBusiness from './ChannelRenderWhatsAppBusiness';
import RenderWhatsAppNormal from './ChannelRenderWhatsAppNormal';

import Footer from '../../components/Footer/Footer';

function ChannelPageConfig() {

    // Authentication Context Functions
    const { authenticated } = isAuthenticated();
    const [userInfo, setUserInfo] = useState({});

    // State Variables
    const [autoReply, setAutoReply] = useState('');
    const [key, setKey] = useState('');  
    const [defaultMaxSendTime, setDefaultMaxSendTime] = useState(new Date()); 
    const [defaultMinSendTime, setDefaultMinSendTime] = useState(new Date()); 
    const location = useLocation();
    const [openModalRequest, setOpenModalRequest] = useState(false);
    const [autoRemoveOption, setAutoRemoveOption] = useState(false); 
    const [autoReplyOption, setAutoReplyOption] = useState(false);  
    const [saveLoading, setSaveLoading] = useState(false); 
    const selectedItem = location.state ? location.state.selectedItem : null;
    const [successSave, setSuccessSave] = useState(null);  
    const [type, setType] = useState(''); 
    const [minSendTime, setMinSendTime] = useState(new Date());
    const [maxSendTime, setMaxSendTime] = useState(new Date());

    const [viewWhatsAppScreenProps, setViewWhatsAppScreenProps] = useState({
        type: 'whatsapp',
        messages: {
            messages: []
        }
    });

    // Effect verifying if the user is authenticated
    useEffect(() => {
        if (authenticated) {
            const { decryptedData } = isAuthenticated();
            setUserInfo(decryptedData);
        }
    }, []);

    // Effect to fetch data
    useEffect(() => {
        if (userInfo['UUID']) {
            fetchData();
        }
    }, [userInfo])

    // Reset the success message and loading state
    useEffect(() => {
        setSuccessSave(null);
        setSaveLoading(false);
    }, [autoReply, type, defaultMaxSendTime, defaultMinSendTime, autoReplyOption, autoRemoveOption])

    // Effect to update the messages
    useEffect(() => {
        const commonMessages = [
            { id: 1, type: 'whatsapp', message: 'Ola, Preciso de ajuda!', fromMe: false, disabledRemove: true },
        ];

        const additionalMessages = [];

        if (autoReplyOption) {
            additionalMessages.push({ id: 2, type: 'whatsapp', message: `${autoReply}`, fromMe: true, disabledRemove: false, options: () => setAutoReplyOption(!autoReplyOption), config: true });
        }
        if (autoRemoveOption) {
            additionalMessages.push({ id: 3, type: 'whatsapp', message: 'Para parar de receber alertas, envie "PARAR".', fromMe: true, disabledRemove: false, options: () => setAutoRemoveOption(!autoRemoveOption), config: true });
            additionalMessages.push({ id: 4, type: 'whatsapp', message: 'PARAR', fromMe: false, disabledRemove: true });
            additionalMessages.push({ id: 5, type: 'whatsapp', message: 'Você não receberá mais alertas.', fromMe: true, disabledRemove: true });

        }

        setViewWhatsAppScreenProps((prevProps) => ({
            ...prevProps,
            messages: {
                messages: [...commonMessages, ...additionalMessages],
            },
        }));

    }, [autoReply, autoRemoveOption, autoReplyOption]);

    // Function to fetch data
    const fetchData = async () => {
 
        if (!selectedItem.instance_name) {
            return;
        }
        try {
            let info = { 'key': userInfo['UUID'] + ':hash_session:' + selectedItem.instance_name, 'userId': userInfo['EMAIL'], 'token': userInfo['TOKEN'] }
            info = JSON.stringify(info);

            const encryptedData = encrypt(info, userInfo['IV']);
            const serversData = await GetInfoRedis({ data: encryptedData, iv: userInfo['IV'] });
            const decryptedData = decrypted(serversData[process.env.REACT_APP_RECEIVED_CRYPTO]);
            const resultJson = JSON.parse(decryptedData);

            setAutoReply(resultJson.chave.config.autoReplyText);   
            setKey(resultJson.chave);
            setType(resultJson.chave.config.type);
            const maxHour = resultJson.chave.config.maxHour.split(':');
            const minHour = resultJson.chave.config.minHour.split(':');
            const maxHourDate = new Date();
            maxHourDate.setHours(maxHour[0]);
            maxHourDate.setMinutes(maxHour[1]);
            const minHourDate = new Date();
            minHourDate.setHours(minHour[0]);
            minHourDate.setMinutes(minHour[1]);
            setDefaultMaxSendTime(maxHourDate);
            setDefaultMinSendTime(minHourDate);
            setAutoReplyOption(resultJson.chave.config.autoReplyOption);   
            setAutoRemoveOption(resultJson.chave.config.autoRemoveOption);   
        } catch (error) {
            console.error('Error fetching data from API:', error);
        }
    };

    // Function to save the configuration
    const saveConfig = async () => {
        setSaveLoading(true);
        try {

            let autoReplyTemp = autoReply;
            const finalAutoReplyOption = autoReplyTemp.replace(/\n/g, '\\n');

            setKey(key => ({
                ...key,
                config: {
                    ...key.config,
                    autoReplyText: finalAutoReplyOption,  
                    type: type,
                    maxHour: maxSendTime.getHours() + ':' + maxSendTime.getMinutes(),
                    minHour: minSendTime.getHours() + ':' + minSendTime.getMinutes(),
                    autoReplyOption: autoReplyOption, 
                    autoRemoveOption: autoRemoveOption  
                }
            }));

            let obj = {
                ...key,
                config: {
                    ...key.config,
                    autoReplyText: autoReply, 
                    type: type,
                    maxHour: maxSendTime.getHours() + ':' + maxSendTime.getMinutes(),
                    minHour: minSendTime.getHours() + ':' + minSendTime.getMinutes(),
                    autoReplyOption: autoReplyOption, 
                    autoRemoveOption: autoRemoveOption 
                }
            }

            let info = { 'key': userInfo['UUID'] + ':hash_session:' + selectedItem.instance_name, 'value': obj, 'uuid_account': userInfo['UUID'], 'userId': userInfo['EMAIL'], tokenUser: userInfo['TOKEN'] }
            info = JSON.stringify(info);

            const encryptedData = encrypt(info, userInfo['IV']);
            const serversData = await ChannelConfig({ data: encryptedData, iv: userInfo['IV'] });

            if (serversData.status === 'OK') {
                setSuccessSave(true);
                setSaveLoading(false);
            } else {
                setSaveLoading(false);
                setSuccessSave(false);
            }

        } catch (error) {
            console.error('Error obtaining data from API:', error);
        }
    }

    // Function to close the request modal
    const handleCloseModalRequest = () => {
        setOpenModalRequest(false);
    }

    // Function to open the request modal
    const handleOpenModalRequest = () => {
        setOpenModalRequest(true);
    }

    // Returns null if there is no selected item
    if (!selectedItem) {
        return null;
    }

    // Render the page
    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                bgcolor: 'transparent',
                overflow: 'auto',
            }}
        >
            {selectedItem.integration_icons === 'whatsAppOfficial' ? (
                <RenderWhatsAppBusiness
                    selectedItem={selectedItem}
                    type={type}
                    setType={setType}
                    defaultMinSendTime={defaultMinSendTime}
                    setMinSendTime={setMinSendTime}
                    defaultMaxSendTime={defaultMaxSendTime}
                    setMaxSendTime={setMaxSendTime}
                    autoReplyOption={autoReplyOption}
                    setAutoReplyOption={setAutoReplyOption}
                    autoRemoveOption={autoRemoveOption}
                    setAutoRemoveOption={setAutoRemoveOption}
                    autoReply={autoReply}
                    setAutoReply={setAutoReply}
                    handleOpenModalRequest={handleOpenModalRequest}
                    saveConfig={saveConfig}
                    saveLoading={saveLoading}
                    successSave={successSave}
                    handleCloseModalRequest={handleCloseModalRequest}
                    openModalRequest={openModalRequest}
                    viewWhatsAppScreenProps={viewWhatsAppScreenProps}
                />
            ) : (
                <RenderWhatsAppNormal
                    selectedItem={selectedItem}
                    type={type}
                    setType={setType}
                    defaultMinSendTime={defaultMinSendTime}
                    setMinSendTime={setMinSendTime}
                    defaultMaxSendTime={defaultMaxSendTime}
                    setMaxSendTime={setMaxSendTime}
                    autoReplyOption={autoReplyOption}
                    setAutoReplyOption={setAutoReplyOption}
                    autoRemoveOption={autoRemoveOption}
                    setAutoRemoveOption={setAutoRemoveOption}
                    autoReply={autoReply}
                    setAutoReply={setAutoReply}
                    handleOpenModalRequest={handleOpenModalRequest}
                    saveConfig={saveConfig}
                    saveLoading={saveLoading}
                    successSave={successSave}
                    handleCloseModalRequest={handleCloseModalRequest}
                    openModalRequest={openModalRequest}
                    viewWhatsAppScreenProps={viewWhatsAppScreenProps}
                />
            )}
            <Box sx={{ display: { md: 'flex', lg: 'inherit' }, alignItems: 'start', position: { lg: 'absolute' }, bottom: { lg: 0 }, padding: { xs: '20px 0 0 64px', lg: '0 0 0 64px' } }}>
                <Footer />
            </Box>
        </Box>
    );
};

export default ChannelPageConfig;
