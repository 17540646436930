// Desc: Component render the header of the notification
// Update: 12/08/2024

// React Components and DOM Elements
import React from 'react';

// Material UI Components
import { Wifi as WifiIcon, NetworkCell as NetworkCellIcon, BatteryFull as BatteryFullIcon } from '@mui/icons-material';

// Components and functions from the project
import ThemeColors from '../ThemeColors/ThemeColors';

// Render the header of the notification
const RenderNotificationHeader = () => {


  return (
    <div style={{ background: ThemeColors.purple400 , padding: '0 8px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '48px',marginLeft: '22px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <WifiIcon style={{ marginRight: '8px', color: '#fff', fontSize: '14px' }} />
          <NetworkCellIcon style={{ marginRight: '8px', color: '#fff', fontSize: '14px' }} />
          <BatteryFullIcon style={{ marginRight: '8px', color: '#fff', fontSize: '14px' }} />
        </div>
      </div>
    </div>
  );
};

export default RenderNotificationHeader;
