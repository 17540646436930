// Desc: This component is responsible for rendering the sidebar of the application.
// Update: 12/08/2024

// React Components and DOM Elements
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

// Material UI Components
import { ListItem, ListItemIcon, ListItemText, Typography, IconButton, Menu, MenuItem, CircularProgress } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Grid } from '@mui/material';
import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import SmartButtonOutlinedIcon from '@mui/icons-material/SmartButtonOutlined';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import TextFormatOutlinedIcon from '@mui/icons-material/TextFormatOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import ErrorIcon from '@mui/icons-material/Error';

// Functions and Styles from the project
import ThemeColors from '../ThemeColors/ThemeColors';
import Logo from '../../assets/Icons/components/Logo';
import LogoSimple from '../../assets/Icons/components/LogoSimple';


function ExpansiveButton({ isCollapsed, handleCollapse, itemsSecondary }) {

    function hexToRGBA(hex, alpha) {
        const r = parseInt(hex.substring(1, 3), 16);
        const g = parseInt(hex.substring(3, 5), 16);
        const b = parseInt(hex.substring(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }


    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'fixed',
                marginLeft: isCollapsed ? '85px' : '245px',
                backgroundColor: hexToRGBA(ThemeColors.gray40, 0.3),
                marginTop: '17px',
                borderRadius: '12px',
                width: '35px',
                height: '35px',
                cursor: 'pointer',
                transition: 'margin-left 0.3s ease'
            }}

            onClick={handleCollapse}
        >
            {isCollapsed ? <DoubleArrowOutlinedIcon style={{ color: ThemeColors.purple400 }}
            // muda a cor do ícone ao passar o mouse


            /> : <DoubleArrowOutlinedIcon style={{ color: ThemeColors.purple400, transform: 'rotate(180deg)' }}


            />}
        </div>
    );
}

function getLocation(fullLocation) {
    // Verifica se fullLocation está definido
    if (fullLocation) {

        console.log('fullLocation:', fullLocation)
        // Obtém a primeira parte da URL após a barra
        const locationName = fullLocation.split('/')[1];

        console.log('locationName:', locationName)
        return '/' + locationName;
    } else {
        return '';
    }
}

// Render the navigation menu
function NavigationMenu({ isCollapsed, location, itemsSecondary }) {

    const linksInitial = [
        { path: "/styles", icon: <PaletteOutlinedIcon />, text: "Cores" },
        { path: "/typography", icon: <TextFormatOutlinedIcon />, text: "Tipografia" },
        { path: "/iconography", icon: <LibraryAddOutlinedIcon />, text: "Iconografia" },
        { path: "/emojis", icon: <EmojiEmotionsOutlinedIcon />, text: "Emojis" },
        { path: "/buttons", icon: <IndeterminateCheckBoxOutlinedIcon />, text: "Botões" },
        { path: "/labels", icon: <SmartButtonOutlinedIcon />, text: "Labels" },
        { path: "/tables", icon: <BackupTableOutlinedIcon />, text: "Tabelas"},
        { path: "/ilustrations", icon: <AppsOutlinedIcon />, text: "Ilustrações" },
        { path: "/alerts", icon: <ErrorIcon />, text: "Alertas" },

        

    ];

    const linksFinal = [
        //{ path: "/support", icon: <ContactSupportOutlinedIcon />, text: "Suporte" },
        //{ path: "/Notifications", icon: <NotificationsOutlinedIcon />, text: "Notificações" },
        //{ path: "/Settings", icon: <SettingsOutlinedIcon />, text: "Configurações" },
    ];

    return (
        <ul style={{ marginBottom: isCollapsed ? '40%' : '10%', margin: '20px', alignContent: 'center' }}>

            <ListItemIcon style={{ minWidth: 'auto', marginRight: '10px', marginBottom: '50px' }}>
                {isCollapsed ? <LogoSimple /> : <Logo />}
            </ListItemIcon>
            {linksInitial.map(link => (
                <ListItem
                    key={link.path}
                    style={{ padding: '10px', background: getLocation(location.pathname) === link.path ? '#43494f' : 'none', marginBottom: link.path === '/campaign' ? '40px' : '5px', borderRadius: '5px' }}
                    component={Link}
                    to={link.path}
                    disablePadding
                >
                    <ListItemIcon style={{ minWidth: 'auto', marginRight: '10px' }}>
                        {React.cloneElement(link.icon, {
                            style: {
                                color: getLocation(location.pathname) === link.path ? itemsSecondary : '#919191',
                            },
                        })}
                    </ListItemIcon>
                    {isCollapsed ? null : (
                        <ListItemText disableTypography>
                            <Typography style={{
                                fontWeight: getLocation(location.pathname) === link.path ? 500 : 400,
                                color: getLocation(location.pathname) === link.path ? '#fff' : '#919191',
                                fontFamily: 'Satoshi',
                                fontSize: '14px',
                            }}>{link.text}</Typography>
                        </ListItemText>
                    )}
                </ListItem>
            ))}

            {linksFinal.map(link => (
                <ListItem
                    key={link.path}
                    style={{
                        padding: '10px', background: getLocation(location.pathname) === link.path ? '#43494f' : 'none',
                        borderRadius: '12px'
                    }}
                    component={Link}
                    to={link.path}
                    disablePadding
                >
                    <ListItemIcon style={{ minWidth: 'auto', marginRight: '10px' }}>
                        {React.cloneElement(link.icon, 
                            {
                                style: {
                                    color: getLocation(location.pathname) === link.path ? itemsSecondary : '#919191',
                                },
                            }
                        )}
                    </ListItemIcon>
                    {isCollapsed ? null : (
                        <ListItemText disableTypography>
                            <Typography variant="caption" style={{
                                fontWeight: getLocation(location.pathname) === link.path ? 500 : 400,
                                color: getLocation(location.pathname) === link.path ? '#fff' : '#919191'
                            }}>{link.text}</Typography>
                        </ListItemText>
                    )}
                </ListItem>
            ))}
        </ul>
    );

}

// Render the user avatar and the logout button
function UserEspace({ loading, userAvatar, handleClick, isCollapsed, anchorEl, handleClose }) {
    return (
        <ListItem className="avatar-section" style={{ position: 'absolute', bottom: 0, padding: '10px', width: '100%', background: isCollapsed ? 'none' : '#43494f', }}>
            {loading ? (
                <CircularProgress style={{
                    width: '40px',
                    height: '40px'
                }} thickness={1} />
            ) : (
                <div >
                    <div style={{
                        width: '40px',
                        height: '40px',
                        transform: isCollapsed ? 'scale(1)' : 'scale(1)',
                        marginLeft: isCollapsed ? '10px' : '0px',
                        transition: 'transform 0.3s ease',
                        // alinha o avatar ao centro
                        marginLeft: '18px',

                    }}>
                        <div style={{
                            boxShadow: '0 0 10px 5px rgba(0, 0, 255, 0.1)',
                            borderRadius: '50%',
                            overflow: 'hidden',
                            width: '100%',
                            height: '100%',

                        }}>
                            <img
                                key={userAvatar}
                                src={`${userAvatar}`}
                                style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                                alt="Avatar"
                                aria-haspopup="true"
                                onClick={isCollapsed ? handleClick : null}

                            />
                        </div>
                        <div style={{
                            position: 'absolute',
                            top: '3px',
                            right: '3px',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            backgroundColor: '#b5ffc1',
                            border: '2px solid #b5ffc1',
                        }} />
                    </div>

                </div>
            )}

            {!isCollapsed && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '15px' }}>

                    <div>
                        <p style={{ fontWeight: 400, color: '#fff', fontSize: '15px', fontFamily: '"Open Sans", sans-serif', marginBottom: '5px' ,
                           whiteSpace: 'nowrap', // Adicionado para evitar quebras de linha
                           overflow: 'hidden', // Esconde o texto que excede o container
                           textOverflow: 'ellipsis' // Adiciona "..." se o texto for cortado
                        }}>
                            Equipe 123Zap
                        </p>
                        <p style={{ fontWeight: 500, color: '#919191', fontSize: '12px', fontFamily: '"Open Sans", sans-serif' }}>
                            teste@gmail.com
                        </p>
                    </div>
                    <IconButton
                        style={{ padding: '0', marginLeft: '50px'  }}
                        onClick={handleClick}
                    >
                        <MoreVertIcon style={{ color: '#fff' }} />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        sx={
                            // retira o box-shadow do menu
                            {
                                '& .MuiPaper-root': {
                                    boxShadow: 'none',
                                    borderRadius: '8px',
                                    border: '1px solid #DBDBDB',
                                    // alinha o menu a esquerda do ícone
                                    marginLeft: '50px',
                                }

                            }
                        }
                    >
                        <MenuItem>
                            <Grid container justifyContent="left" alignItems="center" spacing={1}>
                                <Grid item>
                                    <SettingsOutlinedIcon style={{ color: 'gray', fontSize: '15px', marginTop: '8px' }} />
                                </Grid>
                                <Grid item style={{ paddingLeft: '20px' }}>
                                    Configurações
                                </Grid>
                            </Grid>
                        </MenuItem>
                        <MenuItem>
                            <Grid container justifyContent="left" alignItems="center" spacing={1}>
                                <Grid item>
                                    <LogoutOutlinedIcon style={{ color: 'gray', fontSize: '15px', marginTop: '8px' }} />
                                </Grid>
                                <Grid item style={{ paddingLeft: '20px' }}>
                                    Sair
                                </Grid>
                            </Grid>
                        </MenuItem>

                    </Menu>

                </div>
            )}

        </ListItem>
    );
}

// Render Your Component
export default function SidebarFake() {
    // Consts and State variables
    const location = useLocation();

    const [userAvatar, setUserAvatar] = useState(null);

    const [isCollapsed, setIsCollapsed] = React.useState(false);
    const [loading, setLoading] = useState(true);
    const [isAvatarLoaded, setIsAvatarLoaded] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const path = getLocation();

    // Effect responsible for collapsing the sidebar
    useEffect(() => {
        const mainContent = document.querySelector('.MainContent');
        if (mainContent) {
            if (isCollapsed) {
                mainContent.classList.add('collapsed');
            } else {
                mainContent.classList.remove('collapsed');
            }
        }
    }, [isCollapsed]);

    useEffect(() => {

        // Aguarda 10 segundos para simular o carregamento do avatar e seta o estado de loading para false
        setTimeout(() => {
            fetchUserAvatar('123Zap');
            setLoading(false);
        }, 2000);


    }, []);


    const fetchUserAvatar = async (userHash) => {
            setUserAvatar('https://cdn-icons-png.flaticon.com/512/10542/10542486.png');
    };

    // Render the sidebar
    return (
        <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
            <ExpansiveButton isCollapsed={isCollapsed} handleCollapse={() => setIsCollapsed(!isCollapsed)} itemsSecondary={ThemeColors.gray10} />
            <NavigationMenu isCollapsed={isCollapsed} location={location} itemsSecondary={ThemeColors.purple400} />
            <UserEspace loading={loading} userAvatar={userAvatar} handleClick={(event) => setAnchorEl(event.currentTarget)} isCollapsed={isCollapsed}  />
        </div>
    );
}

