// Description: This component render a showcase of the site's icons
// Update: 12/08/2024

// React Components and DOM Elements
import React from "react";

// Material UI Components
import Grid from '@mui/material/Grid';

// Components and functions from the project
import StatusIcon from "../StatusLabel/StatusIcon";
import ThemeColors from "../ThemeColors/ThemeColors";

// Render Your Component
const Iconography = () => {

    const iconsName = [
        "Email",
        "SMS",
        "WhatsApp",
        "whatsAppOfficial",
        "ArrowBottom",
        "ArrowTop",
        "Delete",
        "Edit",
        "Duplicate",
        "Help",
        "Plus",
        "Search",
        "Campanha",
        "Suporte",
        "Sheets",
        "Palette",
        "Table",
        "Label",
        "Button",
        "Emoji",
        "Letter",
        "Alert"
    ];

    const emojisName1 = () => {
        let emojis = [];
        for (let i = 1; i <= 12; i++) {

            let number = i.toString().padStart(2, '0');
            emojis.push(`emoji${number}`);
        }
        return emojis;
    }
    
    const emojisName2 = () => {
        let emojis = [];
        for (let i = 12; i <= 22; i++) {

            let number = i.toString().padStart(2, '0');
            emojis.push(`emoji${number}`);
        }
        return emojis;
    }
    
    const emojisName3 = () => {
        let emojis = [];
        for (let i = 22; i <= 30; i++) {

            let number = i.toString().padStart(2, '0');
            emojis.push(`emoji${number}`);
        }
        return emojis;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
            < Grid container spacing={2} sx={{ marginTop: '5px', marginBottom: '10px', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                {iconsName.map((iconName, index) => (
                    <Grid item key={index}>
                        <StatusIcon status={iconName} width={35} height={35} />
                    </Grid>
                ))}
            </Grid>
            < Grid container spacing={2} sx={{ marginTop: '5px', marginBottom: '10px', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                {iconsName.map((iconName, index) => (
                    <Grid item key={index}>
                        <StatusIcon status={iconName} width={35} height={35} color={ThemeColors.purple200} />
                    </Grid>
                ))}
            </Grid>
            < Grid container spacing={2} sx={{ marginTop: '5px', marginBottom: '10px', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                {iconsName.map((iconName, index) => (
                    <Grid item key={index}>
                        <StatusIcon status={iconName} width={35} height={35} color={ThemeColors.gray50} />
                    </Grid>
                ))}
            </Grid>
            < Grid container spacing={2} sx={{ marginTop: '5px', marginBottom: '10px', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                {iconsName.map((iconName, index) => (
                    <Grid item key={index}>
                        <StatusIcon status={iconName} width={35} height={35} color={ThemeColors.gray20} />
                    </Grid>
                ))}
            </Grid>

            < Grid container spacing={0} sx={{ marginTop: '5px', marginBottom: '10px', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                {emojisName1().map((iconName, index) => (
                    <Grid item key={index} sx={{ p: 0, m: 0 }}>
                        <StatusIcon status={iconName} width={30} height={30} color={ThemeColors.purple200} />
                    </Grid>
                ))}
            </Grid>
            < Grid container spacing={0} sx={{ marginTop: '5px', marginBottom: '10px', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                {emojisName2().map((iconName, index) => (
                    <Grid item key={index} sx={{ p: 0, m: 0 }}>
                        <StatusIcon status={iconName} width={30} height={30} />
                    </Grid>
                ))}
            </Grid>

            < Grid container spacing={0} sx={{ marginTop: '5px', marginBottom: '10px', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                {emojisName3().map((iconName, index) => (
                    <Grid item key={index} sx={{ p: 0, m: 0 }}>
                        <StatusIcon status={iconName} width={30} height={30} />
                    </Grid>
                ))}
            </Grid>
            < Grid container spacing={0} sx={{ marginTop: '5px', marginBottom: '10px', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                {emojisName3().map((iconName, index) => (
                    <Grid item key={index} sx={{ p: 0, m: 0 }}>
                        <StatusIcon status={iconName} width={30} height={30} color={ThemeColors.gray20} />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export default Iconography;