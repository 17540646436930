import React from "react";

const Emoji07 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.9 208.9"
    width={props.width || '85'} height={props.height || '85'}
  >
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.9,0H55C24.63,0,0,24.62,0,55v98.9c0,30.37,24.63,55,55,55h98.9c30.38,0,55-24.63,55-55V55c0-30.38-24.62-55-55-55ZM49.67,86.2c0-7.65,5.49-13.86,12.26-13.86s12.27,6.21,12.27,13.86-5.49,13.86-12.27,13.86-12.26-6.2-12.26-13.86ZM80.15,136.56h-8.97c-3.95,0-7.23-4.06-7.9-9.42h16.87v9.42ZM80.15,123.14h-16.87c.67-5.36,3.95-9.43,7.9-9.43h8.97v9.43ZM101.96,136.56h-16.81v-9.42h16.81v9.42ZM101.96,123.14h-16.81v-9.43h16.81v9.43ZM123.76,136.56h-16.8v-9.42h16.8v9.42ZM123.76,123.14h-16.8v-9.43h16.8v9.43ZM137.73,136.56h-8.97v-9.42h16.88c-.67,5.36-3.96,9.42-7.91,9.42ZM128.76,123.14v-9.43h8.97c3.95,0,7.24,4.07,7.91,9.43h-16.88ZM146.97,100.06c-6.77,0-12.26-6.2-12.26-13.86s5.49-13.86,12.26-13.86,12.26,6.21,12.26,13.86-5.49,13.86-12.26,13.86Z"
        />
        <path
          className="cls-2"
          d="M61.93,100.06c-6.77,0-12.26-6.2-12.26-13.86s5.49-13.86,12.26-13.86,12.27,6.21,12.27,13.86-5.49,13.86-12.27,13.86Z"
        />
        <path
          className="cls-2"
          d="M71.18,113.71h8.97v9.43h-16.87c.67-5.36,3.95-9.43,7.9-9.43Z"
        />
        <path
          className="cls-2"
          d="M63.28,127.14h16.87v9.42h-8.97c-3.95,0-7.23-4.06-7.9-9.42Z"
        />
        <rect
          className="cls-2"
          x={85.15}
          y={113.71}
          width={16.81}
          height={9.43}
        />
        <rect
          className="cls-2"
          x={85.15}
          y={127.14}
          width={16.81}
          height={9.42}
        />
        <rect
          className="cls-2"
          x={106.96}
          y={113.71}
          width={16.8}
          height={9.43}
        />
        <rect
          className="cls-2"
          x={106.96}
          y={127.14}
          width={16.8}
          height={9.42}
        />
        <path
          className="cls-2"
          d="M128.76,127.14h16.88c-.67,5.36-3.96,9.42-7.91,9.42h-8.97v-9.42Z"
        />
        <path
          className="cls-2"
          d="M145.64,123.14h-16.88v-9.43h8.97c3.95,0,7.24,4.07,7.91,9.43Z"
        />
        <ellipse
          className="cls-2"
          cx={146.97}
          cy={86.2}
          rx={12.26}
          ry={13.86}
        />
        <g>
          <g>
            <path
              className="cls-1"
              d="M74.2,86.2c0,7.66-5.49,13.86-12.27,13.86s-12.26-6.2-12.26-13.86,5.49-13.86,12.26-13.86,12.27,6.21,12.27,13.86Z"
            />
            <ellipse
              className="cls-1"
              cx={146.97}
              cy={86.2}
              rx={12.26}
              ry={13.86}
            />
          </g>
          <g>
            <g>
              <path
                className="cls-1"
                d="M80.15,113.71v9.43h-16.87c.67-5.36,3.95-9.43,7.9-9.43h8.97Z"
              />
              <path
                className="cls-1"
                d="M80.15,127.14v9.42h-8.97c-3.95,0-7.23-4.06-7.9-9.42h16.87Z"
              />
            </g>
            <g>
              <rect
                className="cls-1"
                x={85.15}
                y={113.71}
                width={16.81}
                height={9.43}
              />
              <rect
                className="cls-1"
                x={85.15}
                y={127.14}
                width={16.81}
                height={9.42}
              />
            </g>
            <g>
              <rect
                className="cls-1"
                x={106.96}
                y={113.71}
                width={16.8}
                height={9.43}
              />
              <rect
                className="cls-1"
                x={106.96}
                y={127.14}
                width={16.8}
                height={9.42}
              />
            </g>
            <g>
              <path
                className="cls-1"
                d="M145.64,127.14c-.67,5.36-3.96,9.42-7.91,9.42h-8.97v-9.42h16.88Z"
              />
              <path
                className="cls-1"
                d="M145.64,123.14h-16.88v-9.43h8.97c3.95,0,7.24,4.07,7.91,9.43Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>

); export default Emoji07