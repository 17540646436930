import React from "react";

const Emoji08= (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.9 208.9"
    width={props.width || '85'} height={props.height || '85'}
>
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.9,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.9c30.38,0,55-24.63,55-55V55c0-30.38-24.62-55-55-55ZM158.13,55.29s7.65,7.89,7.65,12.12-3.42,7.65-7.65,7.65-7.65-3.42-7.65-7.65,7.65-12.12,7.65-12.12ZM107.94,75.98s5.55,5.74,5.55,8.8-2.49,5.56-5.55,5.56-5.56-2.49-5.56-5.56,5.56-8.8,5.56-8.8ZM43.88,153.61c-3.54,0-6.4-2.86-6.4-6.4s6.4-10.15,6.4-10.15c0,0,6.41,6.61,6.41,10.15s-2.87,6.4-6.41,6.4ZM56.28,119.11c-6.77,0-12.25-6.19-12.25-13.86s5.48-13.85,12.25-13.85,12.27,6.21,12.27,13.85-5.5,13.86-12.27,13.86ZM118.7,138.51h-39.8c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.8c2.65,0,4.8,2.15,4.8,4.8s-2.15,4.8-4.8,4.8ZM141.32,119.11c-6.77,0-12.27-6.19-12.27-13.86s5.5-13.85,12.27-13.85,12.25,6.21,12.25,13.85-5.48,13.86-12.25,13.86ZM162.43,152.34c-4.97,0-9-4.02-9-8.99s9-14.26,9-14.26c0,0,8.99,9.29,8.99,14.26s-4.02,8.99-8.99,8.99Z"
        />
        <path
          className="cls-2"
          d="M43.88,137.06s6.41,6.61,6.41,10.15-2.87,6.4-6.41,6.4-6.4-2.86-6.4-6.4,6.4-10.15,6.4-10.15Z"
        />
        <path
          className="cls-2"
          d="M68.55,105.25c0,7.67-5.5,13.86-12.27,13.86s-12.25-6.19-12.25-13.86,5.48-13.85,12.25-13.85,12.27,6.21,12.27,13.85Z"
        />
        <path
          className="cls-2"
          d="M102.38,84.78c0-3.06,5.56-8.8,5.56-8.8,0,0,5.55,5.74,5.55,8.8s-2.49,5.56-5.55,5.56-5.56-2.49-5.56-5.56Z"
        />
        <path
          className="cls-2"
          d="M123.5,133.71c0,2.65-2.15,4.8-4.8,4.8h-39.8c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.8c2.65,0,4.8,2.15,4.8,4.8Z"
        />
        <path
          className="cls-2"
          d="M153.57,105.25c0,7.67-5.48,13.86-12.25,13.86s-12.27-6.19-12.27-13.86,5.5-13.85,12.27-13.85,12.25,6.21,12.25,13.85Z"
        />
        <path
          className="cls-2"
          d="M150.48,67.41c0-4.23,7.65-12.12,7.65-12.12,0,0,7.65,7.89,7.65,12.12s-3.42,7.65-7.65,7.65-7.65-3.42-7.65-7.65Z"
        />
        <path
          className="cls-2"
          d="M171.42,143.35c0,4.97-4.02,8.99-8.99,8.99s-9-4.02-9-8.99,9-14.26,9-14.26c0,0,8.99,9.29,8.99,14.26Z"
        />
        <g>
          <g>
            <path
              className="cls-1"
              d="M68.55,105.25c0,7.67-5.5,13.86-12.27,13.86s-12.25-6.19-12.25-13.86,5.48-13.85,12.25-13.85,12.27,6.21,12.27,13.85Z"
            />
            <path
              className="cls-1"
              d="M153.57,105.25c0,7.67-5.48,13.86-12.25,13.86s-12.27-6.19-12.27-13.86,5.5-13.85,12.27-13.85,12.25,6.21,12.25,13.85Z"
            />
          </g>
          <path
            className="cls-1"
            d="M165.78,67.41c0,4.23-3.42,7.65-7.65,7.65s-7.65-3.42-7.65-7.65,7.65-12.12,7.65-12.12c0,0,7.65,7.89,7.65,12.12Z"
          />
          <path
            className="cls-1"
            d="M50.29,147.21c0,3.54-2.87,6.4-6.41,6.4s-6.4-2.86-6.4-6.4,6.4-10.15,6.4-10.15c0,0,6.41,6.61,6.41,10.15Z"
          />
          <path
            className="cls-1"
            d="M171.42,143.35c0,4.97-4.02,8.99-8.99,8.99s-9-4.02-9-8.99,9-14.26,9-14.26c0,0,8.99,9.29,8.99,14.26Z"
          />
          <path
            className="cls-1"
            d="M113.49,84.78c0,3.07-2.49,5.56-5.55,5.56s-5.56-2.49-5.56-5.56,5.56-8.8,5.56-8.8c0,0,5.55,5.74,5.55,8.8Z"
          />
          <path
            className="cls-1"
            d="M123.5,133.71c0,2.65-2.15,4.8-4.8,4.8h-39.8c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.8c2.65,0,4.8,2.15,4.8,4.8Z"
          />
        </g>
      </g>
    </g>
  </svg>

); export default Emoji08