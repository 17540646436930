// Description: This component render a showcase of the Email the client will get
// Update: 12/08/2024

// React Components and DOM Elements
import React, { useState } from "react";

// Material UI Components
import { Box } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// Components and functions from the project
import StatusIcon from "../StatusLabel/StatusIcon";
import Titles from "../Titles/Titles";
import Dropzone from "react-dropzone";
import { notify } from "../../utils/utils";

// render Your Component
const EmailFormShowCase = ({
  title,
  greeting,
  message,
  titleModel,
  background,
  setBackground,
  isEmailTemplatePersonalized,
  edit = false,
}) => {
  // State variables
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  // Function to handle the file change
  const handleFileChange = (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result;
        setSelectedFile({ file, base64String });
        setBackground(base64String);
        setIsPreviewVisible(true);
      };

      reader.readAsDataURL(file);
      notify("Success", "Arquivo adicionado com sucesso");
    } else {
      console.error("Por favor, selecione apenas imagens.");
      notify("Error", "Por favor, selecione apenas imagens.");
      setSelectedFile(null);
      setIsPreviewVisible(false);
    }
  };

  return (
    <Box
      borderRadius={"12px"}
      overflow={"hidden"}
      border={"1px solid #A986FF"}
      sx={{ backgroundColor: "#fff", maxWidth: { xs: "561.200px", lg: "628px" }, minHeight: { xs: "668px", lg: "90vh" }, margin: { xs: "0 auto", lg: "0 60px 0 0" }}}
    >
      <Box
        zIndex={"2"}
        padding={"12px"}
        display={"flex"}
        gap="12px"
        alignItems={"center"}
        minWidth={"100%"}
        sx={{ backgroundColor: "#8142FC", color: "#fff" }}
      >
        <StatusIcon
          status={"E-mail"}
          height={"26px"}
          width={"26px"}
          color="#fff"
        />
        <Titles children={titleModel} color={"#fff"} type={"H4"} />
      </Box>
      <Box padding={"20px"}>
        <Box display={"flex"} flexDirection={"column"} gap="20px">
          {(isEmailTemplatePersonalized && !edit && (
            <Dropzone
              onDrop={(acceptedFiles) => handleFileChange(acceptedFiles)}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  style={{
                    textAlign: "center",
                    cursor: "pointer",
                    marginTop: "10px",
                    height: "170px",
                    background: "#E5E5E5",
                    borderRadius: "4px",
                    overflow: "hidden",
                  }}
                >
                  <input {...getInputProps()} />
                  {selectedFile && isPreviewVisible ? (
                    <div>
                      {selectedFile.file.type.startsWith("image/") && (
                        <div>
                          <img
                            style={{
                              width: "100%",
                              height: "170px",
                              objectFit: "cover",
                            }}
                            src={selectedFile.base64String}
                            alt="Preview"
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <Box
                      display={"flex"}
                      sx={{
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "#E5E5E5",
                        width: "100%",
                        height: "100%",
                        borderRadius: "4px",
                      }}
                    >
                      <CloudUploadIcon
                        style={{ fontSize: "64px", color: "#848484" }}
                      />
                      <Titles
                        children={"Clique para adicionar arquivo"}
                        color={"#848484"}
                        type={"P1"}
                      />
                    </Box>
                  )}
                </div>
              )}
            </Dropzone>
          )) ||
            (edit && isEmailTemplatePersonalized && (
              <Dropzone
                onDrop={(acceptedFiles) => handleFileChange(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      marginTop: "10px",
                      height: "170px",
                      background: "#E5E5E5",
                      borderRadius: "4px",
                      overflow: "hidden",
                    }}
                  >
                    <input {...getInputProps()} />
                      <img
                        style={{
                          width: "100%",
                          height: "170px",
                          objectFit: "cover",
                        }}
                        src={background}
                        alt="Preview"
                      />
                  </div>
                )}
              </Dropzone>
            )) ||
            (edit && !isEmailTemplatePersonalized && (
              <Box
                component={"img"}
                sx={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginTop: "10px",
                  height: "170px",
                  borderRadius: "4px",
                  overflow: "hidden",
                  width: "100%",
                  objectFit: "cover"
                }}
                src={background}
                alt="Preview"
              />
            )) ||
            (!edit && !isEmailTemplatePersonalized && (
              <Box
                component={"img"}
                sx={{
                  textAlign: "center",
                  cursor: "pointer",
                  marginTop: "10px",
                  height: "170px",
                  borderRadius: "4px",
                  overflow: "hidden",
                  width: "100%",
                  objectFit: "cover"
                }}
                src={background}
                alt="Preview"
              />
            ))}
          <Box display="flex" flexDirection={"column"} gap="10px">
            <div style={{ marginBottom: "5px" }}>
              <Titles color={"#8142FC"} type={"H4"} children={title} />
            </div>
            <Titles color={"#848484"} type={"P1"} children={greeting} />
            <Titles color={"#848484"} type={"P1"} children={message} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EmailFormShowCase;
