import React from "react";

const Emoji = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.9 208.9"
    width={props.width || '85'} height={props.height || '85'}
>
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.9,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.9c30.37,0,55-24.63,55-55V55c0-30.38-24.63-55-55-55ZM76.02,91.55c-.95,1.12-2.3,1.7-3.67,1.7-1.09,0-2.19-.38-3.1-1.14l-5.7-4.84-5.7,4.84c-2.03,1.71-5.05,1.46-6.77-.56-1.71-2.02-1.46-5.05.56-6.76l8.81-7.47c1.79-1.52,4.41-1.52,6.2,0l8.81,7.47c2.02,1.71,2.27,4.74.56,6.76ZM127.48,115.44c0,.16-.02.33-.04.49-1.78,11.6-12.83,16.79-22.99,16.79s-21.21-5.19-23-16.79c-.02-.16-.03-.33-.03-.49v-9.74c0-1.77,1.43-3.21,3.2-3.21h39.65c1.77,0,3.21,1.44,3.21,3.21v9.74ZM157.81,91.55c-.95,1.12-2.3,1.7-3.66,1.7-1.1,0-2.2-.38-3.1-1.14l-5.71-4.84-5.7,4.84c-2.02,1.71-5.05,1.46-6.76-.56-1.72-2.02-1.47-5.05.55-6.76l8.81-7.47c1.79-1.52,4.41-1.52,6.21,0l8.8,7.47c2.03,1.71,2.27,4.74.56,6.76Z"
        />
        <path
          className="cls-2"
          d="M75.46,84.79c2.02,1.71,2.27,4.74.56,6.76-.95,1.12-2.3,1.7-3.67,1.7-1.09,0-2.19-.38-3.1-1.14l-5.7-4.84-5.7,4.84c-2.03,1.71-5.05,1.46-6.77-.56-1.71-2.02-1.46-5.05.56-6.76l8.81-7.47c1.79-1.52,4.41-1.52,6.2,0l8.81,7.47Z"
        />
        <path
          className="cls-2"
          d="M127.48,105.7v9.74c0,.16-.02.33-.04.49-1.78,11.6-12.83,16.79-22.99,16.79s-21.21-5.19-23-16.79c-.02-.16-.03-.33-.03-.49v-9.74c0-1.77,1.43-3.21,3.2-3.21h39.65c1.77,0,3.21,1.44,3.21,3.21Z"
        />
        <path
          className="cls-2"
          d="M157.81,91.55c-.95,1.12-2.3,1.7-3.66,1.7-1.1,0-2.2-.38-3.1-1.14l-5.71-4.84-5.7,4.84c-2.02,1.71-5.05,1.46-6.76-.56-1.72-2.02-1.47-5.05.55-6.76l8.81-7.47c1.79-1.52,4.41-1.52,6.21,0l8.8,7.47c2.03,1.71,2.27,4.74.56,6.76Z"
        />
        <g>
          <path
            className="cls-1"
            d="M127.48,105.7v9.74c0,.16-.02.33-.04.49-1.78,11.6-12.83,16.79-22.99,16.79s-21.21-5.19-23-16.79c-.02-.16-.03-.33-.03-.49v-9.74c0-1.77,1.43-3.21,3.2-3.21h39.65c1.77,0,3.21,1.44,3.21,3.21Z"
          />
          <g>
            <path
              className="cls-1"
              d="M76.02,91.55c-.95,1.12-2.3,1.7-3.67,1.7-1.09,0-2.19-.38-3.1-1.14l-5.7-4.84-5.7,4.84c-2.03,1.71-5.05,1.46-6.77-.56-1.71-2.02-1.46-5.05.56-6.76l8.81-7.47c1.79-1.52,4.41-1.52,6.2,0l8.81,7.47c2.02,1.71,2.27,4.74.56,6.76Z"
            />
            <path
              className="cls-1"
              d="M157.81,91.55c-.95,1.12-2.3,1.7-3.66,1.7-1.1,0-2.2-.38-3.1-1.14l-5.71-4.84-5.7,4.84c-2.02,1.71-5.05,1.46-6.76-.56-1.72-2.02-1.47-5.05.55-6.76l8.81-7.47c1.79-1.52,4.41-1.52,6.21,0l8.8,7.47c2.03,1.71,2.27,4.74.56,6.76Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>

); export default Emoji