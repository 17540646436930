import React from "react";

const Financeiro = (props) => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_549_518"   x="0" y="0" width="40" height="40">
    <rect width="40" height="40" fill="#8142FC"/>
    </mask>
    <g >
    <path d="M5.00008 33.3334C4.08341 33.3334 3.29869 33.007 2.64591 32.3542C1.99314 31.7015 1.66675 30.9167 1.66675 30.0001V11.6667H5.00008V30.0001H33.3334V33.3334H5.00008ZM11.6667 26.6667C10.7501 26.6667 9.96536 26.3404 9.31258 25.6876C8.6598 25.0348 8.33342 24.2501 8.33342 23.3334V10.0001C8.33342 9.08341 8.6598 8.29869 9.31258 7.64591C9.96536 6.99314 10.7501 6.66675 11.6667 6.66675H35.0001C35.9167 6.66675 36.7015 6.99314 37.3543 7.64591C38.007 8.29869 38.3334 9.08341 38.3334 10.0001V23.3334C38.3334 24.2501 38.007 25.0348 37.3543 25.6876C36.7015 26.3404 35.9167 26.6667 35.0001 26.6667H11.6667ZM15.0001 23.3334C15.0001 22.4167 14.6737 21.632 14.0209 20.9792C13.3681 20.3265 12.5834 20.0001 11.6667 20.0001V23.3334H15.0001ZM31.6667 23.3334H35.0001V20.0001C34.0834 20.0001 33.2987 20.3265 32.6459 20.9792C31.9931 21.632 31.6667 22.4167 31.6667 23.3334ZM23.3334 21.6667C24.7223 21.6667 25.9029 21.1806 26.8751 20.2084C27.8473 19.2362 28.3334 18.0556 28.3334 16.6667C28.3334 15.2779 27.8473 14.0973 26.8751 13.1251C25.9029 12.1529 24.7223 11.6667 23.3334 11.6667C21.9445 11.6667 20.764 12.1529 19.7917 13.1251C18.8195 14.0973 18.3334 15.2779 18.3334 16.6667C18.3334 18.0556 18.8195 19.2362 19.7917 20.2084C20.764 21.1806 21.9445 21.6667 23.3334 21.6667ZM11.6667 13.3334C12.5834 13.3334 13.3681 13.007 14.0209 12.3542C14.6737 11.7015 15.0001 10.9167 15.0001 10.0001H11.6667V13.3334ZM35.0001 13.3334V10.0001H31.6667C31.6667 10.9167 31.9931 11.7015 32.6459 12.3542C33.2987 13.007 34.0834 13.3334 35.0001 13.3334Z" fill="#8142FC"/>
    </g>
    </svg>
    
); export default Financeiro;