// Desc: Page to add new APIs to the system

// React Components
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Material UI Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FormControl, InputLabel, Typography, TextField, Button, FormHelperText, Select, MenuItem } from "@mui/material";

import { Tab, Tabs } from "@mui/material";

// Components and Functions from the project
import AlertSnackbar from "../../components/Alerts/AlertSnackbar";
import HelpAdd from "../../components/Cards/HelpAdd";
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import ModalR from "../../components/Modals/ModalR";
import ThemeColors from "../../components/ThemeColors/ThemeColors";
import Loading from "../../components/Loading/Loading";

import { ApiInsert } from "../../api/n8n/Listagens/ApiInsert";
import { isAuthenticated } from "../../authMiddleware";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// Render Form to add new APIs
function ApiForm({ copyRespostas, setCopyRespostas, setErrors, errors, uuid_client }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (field, value) => {
    setCopyRespostas({ ...copyRespostas, [field]: value });
  };

  const CheckApiPermission = async () => {
    const { apiName, apiDescription, apiUrl, apiToken, apiBody } = copyRespostas;

    if (!apiName || !apiDescription || !apiUrl || !apiToken || !apiBody) {
      const newErrors = {
        apiName: !apiName ? "Campo obrigatório" : "",
        apiDescription: !apiDescription ? "Campo obrigatório" : "",
        apiUrl: !apiUrl ? "Campo obrigatório" : "",
        apiToken: !apiToken ? "Campo obrigatório" : "",
        apiBody: !apiBody ? "Campo obrigatório" : "",
        uuid_client: uuid_client

      };
      setErrors(newErrors);
      setLoading(false);
      return;
    }


    try {

      copyRespostas.uuid_client = uuid_client;
      setLoading(true);
      const response = await ApiInsert(copyRespostas);

      if (response.status === 200) {
        navigate('/apis');
      } else {
        console.error("Erro ao inserir API:", response);
      }

      setLoading(false);
    } catch (error) {
      console.error("Erro ao inserir API:", error);
      setLoading(false);
    }
  };

  return (
    <Grid item xs={12} md={12} style={{ background: '#FFF', borderRadius: '12px', padding: '20px' }}>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          variant="outlined"
          name="apiName"
          label="Digite um nome para identificar a API"
          value={copyRespostas.apiName || ""}
          onChange={(e) => handleInputChange("apiName", e.target.value)}
          sx={{ background: errors.apiName ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          error={!!errors.apiName}
          InputLabelProps={{ style: { color: ThemeColors.gray50 } }}
        />
        {errors.apiName && <FormHelperText style={{ color: "red" }}>{errors.apiName}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          name="apiDescription"
          label="Digite uma descrição para a API"
          sx={{ background: errors.apiDescription ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          value={copyRespostas.apiDescription || ""}
          onChange={(e) => handleInputChange("apiDescription", e.target.value)}
          error={!!errors.apiDescription}
          InputLabelProps={{ style: { color: ThemeColors.gray50 } }}
        />
        {errors.apiDescription && <FormHelperText style={{ color: "red" }}>{errors.apiDescription}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel
          style={{ color: ThemeColors.gray50 }}
        >Qual o método HTTP da API?</InputLabel>
        <Select
          name="apiMethod"
          sx={{ background: errors.apiMethod ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          value={copyRespostas.apiMethod || ""}
          onChange={(e) => handleInputChange("apiMethod", e.target.value)}
          error={!!errors.apiMethod}
          fullWidth
          InputLabelProps={{ style: { color: ThemeColors.gray50 } }}
        >
          <MenuItem value="GET">GET</MenuItem>
          <MenuItem value="POST">POST</MenuItem>
        </Select>
        {errors.apiMethod && <FormHelperText style={{ color: "red" }}>{errors.apiMethod}</FormHelperText>}
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          autoFocus
          name="apiUrl"
          label="Digite a URL da API:"
          sx={{ background: errors.apiUrl ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          value={copyRespostas.apiUrl || ""}
          onChange={(e) => handleInputChange("apiUrl", e.target.value)}
          error={!!errors.apiUrl}
          fullWidth
          InputLabelProps={{ style: { color: ThemeColors.gray50 } }}
        />
        {errors.apiUrl && <FormHelperText style={{ color: "red" }}>{errors.apiUrl}</FormHelperText>}
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          autoFocus
          name="apiToken"
          label="Authorization Token"
          sx={{ background: errors.apiToken ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          value={copyRespostas.apiToken || ""}
          onChange={(e) => handleInputChange("apiToken", e.target.value)}
          error={!!errors.apiToken}
          fullWidth
          InputLabelProps={{ style: { color: ThemeColors.gray50 } }}
        />
        {errors.apiToken && <FormHelperText style={{ color: "red" }}>{errors.apiToken}</FormHelperText>}
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <TextField
          autoFocus
          name="apiBody"
          multiline
          rows={4}
          label="Body"
          sx={{ background: errors.apiBody ? '#F8D6D3' : '#F9F9F9', marginTop: '5px', borderRadius: '12px' }}
          value={copyRespostas.apiBody || ""}
          onChange={(e) => handleInputChange("apiBody", e.target.value)}
          error={!!errors.apiBody}
          fullWidth
          InputLabelProps={{ style: { color: ThemeColors.gray50 } }}
        />
        {errors.apiBody && <FormHelperText style={{ color: "red" }}>{errors.apiBody}</FormHelperText>}
      </FormControl>

      <Button
        variant="contained"
        onClick={() => {
          setLoading(true);
          CheckApiPermission();
        }}
        fullWidth
        disabled={loading}
      >
        {loading ? "Carregando..." : "Adicionar API"}
      </Button>
    </Grid>
  );
}

function PageAddApis() {
  const { authenticated } = isAuthenticated();
  const [userInfo, setUserInfo] = useState({});
  const [copyRespostas, setCopyRespostas] = useState({});
  const [errors, setErrors] = useState({});
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };
  const navigate = useNavigate();

  const steps = [
    { desc: "Escolha um nome para identificar a API" },
    { desc: "Crie uma descrição para a API" },
    { desc: "Indique a URL da API" },
    { desc: "Insira o token de autorização" },
    { desc: 'Clique em "Adicionar API" para finalizar' }
  ];

  useEffect(() => {
    if (authenticated) {
      const { decryptedData } = isAuthenticated();
      setUserInfo(decryptedData);
    }
  }, [authenticated]);

  const handleSubmit = async () => {
    if (userInfo) {
      const dados = { ...copyRespostas, uuid_client: userInfo.UUID, id: userInfo.EMAIL, token: userInfo.TOKEN };
      try {
        await ApiInsert(dados);
        setOpenModalConfirm(false);
        navigate('/success'); // Redirect on success
      } catch (error) {
        console.error("Erro ao inserir API:", error);
      }
    }
  };

  const handleModalConfirm = () => {
    setOpenModalConfirm(true);
  }

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  }

  useEffect(() => {
    setTimeout(() => setShowLoading(false), 1000);
  }, []);

  if (showLoading) {
    return <Loading open={true} />;
  }

  return (
    <>
      <HeaderPage title="Adicionar APIs" />


      <Grid container spacing={4} sx={{ padding: 5 }}>
        <Grid item xs={12} lg={6} sx={{ display: "flex", flexDirection: "column" }}>
          <HelpAdd Steps={steps} Title="Adicione sua API" />
          {
            (copyRespostas.apiName === "" || copyRespostas.apiDescription === "" || copyRespostas.apiUrl === "" || copyRespostas.apiToken === "") && (
              <AlertSnackbar message="Preencha todos os campos obrigatórios" severity="error" open={true} />
            )
          }
        </Grid>

        <Grid item xs={6} lg={6}>
          <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="form tabs" style={{ 'background': '#FFF', 'borderRadius': '12px', marginBottom: '10px' }}>
            <Tab label="Endpoint" />
          </Tabs>
          <TabPanel value={tabIndex} index={0} style={{ 'background': "#FFF", 'borderRadius': '12px', padding: '20px', minHeight: '60vh' }}>
            <ApiForm
              copyRespostas={copyRespostas}
              setCopyRespostas={setCopyRespostas}
              setErrors={setErrors}
              errors={errors}
              uuid_client={userInfo.UUID}
            />
          </TabPanel>
        </Grid>
      </Grid>
      <ModalR
        cancelText="Voltar"
        color={ThemeColors.purple500}
        confirmText="Sim, adicionar API"
        subTitle="Você deseja finalizar a adição da API?"
        isOpen={openModalConfirm}
        onClose={handleCloseModalConfirm}
        onConfirm={handleSubmit}
      />
    </>
  );
}

export default PageAddApis;
