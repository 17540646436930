import * as React from "react";

const Services = (props) => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_549_574" x="0" y="0" width="40" height="40">
            <rect width="40" height="40" fill="#8142FC" />
        </mask>
        <g>
            <path d="M31.5 34.9999L22.375 25.8749L25.875 22.3749L35 31.4999L31.5 34.9999ZM8.49996 34.9999L4.99996 31.4999L16.5 19.9999L13.6666 17.1666L12.5 18.3333L10.375 16.2083V19.6249L9.20829 20.7916L4.16663 15.7499L5.33329 14.5833H8.74996L6.66663 12.4999L12.5833 6.58325C13.1388 6.0277 13.7361 5.62492 14.375 5.37492C15.0138 5.12492 15.6666 4.99992 16.3333 4.99992C17 4.99992 17.6527 5.12492 18.2916 5.37492C18.9305 5.62492 19.5277 6.0277 20.0833 6.58325L16.25 10.4166L18.3333 12.4999L17.1666 13.6666L20 16.4999L23.75 12.7499C23.6388 12.4444 23.5486 12.1249 23.4791 11.7916C23.4097 11.4583 23.375 11.1249 23.375 10.7916C23.375 9.1527 23.9375 7.77075 25.0625 6.64575C26.1875 5.52075 27.5694 4.95825 29.2083 4.95825C29.625 4.95825 30.0208 4.99992 30.3958 5.08325C30.7708 5.16659 31.1527 5.29159 31.5416 5.45825L27.4166 9.58325L30.4166 12.5833L34.5416 8.45825C34.7361 8.84714 34.868 9.22909 34.9375 9.60409C35.0069 9.97909 35.0416 10.3749 35.0416 10.7916C35.0416 12.4305 34.4791 13.8124 33.3541 14.9374C32.2291 16.0624 30.8472 16.6249 29.2083 16.6249C28.875 16.6249 28.5416 16.5971 28.2083 16.5416C27.875 16.486 27.5555 16.3888 27.25 16.2499L8.49996 34.9999Z" fill="#8142FC" />
        </g>
    </svg>

);
export default Services;
