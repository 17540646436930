export async function ApiInsert(info) {
  try {
    const response = await fetch('https://api.123zap.com.br/webhook/insert-api', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ info: info }),
    });

    if (response.status === 200) {
      const data = await response.json();
      return { data, status: 200 };
    } else if (response.status === 500) {
      return { data: null, status: 500, message: 'Erro no servidor.' };
    } else {
      // Tratamento para outros códigos de status
      return { data: null, status: response.status, message: 'Erro inesperado.' };
    }
  } catch (error) {
    console.error('Erro ao obter dados da API:', error);
    return { data: null, status: 'Ocorreu um erro na requisição.' };
  }
}
