// Custom functions to use in the project

import toast from "react-hot-toast";
import CustomAlert from "../components/Alerts/CustomAlert";
import { isAuthenticated } from '../authMiddleware';

export const notify = (type, text) => {
  toast.remove();
  toast.custom(<CustomAlert message={text} type={type} />);
};

export const truncateText = (text, number) => {
  if (text.length < number) {
    return text;
  }

  return text.substring(0, number) + "...";
};
// Function to check if cookies are expired
export const checkAuth = () => {
  const authStatus = isAuthenticated();
  const { authenticated } = authStatus;

  return authenticated
};

// Function to create a object from one array
export function createObjectFromArray(array, initialValue) {
  const result = {};
  array.forEach((str) => {
    result[str] = initialValue; // All properties start with this value
  });
  return result;
}