import React from "react";

const Emoji21 = (props) => (

  <svg
  id="Camada_2"
  data-name="Camada 2"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 208.89 208.9"
  width={props.width || '85'} height={props.height || '85'}

>
  <defs>
    <style>{".cls-2{stroke-width:0;fill:#7b25ff}"}</style>
  </defs>
  <g id="Layer_1" data-name="Layer 1">
    <path
      className="cls-2"
      d="M153.89 0H55C24.62 0 0 24.62 0 55v98.9c0 30.37 24.62 55 55 55h98.89c30.38 0 55-24.63 55-55V55c0-30.38-24.62-55-55-55M72.31 90.28l-16.65 7.91c-.67.31-1.37.46-2.06.46-1.79 0-3.52-1.01-4.34-2.74-1.13-2.4-.11-5.26 2.28-6.39l7.53-3.58-7.53-3.57c-2.39-1.14-3.41-4-2.28-6.39 1.14-2.4 4-3.41 6.4-2.28l16.65 7.91c1.68.79 2.74 2.48 2.74 4.33s-1.06 3.54-2.74 4.34m55.47 29.05c-.95 1-1.95 1.93-2.99 2.79l1.57 2.93c1.89 3.51.56 7.86-2.94 9.76-1.09.56-2.25.85-3.4.85-2.57 0-5.04-1.38-6.34-3.8l-1.78-3.3c-2.4.57-4.9.86-7.45.86-8.94 0-17.22-3.59-23.34-10.09a4.82 4.82 0 0 1 .22-6.79 4.82 4.82 0 0 1 6.79.22c4.28 4.55 10.07 7.06 16.33 7.06s12.04-2.51 16.32-7.06a4.82 4.82 0 0 1 6.79-.22 4.82 4.82 0 0 1 .22 6.79m29.57-29.81c2.39 1.13 3.41 3.99 2.28 6.39a4.815 4.815 0 0 1-6.4 2.28l-16.65-7.91c-1.68-.8-2.74-2.48-2.74-4.34s1.06-3.54 2.74-4.33l16.65-7.91c2.4-1.13 5.26-.11 6.4 2.28 1.13 2.39.11 5.25-2.28 6.39l-7.53 3.57z"
    />
    <path
      className="cls-2"
      d="M75.05 85.94c0 1.86-1.06 3.54-2.74 4.34l-16.65 7.91c-.67.31-1.37.46-2.06.46-1.79 0-3.52-1.01-4.34-2.74-1.13-2.4-.11-5.26 2.28-6.39l7.53-3.58-7.53-3.57c-2.39-1.14-3.41-4-2.28-6.39 1.14-2.4 4-3.41 6.4-2.28l16.65 7.91c1.68.79 2.74 2.48 2.74 4.33m52.51 26.6a4.82 4.82 0 0 1 .22 6.79c-.95 1-1.95 1.93-2.99 2.79l1.57 2.93c1.89 3.51.56 7.86-2.94 9.76-1.09.56-2.25.85-3.4.85-2.57 0-5.04-1.38-6.34-3.8l-1.78-3.3c-2.4.57-4.9.86-7.45.86-8.94 0-17.22-3.59-23.34-10.09a4.82 4.82 0 0 1 .22-6.79 4.82 4.82 0 0 1 6.79.22c4.28 4.55 10.07 7.06 16.33 7.06s12.04-2.51 16.32-7.06a4.82 4.82 0 0 1 6.79-.22m32.07-16.63a4.815 4.815 0 0 1-6.4 2.28l-16.65-7.91c-1.68-.8-2.74-2.48-2.74-4.34s1.06-3.54 2.74-4.33l16.65-7.91c2.4-1.13 5.26-.11 6.4 2.28 1.13 2.39.11 5.25-2.28 6.39l-7.53 3.57 7.53 3.58c2.39 1.13 3.41 3.99 2.28 6.39"
    />
    <path
      d="M127.78 119.33c-.95 1-1.95 1.93-2.99 2.79l1.57 2.93c1.89 3.51.56 7.86-2.94 9.76-1.09.56-2.25.85-3.4.85-2.57 0-5.04-1.38-6.34-3.8l-1.78-3.3c-2.4.57-4.9.86-7.45.86-8.94 0-17.22-3.59-23.34-10.09a4.82 4.82 0 0 1 .22-6.79 4.82 4.82 0 0 1 6.79.22c4.28 4.55 10.07 7.06 16.33 7.06s12.04-2.51 16.32-7.06a4.82 4.82 0 0 1 6.79-.22 4.82 4.82 0 0 1 .22 6.79M75.05 85.94c0 1.86-1.06 3.54-2.74 4.34l-16.65 7.91c-.67.31-1.37.46-2.06.46-1.79 0-3.52-1.01-4.34-2.74-1.13-2.4-.11-5.26 2.28-6.39l7.53-3.58-7.53-3.57c-2.39-1.14-3.41-4-2.28-6.39 1.14-2.4 4-3.41 6.4-2.28l16.65 7.91c1.68.79 2.74 2.48 2.74 4.33m84.58 9.97a4.815 4.815 0 0 1-6.4 2.28l-16.65-7.91c-1.68-.8-2.74-2.48-2.74-4.34s1.06-3.54 2.74-4.33l16.65-7.91c2.4-1.13 5.26-.11 6.4 2.28 1.13 2.39.11 5.25-2.28 6.39l-7.53 3.57 7.53 3.58c2.39 1.13 3.41 3.99 2.28 6.39"
      style={{
        fill: "#210d33",
        strokeWidth: 0,
      }}
    />
  </g>
</svg>

); export default Emoji21