// Description: This component render a showcase of the site's Typographys
// Update: 12/08/2024

// Material UI Components
import { createTheme } from '@mui/material/styles';

// Components and functions from the project
import ThemeColors from './ThemeColors';

// Render Your Component
const CreateTheme = createTheme({
    typography: {
        fontFamily: 'Satoshi', // Sets the default font for the theme
        textTransform: 'none', // Sets the default text transformation for the theme
        h1: {
            fontSize: '2.5rem', // Example
        },
        body1: {
            fontSize: '1rem',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    color: 'white',
                    textTransform: 'none',
                    boxShadow: 'none',
                },
                containedPrimary: {
                    backgroundColor: ThemeColors.purple500,
                    '&:hover': {
                        backgroundColor: ThemeColors.purple600,
                        boxShadow: 'none',
                    },
                    '&:active': {
                        backgroundColor: ThemeColors.purple700,
                        boxShadow: 'none',
                        
                    },
                    '&.Mui-disabled': {
                        backgroundColor: ThemeColors.purple200,
                        color:  ThemeColors.purple400,
                        boxShadow: 'none',
                        
                    }
                    
                },
                outlinedPrimary: {
                    color: ThemeColors.purple400,
                    border: `1px solid ${ThemeColors.purple400}`,
                    '&:hover': {
                        backgroundColor: 'transparent',
                        border: `1px solid  ${ThemeColors.purple500}`,
                        boxShadow: 'none',
                        color: ThemeColors.purple500,
                    },
                    '&:active': {
                        backgroundColor: 'transparent',
                        border: `1px solid  ${ThemeColors.purple700}`,
                        boxShadow: 'none',
                        color: ThemeColors.purple700,
                    },
                    '&.Mui-disabled': {
                       
                        backgroundColor: 'transparent',
                        border: `1px solid ${ThemeColors.purple200}`,
                        boxShadow: 'none',
                        color: ThemeColors.purple200,
                    }
                }
            }
        },
        MuiSvgIcon: {  // Global icon customization
            styleOverrides: {
                root: {
                    // Sets the default color of icons based on your theme
                    color: ThemeColors.purple500,
                    // Size setting example, change as needed
                    fontSize: '1.25rem',
                }
            }
        }
        
    }
});

export default CreateTheme;
