import React from "react";

const Emoji09 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.9 208.9"
    width={props.width || '85'} height={props.height || '85'}
>
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.9,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.9c30.37,0,55-24.63,55-55V55c0-30.38-24.63-55-55-55ZM61.97,101.34c-6.79,0-12.27-6.21-12.27-13.85,0-1.75.29-3.42.84-4.95l-6.76-2.15c-2.53-.8-3.93-3.52-3.11-6.03.8-2.53,3.5-3.91,6.03-3.11l36.57,11.65c2.53.82,3.94,3.51,3.12,6.04-.64,2.04-2.53,3.34-4.57,3.34-.48,0-.97-.08-1.46-.22l-6.35-2.02c-1.06,6.42-6.03,11.3-12.04,11.3ZM127.56,136.58c-1.93,1.81-4.97,1.72-6.78-.22-4.28-4.55-10.08-7.06-16.33-7.06s-12.05,2.51-16.33,7.06c-1.82,1.94-4.85,2.03-6.78.22-1.94-1.82-2.03-4.85-.22-6.79,6.11-6.5,14.4-10.09,23.33-10.09s17.21,3.59,23.32,10.09c1.82,1.94,1.72,4.97-.21,6.79ZM165.11,80.39l-6.75,2.15c.54,1.53.83,3.2.83,4.95,0,7.64-5.49,13.85-12.25,13.85-6.01,0-11-4.88-12.05-11.3l-6.36,2.02c-.49.14-.98.22-1.45.22-2.04,0-3.93-1.3-4.57-3.34-.82-2.53.58-5.22,3.11-6.04l36.58-11.65c2.53-.8,5.22.58,6.02,3.11.82,2.51-.58,5.23-3.11,6.03Z"
        />
        <path
          className="cls-2"
          d="M80.36,92.06l-6.35-2.02c-1.06,6.42-6.03,11.3-12.04,11.3-6.79,0-12.27-6.21-12.27-13.85,0-1.75.29-3.42.84-4.95l-6.76-2.15c-2.53-.8-3.93-3.52-3.11-6.03.8-2.53,3.5-3.91,6.03-3.11l36.57,11.65c2.53.82,3.94,3.51,3.12,6.04-.64,2.04-2.53,3.34-4.57,3.34-.48,0-.97-.08-1.46-.22Z"
        />
        <path
          className="cls-2"
          d="M127.77,129.79c1.82,1.94,1.72,4.97-.21,6.79-1.93,1.81-4.97,1.72-6.78-.22-4.28-4.55-10.08-7.06-16.33-7.06s-12.05,2.51-16.33,7.06c-1.82,1.94-4.85,2.03-6.78.22-1.94-1.82-2.03-4.85-.22-6.79,6.11-6.5,14.4-10.09,23.33-10.09s17.21,3.59,23.32,10.09Z"
        />
        <path
          className="cls-2"
          d="M165.11,80.39l-6.75,2.15c.54,1.53.83,3.2.83,4.95,0,7.64-5.49,13.85-12.25,13.85-6.01,0-11-4.88-12.05-11.3l-6.36,2.02c-.49.14-.98.22-1.45.22-2.04,0-3.93-1.3-4.57-3.34-.82-2.53.58-5.22,3.11-6.04l36.58-11.65c2.53-.8,5.22.58,6.02,3.11.82,2.51-.58,5.23-3.11,6.03Z"
        />
        <g>
          <g>
            <path
              className="cls-1"
              d="M86.39,88.94c-.64,2.04-2.53,3.34-4.57,3.34-.48,0-.97-.08-1.46-.22l-6.35-2.02c-1.06,6.42-6.03,11.3-12.04,11.3-6.79,0-12.27-6.21-12.27-13.85,0-1.75.29-3.42.84-4.95l-6.76-2.15c-2.53-.8-3.93-3.52-3.11-6.03.8-2.53,3.5-3.91,6.03-3.11l36.57,11.65c2.53.82,3.94,3.51,3.12,6.04Z"
            />
            <path
              className="cls-1"
              d="M165.11,80.39l-6.75,2.15c.54,1.53.83,3.2.83,4.95,0,7.64-5.49,13.85-12.25,13.85-6.01,0-11-4.88-12.05-11.3l-6.36,2.02c-.49.14-.98.22-1.45.22-2.04,0-3.93-1.3-4.57-3.34-.82-2.53.58-5.22,3.11-6.04l36.58-11.65c2.53-.8,5.22.58,6.02,3.11.82,2.51-.58,5.23-3.11,6.03Z"
            />
          </g>
          <path
            className="cls-1"
            d="M127.56,136.58c-1.93,1.81-4.97,1.72-6.78-.22-4.28-4.55-10.08-7.06-16.33-7.06s-12.05,2.51-16.33,7.06c-1.82,1.94-4.85,2.03-6.78.22-1.94-1.82-2.03-4.85-.22-6.79,6.11-6.5,14.4-10.09,23.33-10.09s17.21,3.59,23.32,10.09c1.82,1.94,1.72,4.97-.21,6.79Z"
          />
        </g>
      </g>
    </g>
  </svg>

); export default Emoji09