// Description: This component render a showcase of the site's styles
// Update: 12/08/2024

// React Components and DOM Elements
import React, { useState } from 'react';

// Material UI Components
import { Container } from '@mui/material';

// Components and functions from the project
import HeaderPage from '../HeaderPage/HeaderPage';
import SidebarFake from './SidebarFake';
import PasswordPrompt from './PasswordPrompt';
import { useLocation } from 'react-router-dom';
import Cores from './Cores';
import Buttons from './Buttons';
import Emojis from './Emojis';
import Typographys from './Typographys';
import Labels from './Labels';
import Tables from './Tables';
import Iconography from './Iconography';
import Ilustrations from './Ilustrations';
import Alerts from './Alerts';

// Render Your Component
function Styles() {
  // State variables
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();

  // Remove leading slash and handle special characters
  const name = decodeURIComponent(location.pathname.substring(1));

  return (
    <div background-color="#fff !important">
      {!isAuthenticated ? (
        <Container component="main" maxWidth="xs" style={{ marginTop: '20vh' }}>
          <PasswordPrompt onSuccess={() => setIsAuthenticated(true)} />
        </Container>
      ) : (
        <>

          <div style={{ backgroundColor: '#fff' }}>
            {/* Sidebar -> Platform side menu, added as a fixed component after authentication */}
            <SidebarFake />

            {/* HeaderPage -> Page header, may or may not be added as a top component on screens */}
            <HeaderPage title={name === 'styles' ? 'Cores' : name === 'buttons' ? 'Botões' : name === 'typography' ? 'Tipografia' : name === 'emojis' ? 'Emojis' : name === 'labels' ? 'Labels' : name === 'tables' ? 'Tabelas' : name === 'iconography' ? 'Iconografia' : name === 'ilustrations' ? 'Ilustrações' : name === 'alerts' ? 'Alertas' : 'Estilos'} />

            {/* Padding for better visualization of elements */}

            {name === 'styles' ? <Cores /> : null}

            {name === 'buttons' ? <Buttons /> : null}
            {name === 'iconography' ? <Iconography /> : null}

            {name === 'emojis' ? <Emojis /> : null}

            {name === 'typography' ? <Typographys /> : null}

            {name === 'labels' ? <Labels /> : null}

            {name === 'tables' ? <Tables /> : null}

            {name === 'alerts' ? <Alerts /> : null}


            {name === 'ilustrations' ? <Ilustrations /> : null}


          </div>

        </>
      )
      }
    </div >
  );
}

export default Styles;
