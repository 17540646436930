// Description: This component render a showcase of the container's styles of the site
// Update: 12/08/2024

// React Components and DOM Elements
import React from 'react';

// Components and functions from the project
import Titles from '../Titles/Titles';

// Render Your Component
const ContainerStyle = ({ children, color, colorFont, title, subtitle }) => {
  return (

    <div style={{ backgroundColor: color, color: colorFont, padding: '10px', width: '80px', height: '100px', borderRadius: '12px',margin: '10px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}> 
      <Titles type='P1' color={colorFont} bold={false} style={'regular'}> {title} </Titles>
      <Titles type='P2' color={colorFont} bold={false} style={'regular'}> {subtitle} </Titles>

    </div>
  );
}

export default ContainerStyle;