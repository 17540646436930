// Desc: This component is responsible for rendering the main content of the application.
// Update: 12/08/2024

// React Components
import React from 'react';

// Components and functions from the project
import PapperBlock from './PapperBlock';

// Styles
import './MainContent.css';

// Render Your Component
function MainContent({ children }) {

  return (
    <>
      <div className='MainContent'>
        <PapperBlock>
          {children}
        </PapperBlock>
      </div>
    </>
  );
}

export default MainContent;
