import { AES, enc } from 'crypto-js';
import CryptoJS from 'crypto-js';

const getCookie = (name) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // Verifica se o cookie começa com o nome fornecido
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};

let cookieIVGEN = '';

function isAuthenticated() {
  let authenticated = false;

  const cookieUUID = process.env.REACT_APP_COOKIE_UUID;
  const cookieNameClient = process.env.REACT_APP_COOKIE_NAME_CLIENT;
  const cookieUserType = process.env.REACT_APP_COOKIE_USER_TYPE;
  const cookieIv = process.env.REACT_APP_COOKIE_IV;
  const cookieToken = process.env.REACT_APP_COOKIE_TOKEN;
  const cookieEmail = process.env.REACT_APP_COOKIE_EMAIL;

  const getCookieUUID = getCookie(cookieUUID);
  const getCookieNameClient = getCookie(cookieNameClient);
  const getCookieUserType = getCookie(cookieUserType);
  const getCookieIv = getCookie(cookieIv);  
  const getCookieToken = getCookie(cookieToken);
  const getCookieEmail = getCookie(cookieEmail);

  if (getCookieUUID && getCookieNameClient && getCookieUserType && getCookieIv && getCookieToken && getCookieEmail) {
    authenticated = true;
    const ivBase64 = getCookieIv;
    cookieIVGEN = ivBase64;
  } else {
    authenticated = false;
  }

  const decryptedData = {};

  if (authenticated) {
    decryptedData['UUID'] = decrypted(getCookieUUID);
    decryptedData['NAME'] = decrypted(getCookieNameClient);
    decryptedData['USERTYPE'] = decrypted(getCookieUserType);
    decryptedData['IV'] = getCookieIv;
    decryptedData['TOKEN'] = decrypted(getCookieToken);
    decryptedData['EMAIL'] = decrypted(getCookieEmail);
    
  }

  return { authenticated, decryptedData };
}

const ENCRYPTION_KEY_HEX = process.env.REACT_APP_ENCRYPTION_KEY;
const ENCRYPTION_KEY = enc.Hex.parse(ENCRYPTION_KEY_HEX);

function base64ToBytes(base64) {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

function decrypted(encryptedData) {
  if (!encryptedData) {
    return '';
  }

  // Assegure-se de que ENCRYPTION_KEY_HEX esteja definido corretamente no seu .env
  const ENCRYPTION_KEY_HEX = process.env.REACT_APP_ENCRYPTION_KEY;
  const ENCRYPTION_KEY = CryptoJS.enc.Hex.parse(ENCRYPTION_KEY_HEX);
  
  // O IV (cookieIVGEN) deve ser uma string Base64 válida do cookie
  const iv = CryptoJS.enc.Base64.parse(cookieIVGEN);

  const decryptedValue = CryptoJS.AES.decrypt(encryptedData, ENCRYPTION_KEY, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  const decryptedData = decryptedValue.toString(CryptoJS.enc.Utf8);

  if (!decryptedData) {
    console.error('Decryption failed');
    return '';
  }

  return decryptedData.replace(/\u0000/g, '').trim();
}

function encrypt(data, iv) {
  const paddedData = padData(data); // Função para adicionar padding PKCS7
  
  const encryptedValue = AES.encrypt(paddedData, ENCRYPTION_KEY, { iv: enc.Base64.parse(iv) }).toString();

  return encryptedValue;
}

function encrypt_first_login(data) {
  // Gerar um IV exclusivo para cada conjunto de dados criptografados

  const iv = generateUniqueIV();
  
  // Criptografar os dados usando a função de criptografia AES
  const encryptedData = encrypt(data, iv);
  
  const indexData = process.env.REACT_APP_RECEIVED_CRYPTO;
  const indexIV = process.env.REACT_APP_COOKIE_IV;
  

  // Retornar os dados criptografados e o IV para enviar ao servidor
  return { [indexData ]: encryptedData, [indexIV]: iv };
  
}

// Função para gerar um IV exclusivo
function generateUniqueIV() {
  // Gere um IV aleatório de 16 bytes
  const ivBytes = new Uint8Array(16);
  crypto.getRandomValues(ivBytes);

  // Converta os bytes em uma string base64
  const ivBase64 = btoa(String.fromCharCode.apply(null, ivBytes));

  return ivBase64;
}


// Função para adicionar padding PKCS7
function padData(data) {
  const blockSize = 16; // Tamanho do bloco em bytes (AES usa blocos de 128 bits = 16 bytes)
  const paddingLength = blockSize - (data.length % blockSize);
  const paddingChar = String.fromCharCode(paddingLength);
  const paddedData = data + paddingChar.repeat(paddingLength);
  return paddedData;
}

export { isAuthenticated, encrypt, decrypted, encrypt_first_login};
