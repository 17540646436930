// Description: A Context to handle the state management of the project
// Updated: 14/08/2024

// React
import React, { createContext, useContext, useState, useEffect } from 'react';

// Functions and assets for the project
import { checkAuth } from '../utils/utils';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    // State Variables
    const [userAuthenticated, setUserAuthenticated] = useState(false);
    const [loading, setloading] = useState(true);
    const [userInfo, setUserInfo] = useState({});
    
    useEffect(() => {
        setUserAuthenticated(checkAuth());
    }, []);

    const value = {
        userAuthenticated,
        setUserAuthenticated,
        loading, 
        setloading,
        userInfo,
        setUserInfo
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
