import React from "react";

const LogoSimple = () => {

    return (
        <svg width="53" height="31" viewBox="0 0 53 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39.1513 0V30.8242H11.6581C8.78254 30.8242 6.41891 28.5375 6.41891 25.6669V7.484H6.26105L0 0H39.1513Z" fill="#D4BDFF" />
            <path d="M44.5484 0V30.8242H13.4309V27.645C13.4309 22.5276 16.4238 20.7427 22.4502 16.1691C25.6415 13.8047 27.0196 12.4082 27.0196 10.3146C27.0196 8.56964 25.7994 7.3685 23.7899 7.3685C21.663 7.3685 19.8903 9.15131 19.0242 11.2071L12.3685 8.26095C13.5503 4.57565 17.4093 0.155392 24.0266 0H44.5484Z" fill="#A789EF" />
            <path d="M52.8189 7.3286V23.9723C52.8189 27.7269 49.7172 30.7738 45.903 30.7906C43.7697 30.799 42.4322 30.8158 41.5938 30.7864C41.3187 30.8242 41.0435 30.8242 40.7662 30.8242C34.5819 30.8242 30.3666 27.0234 28.9502 23.3801L35.3307 20.0077C36.3546 21.9858 37.9311 23.5355 40.5294 23.5355C42.4983 23.5355 43.7207 22.4499 43.7207 20.9758C43.7207 19.2707 42.4215 18.1451 40.412 18.1451H36.0005V12.4061H40.6488C42.1848 12.4061 43.5244 11.205 43.5244 9.80857C43.5244 8.02577 42.0674 7.17111 40.5699 7.17111C38.3257 7.17111 36.6703 8.52764 35.5291 10.6212L29.4643 7.59739C31.1559 3.14143 35.608 0 40.9645 0C41.2397 0 41.5171 0 41.7517 0.037798V0H45.3761C49.4698 0 52.8211 3.29682 52.8211 7.3286H52.8189Z" fill="#7428EA" />
        </svg>

    );

}; export default LogoSimple;