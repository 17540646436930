// Request to get campaigns by account uuid
// Update in 20/04/2024 ( OK )
import axios from 'axios';

export async function CampaignStatus(data, iv) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/campaigns-change-status`,
            { [process.env.REACT_APP_RECEIVED_CRYPTO]: data, [process.env.REACT_APP_COOKIE_IV]: iv }
        );
        
        const responseData = response.data;
        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        // Melhor manejo do erro, exibindo mensagem de erro do servidor, se disponível
        const errorMsg = error.response?.data?.message || 'Erro desconhecido ao buscar dados da API';
        throw new Error(`Erro ao buscar dados da API: ${errorMsg}`);
    }
}
