// Desc: This page is responsible for showing the options of channels that the user can use to send the templates
// Update: 20/08/2024

import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import CardInfo from '../../components/Cards/CardInfo';
import Titles from '../../components/Titles/Titles';
import HeaderPage from '../../components/HeaderPage/HeaderPage';
import Box from '@mui/material/Box';
import Fast from '../../assets/images/Fast.png';
import Select from '../../assets/images/Select.png';
import Advanced from '../../assets/images/Advanced.png';
// Colors of the theme
import ThemeColors from '../../components/ThemeColors/ThemeColors';
import Footer from '../../components/Footer/Footer';

import Loading from '../../components/Loading/Loading';

const PageOptionsCampaign = (props) => {
    const [showLoading, setShowLoading] = useState(true);

    const data = [
        {
            image: Fast,
            title: 'Modo Rápido',
            desc: 'Crie campanhas de modo rápido e fácil, ideal para quem deseja enviar mensagens de texto ou imagens para seus clientes de forma rápida e segura.',
            href: '/campanhas/adicionar/modo-rapido'
        },
        {
            image: Select,
            title: 'Modo Seleção',
            desc: 'Crie campanhas selecionando e filtrando exatamente os clientes que deseja atingir, ideal para disparos pequenos e segmentados.',
            href: '/campanhas/adicionar/modo-seleção'
        },
        {
            image: Advanced,
            title: 'Modo Personalizado',
            desc: 'Crie campanhas com configurações personalizadas, ideal para campanhas complexas e com regras específicas. por exemplo, campanhas de fatuamento.',
            href: '/campanhas/adicionar/modo-personalizado'
        },
      

    ];

    useEffect(() => {
        // Simulate a API call
        setTimeout(() => setShowLoading(false), 1000);
    }, []);

    // Show the loading screen
    if (showLoading) {
        return <Loading open={true} />;
    }

    return (
        <>

            <Box style={{ position: 'relative', minHeight: '100vh' }}>
                <Grid container spacing={2}>
                    <HeaderPage title="Modelos" />
                </Grid>

                <div style={{ borderRadius: '2px', overflow: 'hidden', padding: '25px 40px 25px 40px' , margin: '20px'}}>
                    <Grid container spacing={2} justify="center" style={{ marginBottom: '40px' }}>
                        <Grid item xs={12} sm={4}>
                            <Titles type='H3' color={ThemeColors.gray40} style="medium">Como você deseja criar sua campanha?</Titles>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} sm={12}>
                            <CardInfo title="Email" desc="Aperfeiçoe suas campanhas com templates de e-mail versáteis, ideal para marketing, notificações ou correspondências corporativas." style={{ maxHeight: '100px', overflow: 'auto' }} cards={data} />
                        </Grid>
                    </Grid>

                    <div style={{ padding: "40px 0 0 0" }}>
                        <Footer />
                    </div>
                </div>
            </Box>
        </>
    );
};

export default PageOptionsCampaign;