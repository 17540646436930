import React from "react";

const DeleteIcon = (props) => (

    <svg  width={props.width ? props.width : '15'} height={props.height ? props.height : '15'}  viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_78_3551"  maskUnits="userSpaceOnUse" x="0" y="0" width="15" height="15">
            <rect  width={props.width ? props.width : '15'} height={props.height ? props.height : '15'}  fill={props.color ? props.color : '#D9D9D9'} />
        </mask>
        <g mask="url(#mask0_78_3551)">
            <path d="M4.375 13.125C4.03125 13.125 3.73698 13.0026 3.49219 12.7578C3.2474 12.513 3.125 12.2188 3.125 11.875V3.75H2.5V2.5H5.625V1.875H9.375V2.5H12.5V3.75H11.875V11.875C11.875 12.2188 11.7526 12.513 11.5078 12.7578C11.263 13.0026 10.9688 13.125 10.625 13.125H4.375ZM5.625 10.625H6.875V5H5.625V10.625ZM8.125 10.625H9.375V5H8.125V10.625Z" fill={props.color ? props.color : 'red'} />
        </g>
    </svg>

); export default DeleteIcon;