import React from "react";

const Emoji12 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.9 208.9"
    width={props.width || '85'} height={props.height || '85'}
>
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.9,0H55C24.63,0,0,24.62,0,55v98.9c0,30.37,24.63,55,55,55h98.9c30.38,0,55-24.63,55-55V55c0-30.38-24.62-55-55-55ZM61.93,119.09c-6.77,0-12.26-6.21-12.26-13.86s5.49-13.86,12.26-13.86,12.26,6.21,12.26,13.86-5.48,13.86-12.26,13.86ZM77.24,72.7c-.4.38-9.99,9.17-25.71,10.45-.09,0-.17,0-.26,0-1.65,0-3.05-1.27-3.19-2.94-.14-1.76,1.17-3.31,2.93-3.45,13.42-1.09,21.79-8.68,21.88-8.75,1.29-1.2,3.32-1.12,4.51.17,1.2,1.29,1.13,3.31-.16,4.51ZM127.39,138.08c-.75,1.36-2.62,3.65-6.79,3.65h-32.32c-4.13,0-5.99-2.25-6.74-3.6-1.63-2.97-1.12-7.23,1.33-11.11,4.7-7.44,12.44-11.72,21.2-11.78h.16c8.85,0,16.97,4.37,21.76,11.75,2.49,3.82,3.02,8.08,1.4,11.09ZM146.97,119.09c-6.77,0-12.26-6.21-12.26-13.86s5.49-13.86,12.26-13.86,12.26,6.21,12.26,13.86-5.48,13.86-12.26,13.86ZM157.64,83.16c-.09,0-.18,0-.27,0-15.72-1.28-25.31-10.07-25.71-10.45-1.29-1.2-1.36-3.23-.16-4.52,1.21-1.29,3.23-1.36,4.52-.16.11.1,8.48,7.66,21.87,8.75,1.76.14,3.07,1.69,2.93,3.45-.14,1.67-1.53,2.94-3.18,2.94Z"
        />
        <path
          className="cls-2"
          d="M74.19,105.23c0,7.65-5.48,13.86-12.26,13.86s-12.26-6.21-12.26-13.86,5.49-13.86,12.26-13.86,12.26,6.21,12.26,13.86Z"
        />
        <path
          className="cls-2"
          d="M77.4,68.19c1.2,1.29,1.13,3.31-.16,4.51-.4.38-9.99,9.17-25.71,10.45-.09,0-.17,0-.26,0-1.65,0-3.05-1.27-3.19-2.94-.14-1.76,1.17-3.31,2.93-3.45,13.42-1.09,21.79-8.68,21.88-8.75,1.29-1.2,3.32-1.12,4.51.17Z"
        />
        <path
          className="cls-2"
          d="M125.99,126.99c2.49,3.82,3.02,8.08,1.4,11.09-.75,1.36-2.62,3.65-6.79,3.65h-32.32c-4.13,0-5.99-2.25-6.74-3.6-1.63-2.97-1.12-7.23,1.33-11.11,4.7-7.44,12.44-11.72,21.2-11.78h.16c8.85,0,16.97,4.37,21.76,11.75Z"
        />
        <path
          className="cls-2"
          d="M159.23,105.23c0,7.65-5.48,13.86-12.26,13.86s-12.26-6.21-12.26-13.86,5.49-13.86,12.26-13.86,12.26,6.21,12.26,13.86Z"
        />
        <path
          className="cls-2"
          d="M160.82,80.22c-.14,1.67-1.53,2.94-3.18,2.94-.09,0-.18,0-.27,0-15.72-1.28-25.31-10.07-25.71-10.45-1.29-1.2-1.36-3.23-.16-4.52,1.21-1.29,3.23-1.36,4.52-.16.11.1,8.48,7.66,21.87,8.75,1.76.14,3.07,1.69,2.93,3.45Z"
        />
        <g>
          <g>
            <path
              className="cls-1"
              d="M74.19,105.23c0,7.65-5.48,13.86-12.26,13.86s-12.26-6.21-12.26-13.86,5.49-13.86,12.26-13.86,12.26,6.21,12.26,13.86Z"
            />
            <path
              className="cls-1"
              d="M159.23,105.23c0,7.65-5.48,13.86-12.26,13.86s-12.26-6.21-12.26-13.86,5.49-13.86,12.26-13.86,12.26,6.21,12.26,13.86Z"
            />
          </g>
          <g>
            <path
              className="cls-1"
              d="M77.24,72.7c-.4.38-9.99,9.17-25.71,10.45-.09,0-.17,0-.26,0-1.65,0-3.05-1.27-3.19-2.94-.14-1.76,1.17-3.31,2.93-3.45,13.42-1.09,21.79-8.68,21.88-8.75,1.29-1.2,3.32-1.12,4.51.17,1.2,1.29,1.13,3.31-.16,4.51Z"
            />
            <path
              className="cls-1"
              d="M160.82,80.22c-.14,1.67-1.53,2.94-3.18,2.94-.09,0-.18,0-.27,0-15.72-1.28-25.31-10.07-25.71-10.45-1.29-1.2-1.36-3.23-.16-4.52,1.21-1.29,3.23-1.36,4.52-.16.11.1,8.48,7.66,21.87,8.75,1.76.14,3.07,1.69,2.93,3.45Z"
            />
          </g>
          <path
            className="cls-1"
            d="M127.39,138.08c-.75,1.36-2.62,3.65-6.79,3.65h-32.32c-4.13,0-5.99-2.25-6.74-3.6-1.63-2.97-1.12-7.23,1.33-11.11,4.7-7.44,12.44-11.72,21.2-11.78h.16c8.85,0,16.97,4.37,21.76,11.75,2.49,3.82,3.02,8.08,1.4,11.09Z"
          />
        </g>
      </g>
    </g>
  </svg>

); export default Emoji12