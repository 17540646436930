import React from "react";

const Emoji17 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.9 208.9"
    width={props.width || '85'} height={props.height || '85'}
>
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.9,0H55C24.63,0,0,24.62,0,55v98.9c0,30.37,24.63,55,55,55h98.9c30.38,0,55-24.63,55-55V55c0-30.38-24.62-55-55-55ZM66.22,137.46h-13.39v-56.43h-6.51c-2.64,0-4.79-2.15-4.79-4.81s2.15-4.78,4.79-4.78h39.8c2.64,0,4.79,2.14,4.79,4.78s-2.15,4.81-4.79,4.81h-19.9v56.43ZM127.39,121.59c-.74,1.37-2.62,3.66-6.79,3.66h-32.31c-4.14,0-6.01-2.25-6.76-3.6-1.62-2.97-1.11-7.23,1.35-11.11,4.69-7.44,12.41-11.72,21.19-11.78h.16c8.85,0,16.97,4.39,21.76,11.75,2.49,3.82,3.02,8.08,1.4,11.08ZM162.59,81.03h-6.52v56.43h-13.38v-56.43h-19.9c-2.64,0-4.79-2.15-4.79-4.81s2.15-4.78,4.79-4.78h39.8c2.64,0,4.78,2.14,4.78,4.78s-2.14,4.81-4.78,4.81Z"
        />
        <path
          className="cls-2"
          d="M86.12,81.03h-19.9v56.43h-13.39v-56.43h-6.51c-2.64,0-4.79-2.15-4.79-4.81s2.15-4.78,4.79-4.78h39.8c2.64,0,4.79,2.14,4.79,4.78s-2.15,4.81-4.79,4.81Z"
        />
        <path
          className="cls-2"
          d="M125.99,110.51c2.49,3.82,3.02,8.08,1.4,11.08-.74,1.37-2.62,3.66-6.79,3.66h-32.31c-4.14,0-6.01-2.25-6.76-3.6-1.62-2.97-1.11-7.23,1.35-11.11,4.69-7.44,12.41-11.72,21.19-11.78h.16c8.85,0,16.97,4.39,21.76,11.75Z"
        />
        <path
          className="cls-2"
          d="M167.37,76.22c0,2.66-2.14,4.81-4.78,4.81h-6.52v56.43h-13.38v-56.43h-19.9c-2.64,0-4.79-2.15-4.79-4.81s2.15-4.78,4.79-4.78h39.8c2.64,0,4.78,2.14,4.78,4.78Z"
        />
        <g>
          <g>
            <path
              className="cls-1"
              d="M90.91,76.22c0,2.66-2.15,4.81-4.79,4.81h-39.8c-2.64,0-4.79-2.15-4.79-4.81s2.15-4.78,4.79-4.78h39.8c2.64,0,4.79,2.14,4.79,4.78Z"
            />
            <path
              className="cls-1"
              d="M167.37,76.22c0,2.66-2.14,4.81-4.78,4.81h-39.8c-2.64,0-4.79-2.15-4.79-4.81s2.15-4.78,4.79-4.78h39.8c2.64,0,4.78,2.14,4.78,4.78Z"
            />
          </g>
          <path
            className="cls-1"
            d="M127.39,121.59c-.74,1.37-2.62,3.66-6.79,3.66h-32.31c-4.14,0-6.01-2.25-6.76-3.6-1.62-2.97-1.11-7.23,1.35-11.11,4.69-7.44,12.41-11.72,21.19-11.78h.16c8.85,0,16.97,4.39,21.76,11.75,2.49,3.82,3.02,8.08,1.4,11.08Z"
          />
          <rect
            className="cls-1"
            x={142.69}
            y={79.11}
            width={13.38}
            height={58.35}
          />
          <rect
            className="cls-1"
            x={52.83}
            y={79.11}
            width={13.39}
            height={58.35}
          />
        </g>
      </g>
    </g>
  </svg>

); export default Emoji17