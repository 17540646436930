import React from "react";

const Emoji18 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.9 208.9"
    width={props.width || '85'} height={props.height || '85'}
>
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.9,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.9c30.38,0,55-24.63,55-55V55c0-30.38-24.62-55-55-55ZM66.84,109.44c-.52.34-1.14.51-1.74.51s-1.21-.17-1.73-.51l-5.12-3.3c-12.58-8.13-14.04-14.67-14.04-18.88,0-6.95,5.67-12.6,12.62-12.6,3.08,0,6.01,1.13,8.27,3.1,2.25-1.97,5.19-3.1,8.28-3.1,6.96,0,12.62,5.65,12.62,12.6,0,4.21-1.46,10.75-14.06,18.88l-5.1,3.3ZM127.78,124.14c-6.11,6.51-14.4,10.1-23.33,10.1s-17.21-3.59-23.32-10.1c-1.82-1.93-1.72-4.97.21-6.78,1.93-1.81,4.97-1.72,6.78.21,4.28,4.56,10.08,7.07,16.33,7.07s12.05-2.51,16.33-7.07c1.82-1.93,4.85-2.02,6.79-.21,1.93,1.81,2.02,4.85.21,6.78ZM150.65,106.14l-5.1,3.3c-.55.34-1.15.51-1.75.51s-1.2-.17-1.73-.51l-5.11-3.3c-12.58-8.13-14.04-14.67-14.04-18.88,0-6.95,5.64-12.6,12.6-12.6,3.09,0,6.03,1.13,8.28,3.1,2.26-1.97,5.19-3.1,8.29-3.1,6.95,0,12.6,5.65,12.6,12.6,0,4.21-1.44,10.75-14.04,18.88Z"
        />
        <path
          className="cls-2"
          d="M71.94,106.14l-5.1,3.3c-.52.34-1.14.51-1.74.51s-1.21-.17-1.73-.51l-5.12-3.3c-12.58-8.13-14.04-14.67-14.04-18.88,0-6.95,5.67-12.6,12.62-12.6,3.08,0,6.01,1.13,8.27,3.1,2.25-1.97,5.19-3.1,8.28-3.1,6.96,0,12.62,5.65,12.62,12.6,0,4.21-1.46,10.75-14.06,18.88Z"
        />
        <path
          className="cls-2"
          d="M127.57,117.36c1.93,1.81,2.02,4.85.21,6.78-6.11,6.51-14.4,10.1-23.33,10.1s-17.21-3.59-23.32-10.1c-1.82-1.93-1.72-4.97.21-6.78,1.93-1.81,4.97-1.72,6.78.21,4.28,4.56,10.08,7.07,16.33,7.07s12.05-2.51,16.33-7.07c1.82-1.93,4.85-2.02,6.79-.21Z"
        />
        <path
          className="cls-2"
          d="M164.69,87.26c0,4.21-1.44,10.75-14.04,18.88l-5.1,3.3c-.55.34-1.15.51-1.75.51s-1.2-.17-1.73-.51l-5.11-3.3c-12.58-8.13-14.04-14.67-14.04-18.88,0-6.95,5.64-12.6,12.6-12.6,3.09,0,6.03,1.13,8.28,3.1,2.26-1.97,5.19-3.1,8.29-3.1,6.95,0,12.6,5.65,12.6,12.6Z"
        />
        <g>
          <path
            className="cls-1"
            d="M127.78,124.14c-6.11,6.51-14.4,10.1-23.33,10.1s-17.21-3.59-23.32-10.1c-1.82-1.93-1.72-4.97.21-6.78,1.93-1.81,4.97-1.72,6.78.21,4.28,4.56,10.08,7.07,16.33,7.07s12.05-2.51,16.33-7.07c1.82-1.93,4.85-2.02,6.79-.21,1.93,1.81,2.02,4.85.21,6.78Z"
          />
          <g>
            <path
              className="cls-1"
              d="M86,87.26c0,4.21-1.46,10.75-14.06,18.88l-5.1,3.3c-.52.34-1.14.51-1.74.51s-1.21-.17-1.73-.51l-5.12-3.3c-12.58-8.13-14.04-14.67-14.04-18.88,0-6.95,5.67-12.6,12.62-12.6,3.08,0,6.01,1.13,8.27,3.1,2.25-1.97,5.19-3.1,8.28-3.1,6.96,0,12.62,5.65,12.62,12.6Z"
            />
            <path
              className="cls-1"
              d="M164.69,87.26c0,4.21-1.44,10.75-14.04,18.88l-5.1,3.3c-.55.34-1.15.51-1.75.51s-1.2-.17-1.73-.51l-5.11-3.3c-12.58-8.13-14.04-14.67-14.04-18.88,0-6.95,5.64-12.6,12.6-12.6,3.09,0,6.03,1.13,8.28,3.1,2.26-1.97,5.19-3.1,8.29-3.1,6.95,0,12.6,5.65,12.6,12.6Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>

); export default Emoji18