import React from "react";

const Emoji06 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.89 208.9"
    width={props.width || '85'} height={props.height || '85'}
>
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.89,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.89c30.38,0,55-24.63,55-55V55c0-30.38-24.62-55-55-55ZM129.02,62.63c1.21-1.17,3.13-1.15,4.31.05.04.04,1.94,1.95,5.35,3.97,3.41,2.03,8.32,4.18,14.36,4.72,1.68.15,2.92,1.64,2.77,3.31-.14,1.59-1.47,2.78-3.04,2.78-.09,0-.18,0-.27-.01-14.41-1.29-23.17-10.13-23.53-10.51-1.18-1.2-1.15-3.13.05-4.31ZM55.85,71.37c12.18-1.08,19.63-8.61,19.7-8.68,1.18-1.21,3.11-1.23,4.32-.06,1.2,1.18,1.23,3.11.05,4.31-.36.38-9.12,9.22-23.53,10.51-.09.01-.18.01-.27.01-1.57,0-2.9-1.19-3.04-2.78-.15-1.67,1.09-3.16,2.77-3.31ZM46.31,104.35c-2.65,0-4.79-2.15-4.79-4.8s2.14-4.8,4.79-4.8h39.8c2.65,0,4.8,2.15,4.8,4.8s-2.15,4.8-4.8,4.8h-39.8ZM127.37,143.47c-.74,1.37-2.6,3.66-6.77,3.66h-32.33c-4.14,0-5.99-2.26-6.74-3.6-1.64-2.97-1.13-7.23,1.33-11.11,4.71-7.43,12.43-11.72,21.19-11.78,8.87-.02,17.11,4.33,21.94,11.74,2.47,3.84,3.02,8.09,1.38,11.09ZM162.58,104.35h-39.8c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.8c2.65,0,4.79,2.15,4.79,4.8s-2.14,4.8-4.79,4.8Z"
        />
        <path
          className="cls-2"
          d="M79.92,66.94c-.36.38-9.12,9.22-23.53,10.51-.09.01-.18.01-.27.01-1.57,0-2.9-1.19-3.04-2.78-.15-1.67,1.09-3.16,2.77-3.31,12.18-1.08,19.63-8.61,19.7-8.68,1.18-1.21,3.11-1.23,4.32-.06,1.2,1.18,1.23,3.11.05,4.31Z"
        />
        <path
          className="cls-2"
          d="M90.91,99.55c0,2.65-2.15,4.8-4.8,4.8h-39.8c-2.65,0-4.79-2.15-4.79-4.8s2.14-4.8,4.79-4.8h39.8c2.65,0,4.8,2.15,4.8,4.8Z"
        />
        <path
          className="cls-2"
          d="M127.37,143.47c-.74,1.37-2.6,3.66-6.77,3.66h-32.33c-4.14,0-5.99-2.26-6.74-3.6-1.64-2.97-1.13-7.23,1.33-11.11,4.71-7.43,12.43-11.72,21.19-11.78,8.87-.02,17.11,4.33,21.94,11.74,2.47,3.84,3.02,8.09,1.38,11.09Z"
        />
        <path
          className="cls-2"
          d="M155.81,74.68c-.14,1.59-1.47,2.78-3.04,2.78-.09,0-.18,0-.27-.01-14.41-1.29-23.17-10.13-23.53-10.51-1.18-1.2-1.15-3.13.05-4.31,1.21-1.17,3.13-1.15,4.31.05.04.04,1.94,1.95,5.35,3.97,3.41,2.03,8.32,4.18,14.36,4.72,1.68.15,2.92,1.64,2.77,3.31Z"
        />
        <path
          className="cls-2"
          d="M167.37,99.55c0,2.65-2.14,4.8-4.79,4.8h-39.8c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.8c2.65,0,4.79,2.15,4.79,4.8Z"
        />
        <g>
          <path
            className="cls-1"
            d="M127.37,143.47c-.74,1.37-2.6,3.66-6.77,3.66h-32.33c-4.14,0-5.99-2.26-6.74-3.6-1.64-2.97-1.13-7.23,1.33-11.11,4.71-7.43,12.43-11.72,21.19-11.78,8.87-.02,17.11,4.33,21.94,11.74,2.47,3.84,3.02,8.09,1.38,11.09Z"
          />
          <g>
            <path
              className="cls-1"
              d="M90.91,99.55c0,2.65-2.15,4.8-4.8,4.8h-39.8c-2.65,0-4.79-2.15-4.79-4.8s2.14-4.8,4.79-4.8h39.8c2.65,0,4.8,2.15,4.8,4.8Z"
            />
            <path
              className="cls-1"
              d="M167.37,99.55c0,2.65-2.14,4.8-4.79,4.8h-39.8c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.8c2.65,0,4.79,2.15,4.79,4.8Z"
            />
          </g>
          <g>
            <path
              className="cls-1"
              d="M79.92,66.94c-.36.38-9.12,9.22-23.53,10.51-.09.01-.18.01-.27.01-1.57,0-2.9-1.19-3.04-2.78-.15-1.67,1.09-3.16,2.77-3.31,12.18-1.08,19.63-8.61,19.7-8.68,1.18-1.21,3.11-1.23,4.32-.06,1.2,1.18,1.23,3.11.05,4.31Z"
            />
            <path
              className="cls-1"
              d="M155.81,74.68c-.14,1.59-1.47,2.78-3.04,2.78-.09,0-.18,0-.27-.01-14.41-1.29-23.17-10.13-23.53-10.51-1.18-1.2-1.15-3.13.05-4.31,1.21-1.17,3.13-1.15,4.31.05.04.04,1.94,1.95,5.35,3.97,3.41,2.03,8.32,4.18,14.36,4.72,1.68.15,2.92,1.64,2.77,3.31Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
   

); export default Emoji06