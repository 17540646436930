// Description: This component render the footer 
// Update: 12/08/2024

// React Components and DOM Elements
import React from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';

// Material UI Components
import { Box, Link } from '@mui/material';

// Components and functions from the project
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import ThemeColors from '../ThemeColors/ThemeColors';

// Render Your Component
function Footer() {
    const location = useLocation();
    const path = location.pathname;

    const returnPagePrevious = () => {
        return path.split('/').slice(0, -1).join('/');
    };

    return (
        <Box sx={{
            width: '100%',
            backgroundColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            paddingBottom: '25px',
        }}>

            <Link
                component={RouterLink}
                to={returnPagePrevious()}
                sx={{ color: ThemeColors.purple400, textDecoration: 'none', fontSize: '16px', fontFamily: 'Satoshi-Medium', display: 'flex', alignItems: 'center' }}
            >
                <KeyboardBackspaceOutlinedIcon sx={{ color: ThemeColors.purple400 , marginRight: '5px' ,marginTop: '2px'}} />
                Voltar para a página anterior
            </Link>
        </Box>
    );
}

export default Footer;
