// Description: This component render a showcase of the emojis of the site
// Update: 12/08/2024

// React Components and DOM Elements
import React from "react";

// Material UI Components
import { Grid } from "@mui/material";

// Components and functions from the project
import StatusIcon from "../StatusLabel/StatusIcon";

// Render Your Component
const Emojis = () => {
    const emojis = [];
    for (let i = 1; i <= 30; i++) {

        let number = i.toString().padStart(2, '0');
        emojis.push(
            <div key={number} style={{ width: '80px', height: '100px', borderRadius: '12px', margin: '10px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' }}>
                <p>emoji{number}</p>
                <StatusIcon status={`emoji${number}`} />
            </div>
        );
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", padding: "20px" }}>
            <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                {emojis}
            </Grid>
        </div>
    );
}

export default Emojis;
