import React from "react";

const Emoji02 = (props) => (

  <svg
  id="Camada_2"
  data-name="Camada 2"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 208.9 208.9"
  width={props.width || '85'} height={props.height || '85'}
>
  <defs>
    <style>{".cls-2{stroke-width:0;fill:#7b25ff}"}</style>
  </defs>
  <g id="Layer_1" data-name="Layer 1">
    <path
      className="cls-2"
      d="M153.9 0H55C24.63 0 0 24.62 0 55v98.9c0 30.37 24.63 55 55 55h98.9c30.38 0 55-24.63 55-55V55c0-30.38-24.62-55-55-55M61.93 98.06c-6.77 0-12.25-6.19-12.25-13.85s5.48-13.86 12.25-13.86S74.2 76.56 74.2 84.21s-5.5 13.85-12.27 13.85m65.54 23.21c0 .16 0 .33-.03.47-1.79 11.62-12.82 16.81-22.98 16.81s-21.21-5.19-22.99-16.81c-.02-.14-.04-.31-.04-.47v-9.76c0-1.77 1.44-3.19 3.2-3.19h39.66c1.76 0 3.18 1.42 3.18 3.19zm19.5-23.21c-6.77 0-12.27-6.19-12.27-13.85s5.5-13.86 12.27-13.86 12.26 6.21 12.26 13.86-5.48 13.85-12.26 13.85"
    />
    <path
      className="cls-2"
      d="M74.2 84.21c0 7.66-5.5 13.85-12.27 13.85s-12.25-6.19-12.25-13.85 5.48-13.86 12.25-13.86S74.2 76.56 74.2 84.21m53.27 27.3v9.76c0 .16 0 .33-.03.47-1.79 11.62-12.82 16.81-22.98 16.81s-21.21-5.19-22.99-16.81c-.02-.14-.04-.31-.04-.47v-9.76c0-1.77 1.44-3.19 3.2-3.19h39.66c1.76 0 3.18 1.42 3.18 3.19m31.76-27.3c0 7.66-5.48 13.85-12.26 13.85s-12.27-6.19-12.27-13.85 5.5-13.86 12.27-13.86 12.26 6.21 12.26 13.86"
    />
    <path
      d="M127.47 111.51v9.76c0 .16 0 .33-.03.47-1.79 11.62-12.82 16.81-22.98 16.81s-21.21-5.19-22.99-16.81c-.02-.14-.04-.31-.04-.47v-9.76c0-1.77 1.44-3.19 3.2-3.19h39.66c1.76 0 3.18 1.42 3.18 3.19M74.2 84.21c0 7.66-5.5 13.85-12.27 13.85s-12.25-6.19-12.25-13.85 5.48-13.86 12.25-13.86S74.2 76.56 74.2 84.21m85.03 0c0 7.66-5.48 13.85-12.26 13.85s-12.27-6.19-12.27-13.85 5.5-13.86 12.27-13.86 12.26 6.21 12.26 13.86"
      style={{
        fill: "#210d33",
        strokeWidth: 0,
      }}
    />
  </g>
</svg>

); export default Emoji02;