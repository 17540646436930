// Description: This component render a showcase of the site's Tables
// Update: 12/08/2024

// React Components and DOM Elements
import React from "react";

// Material UI Components
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';

// Components and functions from the project
import DynamicTable from "../../components/Tables/DynamicTable";
import StatusLabel from '../../components/StatusLabel/StatusLabel';
import EditIcon from '../../assets/Icons/components/Edit';
import DeleteIcon from '../../assets/Icons/components/Delete';
import DuplicateIcon from '../../assets/Icons/components/Duplicate';
import PlusIcon from '../../assets/Icons/components/Plus';
import ThemeColors from '../../components/ThemeColors/ThemeColors';

// Render Your Component
const Tables = () => {

    const FakeData = [

        { icon: <ExtensionOutlinedIcon  sx={{color: ThemeColors.purple600}}/>, title: 'Item 1', desc: 'Descrição do item 1', status: 'Desconectado', type: 'avisos' },
        { icon: <ExtensionOutlinedIcon  sx={{color: ThemeColors.purple600}}/>, title: 'Item 2', desc: 'Descrição do item 2', status: 'Conectado' , type: 'fatura'},
        { icon: <ExtensionOutlinedIcon  sx={{color: ThemeColors.purple600}}/>, title: 'Item 3', desc: 'Descrição do item 3', status: 'Desconectado' , type: 'inadimplencia'},
        { icon: <ExtensionOutlinedIcon  sx={{color: ThemeColors.purple600}}/>, title: 'Item 4', desc: 'Descrição do item 4', status: 'Active' , type: 'atraso'},
        { icon: <ExtensionOutlinedIcon  sx={{color: ThemeColors.purple600}}/>, title: 'Item 1', desc: 'Descrição do item 1', status: 'Desconectado' , type: 'marketing'},

    ]

return (
    <DynamicTable
        title="Tabela de itens"
        data={FakeData}
        columns={[
            { header: '', field: 'icon', render: (icon) => icon },
            { header: 'Título', field: 'title' },
            { header: 'Descrição', field: 'desc' },
            { header: 'Tipo', field: 'type', render: (type) => <StatusLabel status={type} /> },
            { header: 'Status', field: 'status', render: (status) => <StatusLabel status={status} /> },
        ]}
        actions={[

            // Passa icone + tooltip + alert ( AÇÃO  + ITEM CLICKADO  )
            { icon: <EditIcon />, tooltip: 'Editar', },
            { icon: <DuplicateIcon />, tooltip: 'Duplicar', },
            { icon: <DeleteIcon />, tooltip: 'Deletar', },
        ]}

        button={[
            { icon: <PlusIcon />, tooltip: 'Adicionar Itens ' }
        ]}

        activeSearch={true}
        titleSearch={'Pesquisar itens'}
        titleLabel={'Itens por página'}
    />

);
}

export default Tables;