import React from "react";

const Emoji05 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.89 208.9"
    width={props.width || '85'} height={props.height || '85'}
    >
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.89,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.89c30.38,0,55-24.63,55-55V55c0-30.38-24.62-55-55-55ZM81.61,137.91h-39.79c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.79c2.65,0,4.8,2.15,4.8,4.8s-2.15,4.8-4.8,4.8ZM81.61,123.17h-39.79c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.79c2.65,0,4.8,2.15,4.8,4.8s-2.15,4.8-4.8,4.8ZM158.08,137.91h-39.8c-2.65,0-4.79-2.15-4.79-4.8s2.14-4.8,4.79-4.8h39.8c2.65,0,4.8,2.15,4.8,4.8s-2.15,4.8-4.8,4.8ZM158.08,123.17h-39.8c-2.65,0-4.79-2.15-4.79-4.8s2.14-4.8,4.79-4.8h39.8c2.65,0,4.8,2.15,4.8,4.8s-2.15,4.8-4.8,4.8ZM162.88,94.24c-4.97,0-9-4.02-9-8.99s9-14.26,9-14.26c0,0,8.99,9.29,8.99,14.26s-4.02,8.99-8.99,8.99Z"
        />
        <path
          className="cls-2"
          d="M86.41,133.11c0,2.65-2.15,4.8-4.8,4.8h-39.79c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.79c2.65,0,4.8,2.15,4.8,4.8Z"
        />
        <path
          className="cls-2"
          d="M86.41,118.37c0,2.65-2.15,4.8-4.8,4.8h-39.79c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.79c2.65,0,4.8,2.15,4.8,4.8Z"
        />
        <path
          className="cls-2"
          d="M162.88,133.11c0,2.65-2.15,4.8-4.8,4.8h-39.8c-2.65,0-4.79-2.15-4.79-4.8s2.14-4.8,4.79-4.8h39.8c2.65,0,4.8,2.15,4.8,4.8Z"
        />
        <path
          className="cls-2"
          d="M162.88,118.37c0,2.65-2.15,4.8-4.8,4.8h-39.8c-2.65,0-4.79-2.15-4.79-4.8s2.14-4.8,4.79-4.8h39.8c2.65,0,4.8,2.15,4.8,4.8Z"
        />
        <path
          className="cls-2"
          d="M171.87,85.25c0,4.97-4.02,8.99-8.99,8.99s-9-4.02-9-8.99,9-14.26,9-14.26c0,0,8.99,9.29,8.99,14.26Z"
        />
        <g>
          <g>
            <path
              className="cls-1"
              d="M86.41,133.11c0,2.65-2.15,4.8-4.8,4.8h-39.79c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.79c2.65,0,4.8,2.15,4.8,4.8Z"
            />
            <path
              className="cls-1"
              d="M162.88,133.11c0,2.65-2.15,4.8-4.8,4.8h-39.8c-2.65,0-4.79-2.15-4.79-4.8s2.14-4.8,4.79-4.8h39.8c2.65,0,4.8,2.15,4.8,4.8Z"
            />
          </g>
          <g>
            <path
              className="cls-1"
              d="M86.41,118.37c0,2.65-2.15,4.8-4.8,4.8h-39.79c-2.65,0-4.8-2.15-4.8-4.8s2.15-4.8,4.8-4.8h39.79c2.65,0,4.8,2.15,4.8,4.8Z"
            />
            <path
              className="cls-1"
              d="M162.88,118.37c0,2.65-2.15,4.8-4.8,4.8h-39.8c-2.65,0-4.79-2.15-4.79-4.8s2.14-4.8,4.79-4.8h39.8c2.65,0,4.8,2.15,4.8,4.8Z"
            />
          </g>
          <path
            className="cls-1"
            d="M171.87,85.25c0,4.97-4.02,8.99-8.99,8.99s-9-4.02-9-8.99,9-14.26,9-14.26c0,0,8.99,9.29,8.99,14.26Z"
          />
        </g>
      </g>
    </g>
  </svg>

); export default Emoji05