import React from "react";

const Emoji14 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.9 208.9"
    width={props.width || '85'} height={props.height || '85'}
    >
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.9,0H55C24.62,0,0,24.62,0,55v98.9c0,30.37,24.62,55,55,55h98.9c30.37,0,55-24.63,55-55V55c0-30.38-24.63-55-55-55ZM64.79,97.78c-6.78,0-12.27-6.19-12.27-13.85s5.49-13.86,12.27-13.86,12.25,6.21,12.25,13.86-5.48,13.85-12.25,13.85ZM146,115.65c-2.47,8.58-8.2,15.55-16.15,19.62-7.95,4.08-16.95,4.67-25.36,1.66-2.5-.89-3.8-3.63-2.91-6.13.89-2.49,3.64-3.79,6.14-2.9,5.88,2.1,12.19,1.69,17.75-1.16,5.56-2.86,9.58-7.74,11.31-13.74.73-2.55,3.39-4.02,5.94-3.29,2.54.74,4.01,3.39,3.28,5.94ZM153.64,87.5c2.39,1.14,3.41,4,2.27,6.39-.82,1.73-2.54,2.75-4.33,2.75-.69,0-1.39-.15-2.06-.47l-16.65-7.91c-1.68-.79-2.74-2.48-2.75-4.33,0-1.85,1.07-3.54,2.75-4.33l16.65-7.91c2.4-1.14,5.26-.12,6.39,2.27,1.14,2.4.12,5.26-2.27,6.4l-7.53,3.57,7.53,3.57Z"
        />
        <path
          className="cls-2"
          d="M77.04,83.93c0,7.66-5.48,13.85-12.25,13.85s-12.27-6.19-12.27-13.85,5.49-13.86,12.27-13.86,12.25,6.21,12.25,13.86Z"
        />
        <path
          className="cls-2"
          d="M146,115.65c-2.47,8.58-8.2,15.55-16.15,19.62-7.95,4.08-16.95,4.67-25.36,1.66-2.5-.89-3.8-3.63-2.91-6.13.89-2.49,3.64-3.79,6.14-2.9,5.88,2.1,12.19,1.69,17.75-1.16,5.56-2.86,9.58-7.74,11.31-13.74.73-2.55,3.39-4.02,5.94-3.29,2.54.74,4.01,3.39,3.28,5.94Z"
        />
        <path
          className="cls-2"
          d="M155.91,93.89c-.82,1.73-2.54,2.75-4.33,2.75-.69,0-1.39-.15-2.06-.47l-16.65-7.91c-1.68-.79-2.74-2.48-2.75-4.33,0-1.85,1.07-3.54,2.75-4.33l16.65-7.91c2.4-1.14,5.26-.12,6.39,2.27,1.14,2.4.12,5.26-2.27,6.4l-7.53,3.57,7.53,3.57c2.39,1.14,3.41,4,2.27,6.39Z"
        />
        <g>
          <path
            className="cls-1"
            d="M146,115.65c-2.47,8.58-8.2,15.55-16.15,19.62-7.95,4.08-16.95,4.67-25.36,1.66-2.5-.89-3.8-3.63-2.91-6.13.89-2.49,3.64-3.79,6.14-2.9,5.88,2.1,12.19,1.69,17.75-1.16,5.56-2.86,9.58-7.74,11.31-13.74.73-2.55,3.39-4.02,5.94-3.29,2.54.74,4.01,3.39,3.28,5.94Z"
          />
          <path
            className="cls-1"
            d="M77.04,83.93c0,7.66-5.48,13.85-12.25,13.85s-12.27-6.19-12.27-13.85,5.49-13.86,12.27-13.86,12.25,6.21,12.25,13.86Z"
          />
          <path
            className="cls-1"
            d="M155.91,93.89c-.82,1.73-2.54,2.75-4.33,2.75-.69,0-1.39-.15-2.06-.47l-16.65-7.91c-1.68-.79-2.74-2.48-2.75-4.33,0-1.85,1.07-3.54,2.75-4.33l16.65-7.91c2.4-1.14,5.26-.12,6.39,2.27,1.14,2.4.12,5.26-2.27,6.4l-7.53,3.57,7.53,3.57c2.39,1.14,3.41,4,2.27,6.39Z"
          />
        </g>
      </g>
    </g>
  </svg>

); export default Emoji14