// Description: This component render a showcase of the site's status labels
// Update: 12/08/2024

// React Components and DOM Elements
import React from "react";

// Material UI Components
import { Grid } from "@mui/material";

// Components and functions from the project
import StatusLabel from "../StatusLabel/StatusLabel";

// Render Your Component
const Labels = () => {
    return (
        <>
        <Grid container style={{ display: "flex", justifyContent: "center", padding: "30px" }}>
                {/* Primeira Coluna */}
                <Grid item xs={12} sm={6} md={2} sx={{ '& > *': { marginBottom: '10px' } }}>
                    <Grid item>
                        <StatusLabel status='avisos' />
                    </Grid>
                    <Grid item>
                        <StatusLabel status='fatura' />
                    </Grid>
                    <Grid item>
                        <StatusLabel status='inadimplencia' />
                    </Grid>
                    <Grid item>
                        <StatusLabel status='atraso' />
                    </Grid>
                    <Grid item>
                        <StatusLabel status='marketing' />
                    </Grid>
                </Grid>
                {/* Segunda Coluna */}
                <Grid item xs={12} sm={6} md={2} sx={{ '& > *': { marginBottom: '10px' } }}>
                    <Grid item>
                        <StatusLabel status='Pendente' />
                    </Grid>
                    <Grid item>
                        <StatusLabel status='Em andamento' />
                    </Grid>
                    <Grid item>
                        <StatusLabel status='Fechada' />
                    </Grid>
                    <Grid item>
                        <StatusLabel status='Disapproved' />
                    </Grid>
                </Grid>
                {/* Terceira Coluna */}
                <Grid item xs={12} sm={6} md={2} sx={{ '& > *': { marginBottom: '10px' } }}>
                    <Grid item>
                        <StatusLabel status='Active' />
                    </Grid>
                    <Grid item>
                        <StatusLabel status='Conectado' />
                    </Grid>
                    <Grid item>
                        <StatusLabel status='Desconectado' />
                    </Grid>
                </Grid>
                {/* Quarta Coluna */}
                <Grid item xs={12} sm={6} md={2} sx={{ '& > *': { marginBottom: '10px' } }}>
                    <Grid item>
                        <StatusLabel status='Alta' />
                    </Grid>
                    <Grid item>
                        <StatusLabel status='Média' />
                    </Grid>
                    <Grid item>
                        <StatusLabel status='Baixa' />
                    </Grid>
                </Grid>
                {/* Quinta Coluna */}
                <Grid item xs={12} sm={6} md={2} sx={{ '& > *': { marginBottom: '10px' } }}>
                <Grid item>
                        <StatusLabel status='Online' />
                    </Grid>
                    <Grid item>
                        <StatusLabel status='Arquivo' />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Labels;
