 
import axios from 'axios';

export async function GetListagens({data, iv}) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/get-listagens-by-uuid`, 
            { [process.env.REACT_APP_RECEIVED_CRYPTO]: data, [process.env.REACT_APP_COOKIE_IV]: iv },  
        );
        
        const responseData = response.data;

        return responseData;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        throw new Error('Erro ao buscar dados da API');
    }
}

