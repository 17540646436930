// Desc: Component AlertSnackbar used in project for show alerts
// Update: 12/08/2024

// React Components and DOM Elements
import React, { useEffect, useState } from 'react';

// Material-UI Components
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ThemeColors from '../ThemeColors/ThemeColors';
import StatusIcon from '../StatusLabel/StatusIcon';


const AlertSnackbar = ({ open, onClose, message, colorValue, severity }) => {

  // Standardize Severities
  const mapSeverity = {
    success: ThemeColors.green01,
    error: ThemeColors.red01,
    warning: ThemeColors.yellow01
  };

  const mapTitle = {
    success: ThemeColors.green03,
    error: ThemeColors.red03,
    warning: ThemeColors.yellow03
  };

  // State to control the visibility of the text
  const [isMainContentCollapsed, setIsMainContentCollapsed] = useState(false);
  const [showFullAlert, setShowFullAlert] = useState(true);

  // Verify if the main content is collapsed
  useEffect(() => {
    const mainContent = document.querySelector('.MainContent');
    if (mainContent) {
      setIsMainContentCollapsed(mainContent.classList.contains('collapsed'));
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleMouseLeave();
    }, 3000); // Alert shows for 3 seconds
    return () => clearTimeout(timer);
  }, []);

  // Handle the mouse enter event
  const handleMouseEnter = () => {
    setShowFullAlert(true);
  };

  // Handle the mouse leave event
  const handleMouseLeave = () => {
    setShowFullAlert(false);
  };

  // Handle the close event
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Box
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        backgroundColor: colorValue ? colorValue : severity ? mapSeverity[severity] : ThemeColors.green01,
        position: 'relative',
        color: severity ? mapTitle[severity] : ThemeColors.gray40,
        borderRadius: '6px',
        //marginLeft: isMainContentCollapsed ? '95px' : '260px',
        marginLeft: '-5px',
        padding: '10px',
        marginBottom: '10px',
        transition: 'all 0.3s'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
        <StatusIcon status={'Alert'} sx={{ paddingLeft: '10px' }} width={25} height={25} color={severity ? mapTitle[severity] : ThemeColors.gray40} />
        <Typography variant="body1" sx={{ fontSize: '16px', fontFamily: 'Satoshi', color: severity ? mapTitle[severity] : ThemeColors.gray40, marginLeft: '10px' }}>
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </Typography>
      </div>
    </Box>
  );
}

export default AlertSnackbar;