// Description: This component render a showcase of the alerts of the site
// Update: 12/08/2024

// React Components and DOM Elements
import React from "react";

// Material UI Components
import { Grid } from '@mui/material';

// Components and functions from the project
import AlertSnackbar from "../Alerts/AlertSnackbar";
import ContainerStyle from "../Styles/ContainerStyle";
import ThemeColors from "../../components/ThemeColors/ThemeColors";

// Render Your Component
const Alerts = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", padding: "30px" }}>
            {/* Primeira fila de contentores com estilo */}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid container spacing={1} direction="row">
                        <ContainerStyle color={ThemeColors.green01} colorFont={'#1B4D1B'} title={'01'} subtitle={'#D3EAC8'} />
                        <ContainerStyle color={ThemeColors.green02} colorFont={'#FFFFFF'} title={'02'} subtitle={'#4C8435'} />
                        <ContainerStyle color={ThemeColors.green03} colorFont={'#FFFFFF'} title={'03'} subtitle={'#345427'} />
                    </Grid>
                    <AlertSnackbar message="Ação concluída com sucesso!" severity="success" open={true} colorValue={ThemeColors.green01} />
                    <AlertSnackbar message="Seus dados foram salvos com sucesso." severity="success" open={true} colorValue={ThemeColors.green01} />
                    <AlertSnackbar message="Cadastro realizado com sucesso." severity="success" open={true} colorValue={ThemeColors.green01} />
                    <AlertSnackbar message="Item excluído com sucesso." severity="success" open={true} colorValue={ThemeColors.green01} />
                    <AlertSnackbar message="Sua senha foi alterada com sucesso." severity="success" open={true} colorValue={ThemeColors.green01} />
                    <AlertSnackbar message="Imagem carregada com sucesso!" severity="success" open={true} colorValue={ThemeColors.green01} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid container spacing={1} direction="row">
                        <ContainerStyle color={ThemeColors.yellow01} colorFont={'#8D4D1B'} title={'01'} subtitle={'#F6EDCB'} />
                        <ContainerStyle color={ThemeColors.yellow02} colorFont={'#FFFFFF'} title={'02'} subtitle={'#DEA73F'} />
                        <ContainerStyle color={ThemeColors.yellow03} colorFont={'#FFFFFF'} title={'03'} subtitle={'#8D4D1B'} />
                    </Grid>
                    <AlertSnackbar message="Atenção! Ação não realizada." severity="warning" open={true} colorValue={ThemeColors.yellow01} />
                    <AlertSnackbar message="Atenção! Dados não salvos." severity="warning" open={true} colorValue={ThemeColors.yellow01} />
                    <AlertSnackbar message="Atenção! Cadastro não realizado." severity="warning" open={true} colorValue={ThemeColors.yellow01} />
                    <AlertSnackbar message="Atenção! Item não excluído." severity="warning" open={true} colorValue={ThemeColors.yellow01} />
                    <AlertSnackbar message="Atenção! Senha não alterada." severity="warning" open={true} colorValue={ThemeColors.yellow01} />
                    <AlertSnackbar message="Atenção! Imagem não carregada." severity="warning" open={true} colorValue={ThemeColors.yellow01} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Grid container spacing={1} direction="row">
                        <ContainerStyle color={ThemeColors.red01} colorFont={'#4E1B1D'} title={'01'} subtitle={'#F8D6D3'} />
                        <ContainerStyle color={ThemeColors.red02} colorFont={'#FFFFFF'} title={'02'} subtitle={'#CD4747'} />
                        <ContainerStyle color={ThemeColors.red03} colorFont={'#FFFFFF'} title={'03'} subtitle={'#4E1B1D'} />
                    </Grid>
                    <AlertSnackbar message="Erro ao realizar ação!" severity="error" open={true} colorValue={ThemeColors.red01} />
                    <AlertSnackbar message="Erro ao salvar seus dados." severity="error" open={true} colorValue={ThemeColors.red01} />
                    <AlertSnackbar message="Erro ao realizar cadastro." severity="error" open={true} colorValue={ThemeColors.red01} />
                    <AlertSnackbar message="Erro ao excluir item." severity="error" open={true} colorValue={ThemeColors.red01} />
                    <AlertSnackbar message="Erro ao alterar sua senha." severity="error" open={true} colorValue={ThemeColors.red01} />
                    <AlertSnackbar message="Erro ao carregar imagem!" severity="error" open={true} colorValue={ThemeColors.red01} />
                </Grid>
            </Grid>

        </div>
    )
}

export default Alerts;
