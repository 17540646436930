import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const NavigationControlContext = createContext({
  canNavigate: true
});

export const useNavigationControl = () => useContext(NavigationControlContext);

export const NavigationControlProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar a rota atual para controlar a navegação
    if (location.pathname === '/styles') {
      // Função para tentar navegar para outra rota
      const handleNavigate = (event) => {
        // Se já estamos em /styles, bloqueie qualquer tentativa de navegar para fora
        if (location.pathname === '/styles') {
          event.preventDefault();
          event.returnValue = ''; // Necessário para alguns navegadores
          return false;
        }
      };

      // Adicione o ouvinte ao evento de beforeunload
      window.addEventListener('beforeunload', handleNavigate);

      // Limpar o ouvinte ao sair do componente
      return () => {
        window.removeEventListener('beforeunload', handleNavigate);
      };
    }
  }, [location, navigate]);

  return (
    <NavigationControlContext.Provider value={{ canNavigate: location.pathname !== '/styles' }}>
      {children}
    </NavigationControlContext.Provider>
  );
};
