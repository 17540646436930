// Description: This component render a Papper Block
// Update: 12/08/2024

// React Components and DOM Elements
import React from 'react';

// Components and functions from the project
import './PapperBlock.css';

// Render Your Component
function PapperBlock({  children }) {

  return (
    <div className='PapperBlock'>
      <div>
      </div>
      {children}
    </div>
  );
}

export default PapperBlock;
