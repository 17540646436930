// Description: This component render a showcase of the site's Typographys
// Update: 12/08/2024

// React Components and DOM Elements
import React from "react";

// Material UI Components
import { Grid } from '@mui/material';

// Components and functions from the project
import Titles from '../Titles/Titles';
import ThemeColors from '../ThemeColors/ThemeColors';

// Render Your Component
const Typographys = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", padding: "30px" }}>
            <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                <Grid item xs={12} sm={6} md={3} sx={{ '& > *': { marginBottom: '10px' } }}>
                    <Titles type='H0' color={ThemeColors.purple700} font="light">Light H0</Titles>
                    <Titles type='H1' color={ThemeColors.purple700} font="light">Light H1</Titles>
                    <Titles type='H2' color={ThemeColors.purple700} font="light">Light H2</Titles>
                    <Titles type='H3' color={ThemeColors.purple700} font="light">Light H3</Titles>
                    <Titles type='H4' color={ThemeColors.purple700} font="light">Light H4</Titles>
                    <Titles type='P1' color={ThemeColors.purple700} font="light">Light P1</Titles>
                    <Titles type='P2' color={ThemeColors.purple700} font="light">Light P2</Titles>
                </Grid>
                <Grid item xs={12} sm={6} md={3} sx={{ '& > *': { marginBottom: '10px' } }}>
                    <Titles type='H0' color={ThemeColors.purple700} bold={false}>Regular H0</Titles>
                    <Titles type='H1' color={ThemeColors.purple700} bold={false}>Regular H1</Titles>
                    <Titles type='H2' color={ThemeColors.purple700} bold={false}>Regular H2</Titles>
                    <Titles type='H3' color={ThemeColors.purple700} bold={false}>Regular H3</Titles>
                    <Titles type='H4' color={ThemeColors.purple700} bold={false}>Regular H4</Titles>
                    <Titles type='P1' color={ThemeColors.purple700} bold={false}>Regular P1</Titles>
                    <Titles type='P2' color={ThemeColors.purple700} bold={false}>Regular P2</Titles>
                </Grid>
                <Grid item xs={12} sm={6} md={3} sx={{ '& > *': { marginBottom: '10px' } }}>
                    <Titles type='H0' color={ThemeColors.purple700} font="medium">Medium H0</Titles>
                    <Titles type='H1' color={ThemeColors.purple700} font="medium">Medium H1</Titles>
                    <Titles type='H2' color={ThemeColors.purple700} font="medium">Medium H2</Titles>
                    <Titles type='H3' color={ThemeColors.purple700} font="medium">Medium H3</Titles>
                    <Titles type='H4' color={ThemeColors.purple700} font="medium">Medium H4</Titles>
                    <Titles type='P1' color={ThemeColors.purple700} font="medium">Medium P1</Titles>
                    <Titles type='P2' color={ThemeColors.purple700} font="medium">Medium P2</Titles>
                </Grid>
                <Grid item xs={12} sm={6} md={3} sx={{ '& > *': { marginBottom: '10px' } }}>
                    <Titles type='H0' color={ThemeColors.purple700} font="bold">Bold H0</Titles>
                    <Titles type='H1' color={ThemeColors.purple700} font="bold">Bold H1</Titles>
                    <Titles type='H2' color={ThemeColors.purple700} font="bold">Bold H2</Titles>
                    <Titles type='H3' color={ThemeColors.purple700} font="bold">Bold H3</Titles>
                    <Titles type='H4' color={ThemeColors.purple700} font="bold">Bold H4</Titles>
                    <Titles type='P1' color={ThemeColors.purple700} font="bold">Bold P1</Titles>
                    <Titles type='P2' color={ThemeColors.purple700} font="bold">Bold P2</Titles>
                </Grid>
            </Grid>

        </div>
    );
}
export default Typographys;
