// Request to login in the backend API
// Update in 20/04/2024 ( OK )

import axios from 'axios';

export async function AuthLogin({ data, iv }) {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/login`, 
            { [process.env.REACT_APP_RECEIVED_CRYPTO]: data, [process.env.REACT_APP_COOKIE_IV]: iv },  
        );
        return response.data;
    } catch (error) {
        if (error.response) {
            const status = error.response.status;
            if (status === 401) {
                return { 
                    status: 401,
                    message: 'Usuário ou senha inválidos.'
                };
            } else if (status === 429) {
                return {
                    status: 429,
                    message: 'Muitas solicitações. Tente novamente mais tarde.'
                };
            } else if (status === 404){
                return {
                    status: 404,
                    message: 'Esse email ou usuário é inválido.'
                };
            }
        }  
    }
}
