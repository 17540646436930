export async function InsertCharge(respostas,uuidClient) {
    try {
      const response = await fetch('https://api.123zap.com.br/webhook/InsertCharge', {
        method: 'POST',
        body: JSON.stringify({
          uuid_account: uuidClient,
          full_token: 't8OOEeISKzpmc3jjcMqBWYSaJsafdefer$prod',
          data:respostas
        })
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erro ao obter dados da API:', error);
      return { data: [], status: 'Ocorreu um erro na requisição.' };
    }
  }