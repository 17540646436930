import * as React from "react";
const Sheets = (props) => (

    <svg width={props.width || "20px"} height={props.height || "20px"}
        viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_798_6478)">
            <path d="M11.8125 0L18 6L14.9062 6.54545L11.8125 6L11.25 3L11.8125 0Z" fill="#188038" />
            <path d="M11.8125 6V0H1.6875C0.755156 0 0 0.732273 0 1.63636V22.3636C0 23.2677 0.755156 24 1.6875 24H16.3125C17.2448 24 18 23.2677 18 22.3636V6H11.8125Z" fill="#34A853" />
            <path d="M3.375 9.27271V17.1818H14.625V9.27271H3.375ZM8.29688 15.8182H4.78125V13.9091H8.29688V15.8182ZM8.29688 12.5454H4.78125V10.6363H8.29688V12.5454ZM13.2188 15.8182H9.70312V13.9091H13.2188V15.8182ZM13.2188 12.5454H9.70312V10.6363H13.2188V12.5454Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_798_6478">
                <rect width="18" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>

);
export default Sheets;
