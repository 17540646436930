// Description: This component render a Dynamic Form that will need a HTML file
// Update:  12/08/2024

// React Components and DOM Elements
import React, { useState } from "react";

// Material UI Components
import {
  Grid,
  Button,
  FormControl,
  TextField,
  Box,
  useMediaQuery,
} from "@mui/material";

// Components and functions for the project
import themeColors from "../ThemeColors/ThemeColors";
import Footer from "../Footer/Footer";
import Titles from "../Titles/Titles";
import StatusIcon from "../StatusLabel/StatusIcon";
import { createObjectFromArray } from "../../utils/utils";
import TemplateInput from "../Input/TemplateInput";

// render Your Component
const DynamicForm = ({
  title,
  setTitle,
  updatedHtml,
  variablesOfTemplate,
  emailTitle,
  setEmailTitle,
  setTypeMessage,
  variables,
  handleSubmit,
  formState,
  setFormState,
  selected,
  setSelected,
  edit = false,
  mapHTMLColors,
  handleColorChange,
  selectedColor,
  isHTMLTemplate,
}) => {
  // Form States default
  const [isVisible, setIsVisible] = useState(
    createObjectFromArray(variables, false)
  );
  const [expanded, setExpanded] = useState(
    createObjectFromArray(variables, false)
  );

  // Function to toggle the visibility of the Accordion Menu
  const toggleAccordionVisibility = (name) => {
    setIsVisible((prevState) => ({
      ...prevState,
      [name]: !isVisible[name],
    }));
  };

  // Function to handle the change of each accordion
  const handleChangeAccordion = (variable, category) => (event, isExpanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [variable]: isExpanded ? category : false,
    }));
  };

  const buttons = [
    "Atrasos",
    "Avisos",
    "Faturas",
    "Inadimplências",
    "Marketing",
    "Outros",
  ];

  const isSmallScreen = useMediaQuery("(max-width:1200px)");
  const [showPreview, setShowPreview] = useState(isSmallScreen ? false : true);

  // Function to change the TypeMessage
  const handleClick = (value) => {
    setSelected(value);
    setTypeMessage(value);
  };

  // Function to handle the input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to add a variable in the temporary message
  const addVariableInField = (name, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [name]: prevState[name] + ` {{ ${value} }}`,
    }));
  };

  return (
    <Grid container spacing={2} style={{ paddingTop: "20px" }}>
      <Grid
        item
        xs={12}
        lg={6}
        xl={6}
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Box
          sx={{
            flexGrow: 1,
            backgroundColor: "#F9F9F9",
            width: { xs: "561.200px", lg: "90%" },
            margin: { xs: "0 auto 20px", lg: "0 auto" },
            padding: { xs: 0, lg: "20px 0px 20px 30px", xl: "20px" },
          }}
        >
          <Box>
            <FormControl fullWidth>
              <TextField
                id="outlined-basic"
                label="Nome do seu Modelo"
                variant="standard"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                InputLabelProps={{
                  style: { fontSize: "20px" }, // Ajuste o tamanho da fonte conforme necessário
                }}
              />
            </FormControl>
            <Box
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              {buttons.map((button, index) => (
                <Button
                  key={button}
                  onClick={() => handleClick(button)}
                  sx={{
                    flexGrow: 1,
                    mx: index === 0 || index === buttons.length - 1 ? 0 : 0.5,
                    bgcolor:
                      selected === button ? "#8142FC" : themeColors.gray30,
                    color: "#fff",
                    borderRadius: "27px",
                    "&:hover": {
                      bgcolor: selected === button ? "#8142FC" : "gray",
                    },
                    fontSize: "12px",
                    padding: "4px 16px",
                  }}
                >
                  {button}
                </Button>
              ))}
            </Box>
            <Box
              position={"relative"}
              marginBottom={isSmallScreen && showPreview ? "20px" : "none"}
            >
              <Box
                sx={{
                  color: "rgb(129, 66, 252)",
                  fontSize: "18px",
                  position: "absolute",
                  top: showPreview ? "16px" : "0px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  display: isSmallScreen ? "block" : "none",
                  fontFamily: "Satoshi",
                  cursor: "pointer",
                  zIndex: "10",
                }}
                onClick={() => setShowPreview((prev) => !prev)}
              >
                {!showPreview ? "Exibir Prévia" : "Esconder Prévia"}
              </Box>
              <Box
                sx={{
                  display: isSmallScreen && showPreview ? "none" : "block",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap="15px"
                  marginTop={"20px"}
                  marginBottom={"0px"}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"5px"}
                    minWidth={"100%"}
                  >
                    <Titles
                      children={"Título do Email"}
                      color={"#52525B"}
                      type={"P1"}
                    />
                    <TextField
                      placeholder="Digite o título aqui :)"
                      fullWidth
                      rows={1}
                      variant="outlined"
                      value={emailTitle}
                      onChange={(e) => setEmailTitle(e.target.value)}
                      autoComplete="off"
                      style={{
                        background: "white",
                        color: "black",
                        borderRadius: "12px",
                        margin: 0,
                        border: `1px solid ${themeColors.gray20}`,
                        color: themeColors.gray40,
                      }}
                      sx={{
                        ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "14px",
                          },
                        ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                          borderRadius: "6px",
                        },
                      }}
                    />
                  </Box>
                  {variables.map((variable) => (
                    <TemplateInput
                      addVariableInField={addVariableInField}
                      field={variable}
                      handleChangeAccordion={handleChangeAccordion}
                      handleInputChange={handleInputChange}
                      toggleAccordionVisibility={toggleAccordionVisibility}
                      variablesOfTemplate={variablesOfTemplate}
                      expanded={expanded}
                      formState={formState}
                      isVisible={isVisible}
                      key={variable}
                    />
                  ))}

                  {isHTMLTemplate && formState.Text && (
                    <Box
                      display={"flex"}
                      gap={"7px"}
                      alignItems={"center"}
                      height={"48px"}
                    >
                      {Object.keys(mapHTMLColors).map((color) => (
                        <Box
                          key={color}
                          sx={{
                            width: 50,
                            height: 50,
                            backgroundColor: `#${color}`,
                            cursor: "pointer",
                            borderRadius: "6px",
                            opacity: selectedColor === color ? 1 : 0.3,
                          }}
                          onClick={() => handleColorChange(color)}
                        />
                      ))}
                    </Box>
                  )}
                  <Box
                    display={"flex"}
                    minWidth={"100%"}
                    justifyContent={"end"}
                  >
                    <Button
                      style={{
                        width: "238px",
                        padding: "13px",
                        height: "48px",
                      }}
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      {edit ? "Editar Modelo" : "Salvar Modelo"}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box position={"absolute"} bottom={"0"}>
            <Footer />
          </Box>
        </Box>
      </Grid>
      {isSmallScreen && showPreview === true ? (
        <Grid
          item
          xs={12}
          lg={6}
          xl={6}
          padding={{ xs: "0 0 20px 0", lg: "0", xl: "0" }}
        >
          <Box
            borderRadius={"12px"}
            overflow={"hidden"}
            border={"1px solid #A986FF"}
            sx={{
              backgroundColor: "#fff",
              maxWidth: { xs: "561.200px", lg: "628px" },
              minHeight: { xs: "668px", lg: "90vh" },
              margin: { xs: "0 auto", lg: "0 60px 0 0" },
            }}
          >
            <Box
              zIndex={"2"}
              padding={"12px"}
              display={"flex"}
              gap="12px"
              alignItems={"center"}
              minWidth={"100%"}
              sx={{ backgroundColor: "#8142FC", color: "#fff" }}
            >
              <StatusIcon
                status={"E-mail"}
                height={"26px"}
                width={"26px"}
                color="#fff"
              />
              <Titles children={title} color={"#fff"} type={"H4"} />
            </Box>
            <Box padding={"20px"}>
              <div dangerouslySetInnerHTML={{ __html: updatedHtml }} />
            </Box>
          </Box>
        </Grid>
      ) : !isSmallScreen ? (
        <Grid
          item
          xs={12}
          lg={6}
          xl={6}
          padding={{ xs: "0 0 20px 0", lg: "0", xl: "0" }}
        >
          <Box
            borderRadius={"12px"}
            overflow={"hidden"}
            border={"1px solid #A986FF"}
            sx={{
              backgroundColor: "#fff",
              maxWidth: { xs: "561.200px", lg: "628px" },
              minHeight: { xs: "668px", lg: "90vh" },
              margin: { xs: "0 auto", lg: "0 60px 0 0" },
            }}
          >
            <Box
              zIndex={"2"}
              padding={"12px"}
              display={"flex"}
              gap="12px"
              alignItems={"center"}
              minWidth={"100%"}
              sx={{ backgroundColor: "#8142FC", color: "#fff" }}
            >
              <StatusIcon
                status={"E-mail"}
                height={"26px"}
                width={"26px"}
                color="#fff"
              />
              <Titles children={title} color={"#fff"} type={"H4"} />
            </Box>
            <Box padding={"20px"}>
              <div dangerouslySetInnerHTML={{ __html: updatedHtml }} />
            </Box>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default DynamicForm;
