import React from "react";

const Emoji30 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.89 208.9"
    width={props.width || '85'} height={props.height || '85'}
  >
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.89,0H55C24.62,0,0,24.62,0,55v98.9c0,30.38,24.62,55,55,55h98.89c30.38,0,55-24.62,55-55V55c0-30.38-24.62-55-55-55ZM61.93,109.55c-9.41,0-17.06-8.37-17.06-18.66s7.65-18.66,17.06-18.66,17.06,8.37,17.06,18.66-7.66,18.66-17.06,18.66ZM127.73,134.79c-.51,1.15-1.66,1.88-2.91,1.88h-40.75c-1.26,0-2.4-.73-2.91-1.88-.51-1.16-.31-2.51.53-3.44l20.37-22.81c.6-.68,1.47-1.06,2.38-1.06s1.79.38,2.39,1.06l20.37,22.81c.84.93,1.04,2.28.53,3.44ZM146.96,109.55c-9.4,0-17.06-8.37-17.06-18.66s7.66-18.66,17.06-18.66,17.06,8.37,17.06,18.66-7.65,18.66-17.06,18.66Z"
        />
        <ellipse
          className="cls-2"
          cx={61.93}
          cy={90.89}
          rx={17.06}
          ry={18.66}
        />
        <path
          className="cls-2"
          d="M127.2,131.35c.84.93,1.04,2.28.53,3.44-.51,1.15-1.66,1.88-2.91,1.88h-40.75c-1.26,0-2.4-.73-2.91-1.88-.51-1.16-.31-2.51.53-3.44l20.37-22.81c.6-.68,1.47-1.06,2.38-1.06s1.79.38,2.39,1.06l20.37,22.81Z"
        />
        <ellipse
          className="cls-2"
          cx={146.96}
          cy={90.89}
          rx={17.06}
          ry={18.66}
        />
        <g>
          <g>
            <path
              className="cls-1"
              d="M61.93,72.23c-9.41,0-17.06,8.37-17.06,18.66s7.65,18.66,17.06,18.66,17.06-8.37,17.06-18.66-7.66-18.66-17.06-18.66ZM61.93,99.95c-4.12,0-7.47-4.06-7.47-9.06s3.35-9.06,7.47-9.06,7.46,4.06,7.46,9.06-3.35,9.06-7.46,9.06Z"
            />
            <path
              className="cls-1"
              d="M146.96,72.23c-9.4,0-17.06,8.37-17.06,18.66s7.66,18.66,17.06,18.66,17.06-8.37,17.06-18.66-7.65-18.66-17.06-18.66ZM146.96,99.95c-4.11,0-7.46-4.06-7.46-9.06s3.35-9.06,7.46-9.06,7.47,4.06,7.47,9.06-3.35,9.06-7.47,9.06Z"
            />
          </g>
          <path
            className="cls-1"
            d="M127.73,134.79c-.51,1.15-1.66,1.88-2.91,1.88h-40.75c-1.26,0-2.4-.73-2.91-1.88-.51-1.16-.31-2.51.53-3.44l20.37-22.81c.6-.68,1.47-1.06,2.38-1.06s1.79.38,2.39,1.06l20.37,22.81c.84.93,1.04,2.28.53,3.44Z"
          />
        </g>
      </g>
    </g>
  </svg>

); export default Emoji30