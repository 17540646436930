import axios from 'axios';

export async function Analytics({ data, iv, onProgress }) {
    try {
        const response = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_BACKEND_URL}/export-analytics`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                [process.env.REACT_APP_RECEIVED_CRYPTO]: data,
                [process.env.REACT_APP_COOKIE_IV]: iv,
            },
            responseType: 'blob', // Importante para baixar arquivos
            onDownloadProgress: (progressEvent) => {
                if (onProgress) {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onProgress(percentCompleted);
                }
            },
        });

        console.log('response', response);

        return response.data;
    } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
        throw new Error('Erro ao buscar dados da API');
    }
}