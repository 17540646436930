import React from "react";

const Emoji26 = (props) => (

    <svg
    id="Camada_2"
    data-name="Camada 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 208.89 208.9"
    width={props.width || '85'} height={props.height || '85'}
>
    <defs>
      <style>
        {
          "\n      .cls-1 {\n        fill: #210d33;\n      }\n\n      .cls-1, .cls-2 {\n        stroke-width: 0px;\n      }\n\n      .cls-2 {\n        fill: #7B25FF;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <g>
        <path
          className="cls-2"
          d="M153.89,0H55C24.62,0,0,24.62,0,55v98.9c0,30.38,24.62,55,55,55h98.89c30.38,0,55-24.62,55-55V55c0-30.38-24.62-55-55-55ZM46.15,91.97c0-6.83,3.79-13.24,9.89-16.73,1.64-.94,3.68-.82,5.2.29l22.54,16.4c1.62,1.18,2.34,3.25,1.79,5.19-2.39,8.4-10.34,14.27-19.34,14.27-11.08,0-20.08-8.71-20.08-19.42ZM127.56,132.99c-.93.87-2.11,1.3-3.29,1.3-1.27,0-2.55-.51-3.5-1.51-4.27-4.56-10.07-7.07-16.33-7.07s-12.05,2.51-16.32,7.07c-1.82,1.93-4.86,2.02-6.79.21s-2.02-4.85-.21-6.78c6.11-6.51,14.4-10.09,23.32-10.09s17.22,3.58,23.33,10.09c1.81,1.93,1.72,4.97-.21,6.78ZM142.66,111.39c-9,0-16.96-5.87-19.34-14.28-.55-1.93.17-4,1.8-5.18l22.53-16.4c1.53-1.11,3.57-1.22,5.2-.29,6.1,3.48,9.89,9.89,9.89,16.73,0,10.71-9,19.42-20.08,19.42Z"
        />
        <path
          className="cls-2"
          d="M66.23,111.39c-11.08,0-20.08-8.71-20.08-19.42,0-6.83,3.79-13.24,9.89-16.73,1.64-.94,3.68-.82,5.2.29l22.54,16.4c1.62,1.18,2.34,3.25,1.79,5.19-2.39,8.4-10.34,14.27-19.34,14.27Z"
        />
        <path
          className="cls-2"
          d="M127.77,126.21c1.81,1.93,1.72,4.97-.21,6.78-.93.87-2.11,1.3-3.29,1.3-1.27,0-2.55-.51-3.5-1.51-4.27-4.56-10.07-7.07-16.33-7.07s-12.05,2.51-16.32,7.07c-1.82,1.93-4.86,2.02-6.79.21s-2.02-4.85-.21-6.78c6.11-6.51,14.4-10.09,23.32-10.09s17.22,3.58,23.33,10.09Z"
        />
        <path
          className="cls-2"
          d="M162.74,91.97c0,10.71-9,19.42-20.08,19.42-9,0-16.96-5.87-19.34-14.28-.55-1.93.17-4,1.8-5.18l22.53-16.4c1.53-1.11,3.57-1.22,5.2-.29,6.1,3.48,9.89,9.89,9.89,16.73Z"
        />
        <g>
          <path
            className="cls-1"
            d="M127.56,132.99c-.93.87-2.11,1.3-3.29,1.3-1.27,0-2.55-.51-3.5-1.51-4.27-4.56-10.07-7.07-16.33-7.07s-12.05,2.51-16.32,7.07c-1.82,1.93-4.86,2.02-6.79.21s-2.02-4.85-.21-6.78c6.11-6.51,14.4-10.09,23.32-10.09s17.22,3.58,23.33,10.09c1.81,1.93,1.72,4.97-.21,6.78Z"
          />
          <g>
            <path
              className="cls-1"
              d="M83.78,91.93l-22.54-16.4c-1.52-1.11-3.56-1.23-5.2-.29-6.1,3.49-9.89,9.9-9.89,16.73,0,10.71,9,19.42,20.08,19.42,9,0,16.95-5.87,19.34-14.27.55-1.94-.17-4.01-1.79-5.19ZM66.23,101.8c-5.78,0-10.49-4.41-10.49-9.83,0-2.49.99-4.82,2.72-6.6l16.5,12.01c-1.9,2.7-5.15,4.42-8.73,4.42Z"
            />
            <path
              className="cls-1"
              d="M152.85,75.24c-1.63-.93-3.67-.82-5.2.29l-22.53,16.4c-1.63,1.18-2.35,3.25-1.8,5.18,2.38,8.41,10.34,14.28,19.34,14.28,11.08,0,20.08-8.71,20.08-19.42,0-6.84-3.79-13.25-9.89-16.73ZM142.66,101.8c-3.58,0-6.83-1.72-8.73-4.42l16.5-12.01c1.73,1.78,2.72,4.11,2.72,6.6,0,5.42-4.71,9.83-10.49,9.83Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>

); export default Emoji26